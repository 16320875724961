import { EventEmitter } from "@angular/core";
import { Recording } from "./recording.model";

function parseResponseError(error: Error | any) {
	if (error.message) throw error
	else if (error.statusText) throw new Error(error.statusText)
	else throw error
}
export class RecordingService extends EventEmitter {
	Recording: any;
	constructor($resource, API_URL) {
		'ngInject';
		super();
		this.Recording = $resource(`${API_URL}recording/:id`, {}, {
			enable: {
				url: `${API_URL}recording/:id/enable`,
				method: 'POST',
			},
			restart: {
				url: `${API_URL}recording/restart`,
				method: 'POST',
			}
		});
	}

	async query(params: any): Promise<Recording[]> {
		const recordings = await this.Recording.query(params).$promise;
		return recordings.map(recording => new Recording(recording));
	}
	async get(id?: string): Promise<Recording> {
		const recording = await this.Recording.get({ id: id }).$promise.catch(parseResponseError);
		return new Recording(recording)
	}
	async update(recording: FireClip.Recording, id: string) {
		return this.Recording.update({ id: id }, recording).$promise.catch(parseResponseError);
	}
	async delete(id: string) {
		return this.Recording.delete({ id: id }).$promise.catch(parseResponseError);
	}
	async save(recording: FireClip.Recording) {
		return this.Recording.save(recording).$promise.catch(parseResponseError);
	}
	async enable(id: string, enabled: boolean) {
		return this.Recording.enable({ id: id }, { enabled: enabled }).$promise.catch(parseResponseError);
	}
	async restart(id: string, channelId: string = null) {
		return this.Recording.restart({ id, channelId }).$promise;
	}
}
export default RecordingService;