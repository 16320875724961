import { SmallClip, Clip } from './clipers';
import { Library, SmallLibrary } from './library';
import Konva from 'konva';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take, skip, filter } from 'rxjs/operators';
import { timeLine } from './timeline.component';
import { Layer } from 'konva/types/Layer';
import { formatDateToTime, formatDurationTotime } from './helpers';


class Point {
	privateTime = null
	pointService: any;
	k: any;
	/**
	 * 
	 * @param {number} time 
	 * @param {PointService} pointService 
	 */
	constructor(time, pointService) {
		this.privateTime = time;
		this.pointService = pointService;
		this.constructConva();
		this.recalc();
	}

	get konva() {
		return this.k;
	}

	set time(time) {
		this.privateTime = time;
	}

	recalc() {
		if (this.k) {
			this.k.x(this.pointService.timeToX(this.privateTime));
		}
	}

	constructConva() {
		this.k = new Konva.Line({
			x: this.pointService.timeToX(this.privateTime),
			y: 60,
			points: [0, 0, 0, -40],
			stroke: 'yellow',
			opacity: 1,
			strokeWidth: 4,
		});
		this.k.on('mouseenter', () => {
			this.k.setStroke('blue');
			this.k.setStrokeWidth(8);
		});
		this.k.on('mouseleave', () => {
			this.k.setStrokeWidth(4);
			this.k.setStroke('yellow');
		});
	}
}
class PointService {
	color = 'red'

	/** @type {Point[]} */
	items = []

	startTime = 0

	endTime = 0

	width = 1

	ratio = 0

	/**
	 * @param {number} startTime
	 * @param {number} endTime
	 * @param {number} width
	 */
	setRegion(startTime: number, endTime: number, width) {
		this.startTime = startTime;
		this.endTime = endTime;
		this.width = width;
		this.ratio = (endTime - startTime) / width;
		this.items.forEach(item => item.recalc());
	}

	/**
	 * @param {number} x
	 */
	positionToTime(x) {
		return new Date(this.startTime + x * this.ratio);
	}

	/**
	 * @param {number} time
	 */
	timeToX(time: number) {
		return (time - this.startTime) / this.ratio;
	}

	/**
	 * @param {number} time
	 */
	create(time) {
		return new Point(time, this);
	}

	/** @param {Point} point */
	add(point) {
		this.items.push(point);
	}

	/** @param {Point} point */
	delete(point) {
		this.items.splice(this.items.findIndex(point));
	}
}

class MiniScroll {
	time = null;
	playerThumbnail: Konva.Image;
	anim: Konva.Animation;
	loadingGear: Konva.Path;
	thumbailGroup: Konva.Group;
	timeText: Konva.Text;
	marksGroup: Konva.Group;
	librariesGroup: Konva.Group;
	ratio: number;
	bars: Konva.Group;
	mainClipGroup: Konva.Group;
	pointService: PointService;
	width: any;
	line: Konva.Group;
	playBar: Konva.Group;
	hitRegionRect: any;
	playBarMoving = false;
	subscription1: any;
	subscription2: any;
	clips: SmallClip[] = [];
	libraries: SmallLibrary[] = [];
	range: { start: number; end: number; };
	lastTime: number;
	layer: Layer;
	masterRange$: BehaviorSubject<TimeLineComponent.Range>;
	range$: BehaviorSubject<{ start: number; end: number; }>;
	width$: BehaviorSubject<number>;
	playTime$: BehaviorSubject<{ library: Library; time: number; }>;
	constructor(private service: timeLine) {
		this.layer = new Konva.Layer({ listening: true, y: 160 });
		// this.layer.toggleHitCanvas()
		this.service.stage.add(this.layer);
		this.masterRange$ = this.service.macroRange$;
		this.range$ = this.service.microRange$;
		this.width$ = this.service.width$;
		this.playTime$ = this.service.playTime$;
		this.service.addLibrary$.subscribe((lib) => {
			this.addLibrary(lib);
		})

		this.width = this.width$.getValue();
		const obs = combineLatest(
			this.width$.pipe(filter((a) => a !== 0)),
			this.range$
		)

		// update width and range when anyone changes..
		obs.subscribe((a) => {
			const [width, range] = a;
			this.width = width;
			this.range = range;
			this.ratio = (this.range.end - this.range.start) / this.width;
		})

		obs
			.pipe(take(1))
			.subscribe(() => {
				// on first load, setup components..
				this.setup()
				this.layer.batchDraw();
				this.playTime$.subscribe(() => {
					this.calcPlayBar()
					this.layer.batchDraw()
				})
			})

		obs
			.pipe(skip(1))
			.subscribe(() => {
				this.calcRuler()
				this.calcClips();
				this.calcPlayBar();
				this.pointService.setRegion(this.range.start, this.range.end, this.width)
				this.hitRegionRect.setWidth(this.width);
				this.layer.batchDraw();
			})

	}
	setup() {
		// play bar
		const playArrowPointW = 5;
		const playArrowPointH = 9;
		const playArrowH = 15;
		this.playBar = new Konva.Group({
			y: 0,
			draggable: true,
			dragBoundFunc: pos => ({
				y: this.playBar.getAbsolutePosition().y,
				x: pos.x,
			}),
		});
		const triangle = new Konva.Line({
			x: 0,
			y: 0, // 7 es el cachit que sale de la barra
			points: [
				-playArrowPointW, 0,
				-playArrowPointW, playArrowPointH,
				0, playArrowH,
				playArrowPointW, playArrowPointH,
				playArrowPointW, 0,
			],
			fill: '#FA1D04',
			// stroke: '#ff0000',
			strokeWidth: 1,
			// draggable: true,
			closed: true,
			// dragBoundFunc: pos => ({
			// 	y: this.playBar.getAbsolutePosition().y,
			// 	x: pos.x,
			// }),
		});
		const line = new Konva.Line({
			x: 0,
			y: 0,
			points: [
				0, playArrowH,
				0, playArrowH + 30
			],
			opacity: 0.5,
			stroke: '#FA1D04',
			strokeWidth: 1,
			listening: false
		})
		this.playBar.add(triangle)
		this.playBar.add(line)
		this.playBar.on('mouseover', function () {
			document.body.style.cursor = 'pointer';
		});
		this.playBar.on('mouseout', function () {
			document.body.style.cursor = 'default';
		});
		this.playBar.on('dragstart', () => {
			this.playBarMoving = true;
		})
		this.playBar.on('dragend', (data) => {
			this.playBarMoving = false;
			const time = this.positionToTime(data.target.getPosition().x);
			const smallLib = this.libraries.find(l => {
				return time <= l.lib.end && time >= l.lib.start;
			})
			if (smallLib) this.service.playToLibraryTime(smallLib.lib, time - smallLib.lib.end);
		});

		this.pointService = new PointService();
		this.pointService.color = 'blue';
		this.bars = new Konva.Group({});

		const clipGroup = new Konva.Group({
			y: 0,
			height: 39,
		});
		this.mainClipGroup = new Konva.Group({
			y: 20,
			height: 30,
			scale: {
				y: 1,
				x: 1
			},
		});
		clipGroup.add(this.mainClipGroup);

		// line on mouse over and thumbnail....
		this.line = new Konva.Group({
			visible: false,
			listening: false
		})
		this.line.add(new Konva.Rect({
			x: 0,
			y: 0,
			height: 60,
			width: 2,
			fill: '#111',
			opacity: 0.2,
		}))


		// frame box
		const thumbnailWidth = 256;
		const thumbnailHeight = thumbnailWidth * (9 / 16); // 16:9 aspect ratio, maybe do not use that... 
		this.playerThumbnail = new Konva.Image({
			x: 0,
			y: 0,
			image: null,
			width: thumbnailWidth,
			height: thumbnailHeight,
		});
		const textY = thumbnailHeight - 10;
		this.timeText = new Konva.Text({
			x: 0,
			y: textY,
			text: '00:00:00',
			fontSize: 12,
			fontFamily: 'Roboto Mono',
			fill: 'white',
			width: thumbnailWidth,
			align: 'center',
		});
		const textPadding = 16;
		const backgroundRect = new Konva.Rect({
			x: -4,
			y: -4,
			width: thumbnailWidth + 8,
			height: thumbnailHeight + 8,
			fill: '#331',
		});
		const textRect = new Konva.Rect({
			x: textPadding,
			y: textY,
			width: thumbnailWidth - textPadding * 2,
			height: 12,
			fill: '#000',
			opacity: 0.5,
		});
		const loadingGearSize = 26;
		this.loadingGear = new Konva.Path({
			x: thumbnailWidth / 2, // thumbailWidth,
			y: thumbnailHeight / 2,
			width: loadingGearSize,
			height: loadingGearSize,
			offset: {
				x: loadingGearSize / 2,
				y: loadingGearSize / 2,
			},
			scale: { x: 3, y: 3 },
			fill: 'white',
			stroke: 'black',
			strokeWidth: 1,
			opacity: 0.4,
			data: 'M20,14.5v-2.9l-1.8-0.3c-0.1-0.4-0.3-0.8-0.6-1.4l1.1-1.5l-2.1-2.1l-1.5,1.1c-0.5-0.3-1-0.5-1.4-0.6L13.5,5h-2.9l-0.3,1.8 C9.8,6.9,9.4,7.1,8.9,7.4L7.4,6.3L5.3,8.4l1,1.5c-0.3,0.5-0.4,0.9-0.6,1.4L4,11.5v2.9l1.8,0.3c0.1,0.5,0.3,0.9,0.6,1.4l-1,1.5 l2.1,2.1l1.5-1c0.4,0.2,0.9,0.4,1.4,0.6l0.3,1.8h3l0.3-1.8c0.5-0.1,0.9-0.3,1.4-0.6l1.5,1.1l2.1-2.1l-1.1-1.5c0.3-0.5,0.5-1,0.6-1.4 L20,14.5z M12,16c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S13.7,16,12,16z',
		});

		this.thumbailGroup = new Konva.Group({
			y: -thumbnailHeight - 12,
			offsetX: thumbnailWidth / 2,
			visible: false,
			listening: false
		});
		this.thumbailGroup.add(backgroundRect);
		this.thumbailGroup.add(this.playerThumbnail);
		this.thumbailGroup.add(this.loadingGear);
		this.thumbailGroup.add(textRect);
		this.thumbailGroup.add(this.timeText);

		this.librariesGroup = new Konva.Group();

		this.anim = new Konva.Animation(((frame) => {
			this.loadingGear.rotate(frame.timeDiff * 135 / 1000);
		}), this.thumbailGroup);
		this.hitRegionRect = new Konva.Rect({
			name: 'hitRegionRect',
			x: 0,
			y: 0,
			width: this.layer.getWidth(),
			height: 60,
			fill: '#f00',
			opacity: 0,
		});
		this.layer.on('mousemove', this.mouseMove.bind(this));
		this.layer.on('mouseover', this.mouseMove.bind(this));
		this.layer.on('mouseout', () => {
			this.hideCursor();
			this.layer.batchDraw();
		});
		// change konva 0.12.2
		this.layer.on('wheel', data => this.wheelMove(data));
		this.marksGroup = new Konva.Group({});
		this.layer.on('click', data => this.onClickBar(data));

		this.layer.add(this.hitRegionRect);
		this.layer.add(this.bars);
		this.layer.add(this.librariesGroup);
		this.layer.add(this.marksGroup);
		this.layer.add(this.line);
		this.layer.add(this.thumbailGroup);
		this.layer.add(this.playBar);
		this.layer.add(clipGroup);
		this.calcRuler()
	}

	calcPlayBar() {
		if (this.playBarMoving) return
		if (this.playTime$.getValue() === null) {
			this.playBar.hide();
		} else {
			const time = this.playTime$.getValue().library.start + this.playTime$.getValue().time;
			if (time === null || time < this.range.start || time > this.range.end) {
				this.playBar.hide();
			} else {
				this.playBar.setAttr('x', this.timeToX(time));
				this.playBar.show();
			}
		}
	}

	onClickBar(data) {
		// 0: primary, 1:middle, 2:secondary
		const time = this.positionToTime(data.evt.layerX + this.layer.offsetX());
		if (data.evt.button === 0) {
			if (data.evt.ctrlKey || data.evt.altKey || data.evt.shiftKey) {
				if (data.evt.ctrlKey || data.evt.altKey) {
					if (data.evt.shiftKey) {
						if (data.target.getName() === 'clip') {
							this.service.emit('clipCreate', new Date(time), 'fragment')
						}
					} else {
						this.service.emit('clipCreate', new Date(time), 'clip')
					}
				} else if (data.evt.shiftKey) {
					const point = this.pointService.create(time);
					this.pointService.add(point);
					this.addMark(point);
				}
			} else if (data.target.getName() !== 'leftFocusBar' && data.target.getName() !== 'rightFocusBar') {
				const l = this.libraries.find(l => l.rect === data.target);
				if (l) {
					this.service.playToLibraryTime(l.lib, time - l.lib.start)
				}
			}
		}
	}

	wheelMove(data) {
		data.evt.preventDefault(true);
		let deltaX = data.evt.deltaY;
		let deltaY = data.evt.deltaX;
		if (data.evt.shiftKey) {
			deltaY = data.evt.deltaY;
			deltaX = data.evt.deltaX;
		}
		// zoom
		/**
		 * zoom in/out by half/double of current timeline visible range
		 * center on mouse position
		 */
		if (deltaY !== 0) {
			const x = data.evt.layerX + this.layer.offsetX();
			const ratio = deltaY / 50;
			const time = this.positionToTime(x);
			const timeDelta = (this.range.end - this.range.start) / 4;
			const xRatio = (time - this.range.start) / (this.range.end - this.range.start);
			const leftAdd = timeDelta * xRatio * ratio;
			const rightAdd = timeDelta * (1 - xRatio) * ratio;
			const start = this.range.start - leftAdd;
			const end = this.range.end + rightAdd;
			const newRatio = (end - start) / this.width;
			if (newRatio > 10 && start < end && start >= this.masterRange$.getValue().start && end <= this.masterRange$.getValue().end) {
				this.range$.next({ start: start, end: end })
			}
		}
		// move
		if (deltaX !== 0) {
			const delta = (this.range.end - this.range.start) * (0.1 * deltaX / 100);
			const start = this.range.start + delta;
			const end = this.range.end + delta;
			if (start >= this.masterRange$.getValue().start && end <= this.masterRange$.getValue().end) {
				this.range$.next({ start: start, end: end })
			}
		}
	}

	positionToTime(x: number): number {
		return this.range.start + x * this.ratio;
	}

	timeToX(time: number) {
		return (time - this.range.start) / this.ratio;
	}

	addClip(timelineClip: Clip) {
		const cliper = new SmallClip(timelineClip, this, this.mainClipGroup);
		timelineClip.start$.subscribe({
			complete: () => {
				this.deleteClip(cliper.id);
			}
		})
		cliper.on('leftChangeEnd', () => {
			this.service.finishChange(timelineClip, 'leftMove');
		});
		cliper.on('rightChangeEnd', () => {
			this.service.finishChange(timelineClip, 'rightMove');
		});
		timelineClip.start$.subscribe(() => {
			this.layer.batchDraw()
		})
		this.clips.push(cliper);
	}

	deleteClip(id: string) {
		const index = this.clips.findIndex(c => c.id === id);
		if (index >= 0) {
			const removed = this.clips.splice(index, 1);
			if (removed.length > 0) {
				removed[0].clipers.destroy();
			}
		}
		this.layer.batchDraw()
	}

	calcClips() {
		this.clips.forEach(c => c.calc());
	}


	private addLibrary(timelineLibrary: Library) {
		const library = new SmallLibrary(timelineLibrary, this, this.librariesGroup);
		timelineLibrary.destroy$.subscribe(() => {
			const i = this.libraries.findIndex(l => l === library)
			if (i >= 0) this.libraries.splice(i, 1);
			this.layer.batchDraw();
		})
		this.libraries.push(library);
	}

	private calcRuler() {
		this.bars.destroyChildren();
		let delta = 1000;
		let factor = 60;
		if (this.ratio >= 20000) {
			delta = 60000 * 20;
			factor = 2;
		} else if (this.ratio >= 10000) {
			delta = 60000 * 10;
			factor = 2;
		} else if (this.ratio >= 5000) {
			delta = 60000 * 5;
			factor = 2;
		} else if (this.ratio >= 3500) {
			delta = 120000;
			factor = 2;
		} else if (this.ratio >= 2600) {
			delta = 60000;
			factor = 4;
		} else if (this.ratio >= 1000) {
			delta = 60000;
			factor = 2;
		} else if (this.ratio >= 400) {
			delta = 10000;
			factor = 6;
		} else if (this.ratio >= 200) {
			delta = 5000;
			factor = 6;
		} else if (this.ratio >= 100) {
			delta = 5000;
			factor = 2;
		} else if (this.ratio >= 50) { // cada 5 segundos
			delta = 5000;
			factor = 1;
		} else if (this.ratio >= 25) { // cada 2
			delta = 2000;
			factor = 1;
		} else if (this.ratio >= 12.5) { // cada 1
			delta = 1000
			factor = 1;
		} else if (this.ratio >= 0) { // cada 1 por omision
			delta = 1000
			factor = 1;
		}
		let start = this.range.start;
		if (start % delta != 0) {
			start = delta - start % delta + start;
		}
		for (let i = start; i < this.range.end; i += delta) {
			const xCoord = (i - this.range.start) / this.ratio;
			if (i % (factor * delta) === 0) {
				this.bars.add(new Konva.Line({
					x: xCoord,
					y: 0,
					points: [0, 0, 0, 50],
					// stroke: '#323232',
					stroke: '#fff',
					opacity: 0.2,
					strokeWidth: 1,
					listening: false,
				}));
				this.bars.add(new Konva.Text({
					x: xCoord + 2,
					y: 0,
					text: this.service.config.type === 'absolute' ? formatDateToTime(new Date(i)) : formatDurationTotime(i),
					fill: '#fff',
					fontFamily: 'Roboto Mono',
					fontSize: 10,
					align: 'right',
					listening: false
				}));
			} else if (i % delta === 0) {
				// this.bars.add(new TheKonva.Line({
				// 	x: xCoord,
				// 	y: 0,
				// 	points: [0, 25, 0, 50],
				// 	stroke: '#fff',
				// 	opacity: 0.5,
				// 	strokeWidth: 1,
				// 	listening: false,
				// }));
			}
		}
		this.hitRegionRect.width(this.width$.getValue())
	}

	addMark(point) {
		this.marksGroup.add(point.konva);
	}

	setCursor(x) {
		this.line.x(x);
		this.thumbailGroup.x(x);
		this.timeText.setText(formatDateToTime(new Date(this.positionToTime(x))));
	}

	showCursor() {
		this.line.visible(true);
		this.thumbailGroup.visible(true);
	}

	hideCursor() {
		this.line.visible(false);
		this.thumbailGroup.visible(false);
	}

	clearThumbnail() {
		const canvas = document.createElement('canvas');
		canvas.width = 160;
		canvas.height = 120;
		canvas.getContext('2d').fillStyle = 'blue';
		canvas.getContext('2d').fillRect(0, 0, 160, 120);
		this.playerThumbnail.image(canvas);
	}

	thumbnailLoading(loading = true) {
		if (loading) {
			this.loadingGear.visible(true);
			this.anim.start();
		} else {
			this.loadingGear.visible(false);
			this.anim.stop();
		}
	}

	async mouseMove(data) {
		if (data.target.name() === 'library') {
			//TODO: keep a reference o Library in l.rect? so avoiding find, or bind mouse move to library rec....
			const smallLib = this.libraries.find(l => l.rect === data.target);
			if (smallLib) {
				const x = data.evt.layerX + this.layer.offsetX();
				const time = this.positionToTime(x) - smallLib.lib.start;
				this.playerThumbnail.visible(true);
				this.setCursor(x);
				this.showCursor();
				if (this.lastTime === time) {
					return
				}
				this.thumbnailLoading(true);
				this.clearThumbnail()
				this.layer.batchDraw()
				this.lastTime = time;
				const canvas = await smallLib.lib.getThumbnailCanvasForTime(time);
				if (time == this.lastTime) {
					this.thumbnailLoading(false);
					if (canvas) {
						this.playerThumbnail.image(canvas);
					} else {
						this.clearThumbnail()
					}
				}
				this.layer.batchDraw()
			}
		}
	}
}

export default MiniScroll;
