import { EventEmitter } from 'events';
import Konva from 'konva';
import { Clip, BigClip } from './clipers';
import { Library, BigLibrary } from './library';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { filter, take, skip } from 'rxjs/operators';
import { timeLine } from './timeline.component';
import { clipTextSeconds } from './helpers';

const TheKonva = require('konva')
class MacroScroll {
	destroy() {
		this.scrollThumbLeftHandle.destroy();
		this.scrollThumbRightHandle.destroy();
	}
	width: number = 0;
	ratio: number;
	ratio$ = new BehaviorSubject(0);
	emitter: EventEmitter;
	barHeight: number;
	ruler: Konva.Group;
	clipGroup: Konva.Group;
	librariesGroup: Konva.Group;
	background: any;
	playBar: Konva.Line;
	scrollThumb: Konva.Rect;
	scrollThumbRightHandle: Konva.Path;
	scrollThumbLeftHandle: Konva.Path;
	bar: Konva.Group;
	playTime: any;
	bigClips: BigClip[] = [];
	uiBuilded$ = new Subject();
	layer: Konva.Layer;
	range$: BehaviorSubject<TimeLineComponent.Range>;
	detailRange$: BehaviorSubject<{ start: number; end: number; }>;
	width$: BehaviorSubject<number>;
	playTime$: BehaviorSubject<{ library: Library; time: number; }>;
	libraries = new Array<BigLibrary>();
	constructor(private service: timeLine) {
		this.layer = new TheKonva.Layer({ listening: true, y: 160 + 70 });;
		this.service.stage.add(this.layer);
		this.emitter = new EventEmitter();
		this.barHeight = 30;
		this.ruler = new TheKonva.Group({});
		this.clipGroup = new TheKonva.Group({});
		this.librariesGroup = new TheKonva.Group({});
		this.range$ = this.service.macroRange$;
		this.detailRange$ = this.service.microRange$;
		this.width$ = this.service.width$;
		this.playTime$ = this.service.playTime$;
		this.service.addLibrary$.subscribe((lib) => {
			this.addLibrary(lib);
		})
		const obs = combineLatest(
			this.width$.pipe(filter((a) => a !== 0)),
			this.range$.pipe(filter(r => r !== null))
		)
		obs
			.subscribe((data) => {
				this.width = data[0];
				this.ratio = (data[1].end - data[1].start) / this.width$.getValue()
				this.ratio$.next(this.ratio);
			})
		this.ratio$.pipe(
			filter(r => r !== 0),
			take(1)
		)
			.subscribe(() => {
				this.buildUI();
				this.calcRuler()
				this.layer.batchDraw()
				this.detailRange$
					.subscribe(() => {
						this.calcThumbBar();
						this.layer.batchDraw();
					})
				this.playTime$
					.pipe(filter(d => d !== null))
					.subscribe(() => {
						this.calcPlayBar();
						this.layer.batchDraw()
					})
			})
		this.ratio$
			.pipe(filter(r => r !== 0), skip(1))
			.subscribe(() => {

				this.calcPlayBar()
				this.calcClips()
				this.calcRuler();
				this.calcThumbBar();
				this.layer.batchDraw();
			})
	}
	buildUI() {
		this.background = new TheKonva.Rect({
			x: 0,
			y: 0,
			height: this.barHeight,
			width: this.width,
			// fill: '#1B1B1B',
		});
		this.playBar = new TheKonva.Line({
			x: 0,
			y: 15,
			points: [0, 0, 0, 10],
			stroke: '#FA1D04',
			strokeWidth: 2,
		});


		this.scrollThumb = new TheKonva.Rect({
			cornerRadius: 6,
			x: 0,
			y: 10,
			height: this.barHeight - 10,
			width: 0,
			fill: '#afafaf',
			opacity: 0.4,
			draggable: true,
			stroke: '#000000',
			strokeEnabled: true,
			strokeWidth: 1,
			dragBoundFunc: (pos) => {
				const centerTime = this.positionToTime(pos.x + this.scrollThumb.width() / 2)
				const detailRange = this.detailRange$.getValue();
				const rangeDuration = detailRange.end - detailRange.start;
				const newStart = centerTime - rangeDuration / 2;
				const newEnd = centerTime + rangeDuration / 2;
				const masterRange = this.range$.getValue();
				let x = pos.x;
				const pixelWidth = this.timeToPosition(detailRange.end) - this.timeToPosition(detailRange.start);
				if (newStart < masterRange.start) {
					x = (pixelWidth - this.scrollThumb.width()) / 2
				}
				if (newEnd > masterRange.end) {
					x = this.layer.getWidth() - (pixelWidth + this.scrollThumb.width()) / 2
				}
				return { y: this.scrollThumb.getAbsolutePosition().y, x };
			},
		});
		this.scrollThumbLeftHandle = new TheKonva.Path({
			x: 0,
			y: 0,
			data: 'M20,0H7.157C3.555,0,0,3.427,0,7.029v6.524C0,17.156,3.555,20,7.157,20H20V0z M8,18H7V3h1V18z M12,18h-1V3h1 V18z M16,18h-1V3h1V18z',
			fill: '#777777',
			// draggable: true,
			dragBoundFunc: function leftHandleDragBoundFunc(pos) {
				const newPos = {
					y: this.getAbsolutePosition().y,
					x: pos.x,
				};
				return newPos;
			},
		});
		this.scrollThumbRightHandle = new TheKonva.Path({
			x: 0,
			y: 0,
			data: 'M20,0H7.157C3.555,0,0,3.427,0,7.029v6.524C0,17.156,3.555,20,7.157,20H20V0z M8,18H7V3h1V18z M12,18h-1V3h1 V18z M16,18h-1V3h1V18z',
			fill: '#777777',
			// draggable: true,
			scale: {
				x: -1,
				y: 1,
			},
			dragBoundFunc(pos) {
				const newPos = {
					y: this.getAbsolutePosition().y,
					x: pos.x,
				};
				return newPos;
			},
		});
		this.scrollThumbRightHandle.offsetX(this.scrollThumbLeftHandle.getClientRect(null).width);
		this.scrollThumbLeftHandle.offsetX(this.scrollThumbLeftHandle.getClientRect(null).width);
		this.bar = new TheKonva.Group({
			x: 0,
			y: 0,
		});
		// this.bar.add(this.scrollThumbLeftHandle);
		// this.bar.add(this.scrollThumbRightHandle);
		this.bar.add(this.scrollThumb);

		this.scrollThumb.on('mouseover', () => { document.body.style.cursor = 'pointer'; });
		this.scrollThumb.on('mouseout', () => { document.body.style.cursor = 'default'; });
		const moveToCenter = (centerTime: number) => {
			const detailRange = this.detailRange$.getValue();
			const rangeDuration = detailRange.end - detailRange.start;
			const masterRange = this.range$.getValue();
			if (detailRange.start >= masterRange.start && detailRange.end <= masterRange.end) {
			}
			this.detailRange$.next({
				start: centerTime - rangeDuration / 2,
				end: centerTime + rangeDuration / 2
			})
		}
		this.scrollThumb.on('dragmove', (e) => {
			const centerTime = this.positionToTime(e.target.attrs.x + e.target.attrs.width / 2)
			moveToCenter(centerTime);
		});
		this.layer.on('click', (data) => {
			const centerTime = this.positionToTime(data.evt.offsetX);
			moveToCenter(centerTime);
		});


		this.layer.add(this.background);
		this.layer.add(this.ruler);
		this.layer.add(this.librariesGroup);
		this.layer.add(this.clipGroup);
		this.layer.add(this.playBar);
		this.layer.add(this.bar);
		this.uiBuilded$.complete();
	}


	calcPlayBar() {
		if (this.playTime$.getValue() == null) {
			this.playBar.hide()
		} else {
			const position = this.playTime$.getValue().time;
			const library = this.playTime$.getValue().library;
			if (position === undefined || library === null) {
				this.playBar.hide();
			} else {
				let time = library.start + position;
				if (time < this.range$.getValue().start || time > this.range$.getValue().end) {
					this.playBar.hide();
				} else {
					this.playBar.show();
					this.playBar.x(this.timeToPosition(time));
				}
			}
		}
	}

	calcClips() {
		for (const c of this.bigClips) {
			c.calc();
		}
	}

	public addClip(timelineClip: Clip) {
		const bigClip = new BigClip(timelineClip, this);
		timelineClip.start$.subscribe({
			complete: () => {
				this.deleteClip(timelineClip);
			}
		})
		if (bigClip.macroClip) this.clipGroup.add(bigClip.macroClip);
		this.bigClips.push(bigClip);
	}

	public deleteClip(clip: Clip) {
		const index = this.bigClips.findIndex(c => c.clip === clip);
		if (index >= 0) {
			const removed = this.bigClips.splice(index, 1);
			if (removed.length === 1) {
				removed[0].destroy();
			}
		}
		this.layer.batchDraw()
	}

	timeToPosition(date: number) {
		// ms / ratio = px
		// TODO: round?!
		return (date - this.range$.getValue().start) / this.ratio;
	}

	private calcRuler() {
		// marking lines
		this.ruler.destroyChildren();
		this.background.setWidth(this.width);
		// hour division...
		// find nearest start
		const delta = 3600000;
		let start = this.range$.getValue().start;
		if (start % delta != 0) {
			start = delta - start % delta + start;
		}
		let leftText = '';
		let rightText = '';
		if (this.service.config.type === 'absolute') {
			leftText = new Date(this.range$.getValue().start).toLocaleDateString('es-cl')
			rightText = new Date(this.range$.getValue().end).toLocaleDateString('es-cl')
		} else {
			// TODO : is correct to assume range.start is allways 0 in relative type? 
			leftText = clipTextSeconds(this.range$.getValue().start, false)
			rightText = clipTextSeconds(this.range$.getValue().end, false)
		}
		this.ruler.add(new TheKonva.Text({
			x: 0,
			y: this.barHeight,
			text: leftText,
			fill: '#aaa',
			fontFamily: 'Roboto Mono',
			fontSize: 10,
			align: 'left',
		}));
		this.ruler.add(new Konva.Text({
			x: this.width - 63, // how to calculate this 63? 
			y: this.barHeight,
			text: rightText,
			fill: '#aaa',
			fontFamily: 'Roboto Mono',
			fontSize: 10,
			align: 'right',
		}));
		for (let i = start; i < this.range$.getValue().end; i += delta) {
			const k = new Date(i);
			this.ruler.add(new TheKonva.Line({
				x: (i - this.range$.getValue().start) / this.ratio,
				y: 0,
				opacity: 0.05,
				points: [0, 0, 0, this.barHeight],
				stroke: k.getHours() == 0 ? '#525252' : '#fff',
				strokeWidth: k.getHours() == 0 ? 2 : 1,
			}));
			if (i % delta === 0) {
				this.ruler.add(new TheKonva.Text({
					x: (i - this.range$.getValue().start) / this.ratio + 1,
					y: 0,
					text: this.service.config.type === 'absolute'?k.getHours().toString():i.toString(),
					fill: '#aaa',
					fontFamily: 'Roboto Mono',
					fontSize: 10,
					align: 'right',
				}));
			}
		}
	}

	private calcThumbBar() {
		const minWidth = 40;
		let startPos = this.timeToPosition(this.detailRange$.getValue().start);
		let endPos = this.timeToPosition(this.detailRange$.getValue().end);
		let width = endPos - startPos;
		if (width < minWidth) {
			startPos -= (minWidth - width) / 2;
			endPos += (minWidth - width) / 2;
			width = minWidth;
		}
		this.scrollThumb.width(width);
		this.scrollThumb.x(startPos);
		this.scrollThumbLeftHandle.x(startPos);
		this.scrollThumbRightHandle.x(endPos);
	}

	durationToPixels(secs) {
		return secs / this.ratio;
	}

	positionToTime(x) {
		return this.range$.getValue().start + x * this.ratio;
	}

	private addLibrary(timelineLibrary: Library) {
		const bigLib = new BigLibrary(timelineLibrary, this, this.librariesGroup)
		timelineLibrary.destroy$.subscribe(() => {
			const i = this.libraries.findIndex(l => l === bigLib)
			if (i >= 0) this.libraries.splice(i, 1);
			this.layer.batchDraw();
		})
		this.libraries.push(bigLib);
	}
}

export default MacroScroll;
