function padRNumber(pad, str) {
	if (typeof str === 'undefined')
		return pad;
	return (str.toString() + pad).substring(0, pad.length);
}

function padLNumber(pad, str) {
	if (typeof str === 'undefined')
		return pad;
	return (pad + str.toString()).slice(-pad.length);
}
angular.module('fireclip').filter('time', function () {
	return function (date) {
		if (!date) return 'ERROR';
		if (typeof(date) === 'number') date = new Date(date)
		return padLNumber("00", date.getHours()) + ':' + padLNumber("00", date.getMinutes()) + ':' + padLNumber("00", date.getSeconds()) + '.' + padRNumber("000", date.getMilliseconds());
	};
});

// Filtro para convertir segundos en una representacion hh:mm:ss de tiempo
angular.module('fireclip').filter('duration', function () {
	return function (seconds = 0) {
		if (isNaN(seconds)) seconds = 0;
		//use zero day to get time transform, hacky way, use other way in future :P (in case a duration is more than a day long.)
		var date = new Date(seconds * 1000);
		return padLNumber("00", date.getUTCHours()) + ':' + padLNumber("00", date.getUTCMinutes()) + ':' + padLNumber("00", date.getUTCSeconds()) + '.' + padRNumber("000", date.getUTCMilliseconds());
	};
});

angular.module('fireclip').filter('size', function () {
	return function (bytes) {
		if (bytes < 1024) {
			return bytes + 'B';
		}
		var kilo = bytes / 1024;
		if (kilo < 1024) {
			return Math.round(kilo) + 'KB';
		} else {
			return Math.round(kilo / 1024) + 'MB';
		}
	};
});

angular.module('fireclip').filter('trustAsResourceUrl', ['$sce', function ($sce) {
	return function (val) {
		if (val === undefined) return;
		if (typeof (val) !== "string") {
			return $sce.trustAsResourceUrl(val.toString())
		}
		return $sce.trustAsResourceUrl(val);
	};
}])
