import 'core-js/es/reflect';
import 'core-js/stable/reflect';
import 'core-js/features/reflect';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__Zone_disable_requestAnimationFrame = true;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__Zone_disable_canvas = true;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove', 'pointermove']; // disable patch

import 'zone.js';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JobListComponent } from './job/job.list.component';
import { downgradeComponent, UpgradeModule } from '@angular/upgrade/static';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import angular from 'angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import localeEsCl from '@angular/common/locales/es-CL'
import { registerLocaleData } from '@angular/common';
import { UserListComponent } from './user/user-list.component';
import { RecordingListComponent } from './recording/recording-list.component';
import { UserEditCreateDialog } from './user/dialog-edit-user.component';
import { ConfirmDialog } from './common/dialog-confirm.component';
import { RecordingEditDialogComponent } from './recording/recording-edit-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ValidUrlValidatorDirective } from './common/url-validator.directive';
import { RangeValidatorDirective } from './common/range-validator.directive';
registerLocaleData(localeEsCl)
@NgModule({
	imports: [
		BrowserModule,
		UpgradeModule,
		HttpClientModule,

		FormsModule,
		ReactiveFormsModule,

		BrowserAnimationsModule,
		FlexLayoutModule,

		NgxJsonViewerModule,

		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatFormFieldModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatChipsModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatTableModule,
		MatTabsModule,
		MatGridListModule,
		MatCardModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatTooltipModule,
		MatMenuModule
		// NgxMatTimepickerModule,
		// NgxMatDatetimePickerModule,
		// OverlayModule
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'es-CL' },
		{
			provide: 'Auth0Service',
			useFactory: ($injector: any) => $injector.get('Auth0Service'),
			deps: ['$injector']
		},
		{
			provide: 'User',
			useFactory: ($injector: any) => $injector.get('User'),
			deps: ['$injector']
		},
		{
			provide: 'RecordingService',
			useFactory: ($injector: any) => $injector.get('RecordingService'),
			deps: ['$injector']
		},
	],
	declarations: [
		JobListComponent,
		UserListComponent,
		RecordingListComponent,
		UserEditCreateDialog,
		RecordingEditDialogComponent,
		ConfirmDialog,
		ValidUrlValidatorDirective,
		RangeValidatorDirective
	],
	entryComponents: [
		JobListComponent,
		UserListComponent,
		UserEditCreateDialog,
		RecordingListComponent,
		RecordingEditDialogComponent,
		ConfirmDialog
	]
})
export class FireClipModule {
	constructor(private upgrade: UpgradeModule) { }
	public ngDoBootstrap() {
		console.log('bootstrap')
		this.upgrade.bootstrap(document.body, ['fireclip'], { strictDi: true })
	}
}

export function downgrade() {
	platformBrowserDynamic().bootstrapModule(FireClipModule);
	angular
		.module('fireclip')
		.directive('jobListComponent', downgradeComponent({ component: JobListComponent }) as angular.IDirectiveFactory)
		.directive('userListComponent', downgradeComponent({ component: UserListComponent }) as angular.IDirectiveFactory)
		.directive('recordingListComponent', downgradeComponent({ component: RecordingListComponent }) as angular.IDirectiveFactory)
}
