import fireclipApp from '../../app';
import * as angular from 'angular';
import './upload.panel.component'
import { EventEmitter } from 'events';
import { Channel } from '../channel/channelService';
const tus = require('tus-js-client');


class uploader extends EventEmitter {

	progress: number = 0;
	status: 'starting' | 'paused' | 'uploading' | 'success' | 'errored' = 'starting'
	lastUpdateTime: number;
	milisecondsToEnd: number;
	speed: number;
	lastUpdateSize: any = 0;
	upload: any;
	errorMessage: string
	getFile() {
		let endpoint = this.upload.options.endpoint;
		if (this.upload.options.endpoint.slice(-1) !== '/') endpoint += '/';
		return this.upload.url.replace(endpoint, '');
	}
	async cancel() {
		this.status = 'success';
		this.emit('errored');
		return new Promise((f, r) => {
			this.upload.abort(true, (error) => {
				if (error) r(error)
				else f(null)
			})
		})
	}
	pause() {
		this.status = 'paused'
		this.emit('progress');
		this.upload.abort(false, (error) => {
			if (error) {
				this.status = 'errored';
			}
			this.emit('progress');
		})
	}
	unpause() {
		this.errorMessage = null;
		this.status = 'starting'
		this.upload.start();
		this.emit('progress')
	}
	constructor(endpoint, _meta, file, part = false, start?: number, end?: number) {
		super();
		// https://github.com/tus/tus-js-client#tusdefaultoptions
		var options = {
			endpoint,
			resume: true,
			chunkSize: 80 * 1024 * 1024,
			retryDelays: [0, 1000, 3000, 5000],
			removeFingerprintOnSuccess: true,
			onError: this.onError.bind(this),
			onProgress: this.onProgress.bind(this),
			onSuccess: this.onSuccess.bind(this),
			headers: {},
		}
		let fileToUpload = file;
		if (part) {
			options.headers['Upload-Concat'] = 'partial';
			fileToUpload = file.slice(start, end, file.type);
		}
		this.upload = new tus.Upload(fileToUpload, options);
		this.lastUpdateTime = Date.now();
		this.upload.start();
	}
	onProgress(bytesSent, bytesTotal) {
		this.status = 'uploading'
		if (this.lastUpdateTime === 0 || this.lastUpdateTime - Date.now() > 5000) {
			this.lastUpdateTime = Date.now();
			this.lastUpdateSize = bytesSent;
		} else {
			this.speed = (bytesSent - this.lastUpdateSize) / (Date.now() - this.lastUpdateTime); //bytes per milisecond
			this.milisecondsToEnd = (bytesTotal - bytesSent) / this.speed;
		}
		this.progress = Math.round((bytesSent / bytesTotal) * 100);
		// console.log(bytesSent, bytesTotal, Math.round(this.milisecondsToEnd / 1000) + 's', Math.round(this.speed * 1000) + 'bps');
		this.emit('progress')
	}
	onSuccess() {
		this.status = 'success';
		this.emit('progress')
		this.emit('success')
		return;
	}
	onError(error) {
		this.status = 'errored';
		this.errorMessage = error.message;
		this.emit('progress')
		this.emit('error', error)
		return;
	}
	end() {
		// remove fingerprint.
		// this.upload.fingreprint
	}
}

class PanelController {
	constructor(public mdPanelRef) {
		'ngInject';
	}
}

class UploadService extends EventEmitter {
	panel: angular.material.IPanelRef;
	async cancelAll() {
		return await Promise.all(this.uploads.filter(u => u.status == 'starting' || u.status == 'uploading' || u.status == 'paused').map(u => u.cancel()));
	}
	uploads: uploader[] = [];
	windowClosingListener = false

	constructor(private $mdPanel: angular.material.IPanelService, private Library) {
		'ngInject';
		super();
	}

	preventClosingWindow(e) {
		e.preventDefault()
		e.returnValue = ''
	}

	addUpload(file: File, channel: Channel, recording: FireClip.Recording, metadata) {
		let errorMessage = null;
		if (!file || !channel || !recording) {
			alert('error, faltan parametros, seleccionar channel, archivo o recording')
			return;
		}
		const inputs = channel.inputs.filter(i => i.recordingId === recording.id);
		if (inputs.length === 0) {
			errorMessage = 'Sin entradas para esta ingesta! Comunicarse con soporte';
		} else if (inputs.length > 1) {
			errorMessage = 'Mas de una entrada encontrada para esta ingesta! Comunicarse con soporte.';
		}
		if (errorMessage) {
			alert(errorMessage);
			return;
		}
		const input = inputs[0];
		let exporter = channel.exporters.find(e => e.id === input.automaticExporting);
		if (!exporter) errorMessage = 'no hay exportador automático';
		let tusEndpoint = recording.input.tusEndpoint;

		if (!tusEndpoint) {
			alert('no endpoint configured');
			return
		}
		const upload = new uploader(tusEndpoint, metadata, file)

		this.setWindowClosingPrevention()

		upload.on('progress', () => {
			this.emit('progress');
		})
		upload.on('success', () => {
			this.end(upload, channel, recording, exporter)
		})

		upload.on('error', (e) => {
			alert('ha ocurrido un error al procesar la subida:')
			console.error(e)
			this.removeWindowClosingPrevention()

		})
		this.openPanel();
		this.uploads.push(upload);
		this.emit('added')
	}
	private setWindowClosingPrevention() {
		if (!this.windowClosingListener) {
			this.windowClosingListener = true
			window.addEventListener('beforeunload', this.preventClosingWindow)
		}
	}
	private removeWindowClosingPrevention() {
		const pendingUploads = this.uploads.filter(u => u.status == 'starting' || u.status == 'uploading' || u.status == 'paused')

		if (!pendingUploads.length) {
			if (this.windowClosingListener) {
				window.removeEventListener('beforeunload', this.preventClosingWindow)
				this.windowClosingListener = false
			}
		}
	}
	private end(uploader: uploader, channel: Channel, recording: FireClip.Recording, exporter: FireClip.Exporter) {
		const file = uploader.getFile();

		this.Library.save({
			"title": uploader.upload.file.name,
			"path": recording.input.tusPath,
			"filePrefix": file,
			"ext": "",
			"recording": recording.id,
			"channel": channel.id,
			"export": {
				"exporterId": exporter?.id,
				"data": {
					"name": uploader?.upload.file.name
				}
			}
		})
			.$promise
			.then(() => {
				uploader.upload.abort(false, (error) => {
					if (error) {
						alert(error);
					}
				})
			})
			.catch(error => {
				console.error(error)
				alert(error?.data?.message || 'Error al crear biblioteca, re-intenta')
			})
		this.removeWindowClosingPrevention()
	}
	openPanel() {
		if (this.panel) {
			this.panel.open()
		} else {
			this.uploads = [];
			this.panel = this.$mdPanel.create({
				attachTo: angular.element(document.body),
				disableParentScroll: false, // this.disableParentScroll,
				controller: PanelController,
				controllerAs: '$ctrl',
				template: '<fc-upload-panel panel="$ctrl.mdPanelRef"/>',
				hasBackdrop: false,
				panelClass: 'md-whiteframe-4dp',
				position: this.$mdPanel.newPanelPosition().absolute().bottom().end(),
				propagateContainerEvents: true,
				onDomRemoved: () => {
					this.panel.destroy()
					this.panel = null;
				}
			})
			this.panel.open()
		}
	}
}
fireclipApp.service('UploadService', UploadService)

export { UploadService, uploader }