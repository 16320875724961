import * as  angular from 'angular';
import { copyToClipboard } from '../../utils';

const template = require('./index.pug');
const image = require('./c9.svg');

class Service implements FireClip.ExportService {
  async defaultAction(theExport, _media) {
    return copyToClipboard(theExport.result.url).then(() => "Texto copiado")
  }
}

class Controller implements angular.IComponentController {
  exporter: any;
  job: any;
  media: any;
  result: any;
  async $onInit() {
    if (this.job.status == 3) {
      this.result = this.job.result;
    }
  }
}
angular.module('fireclip').component('exporterC9', {
  template: template,
  controller: Controller,
  bindings: {
    job: '<',
    media: '<',
    exporter: '<'
  }
});

export default {
  code: 'c9',
  template,
  controller: Controller,
  image,
  service: new Service(),
}