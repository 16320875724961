/* eslint-disable indent */
import { Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export function validUrlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let protocol = null;
    try{
       protocol = (new URL(`${control.value||""}`.toLowerCase()).protocol);
    }
    catch(e) {
      //
    }
    return (protocol) ? null : { validUrl: "URL inválida"  };
    
  };
}

@Directive({
	selector: '[validUrl]',
	providers: [{ provide: NG_VALIDATORS, useExisting: ValidUrlValidatorDirective, multi: true }]
})
export class ValidUrlValidatorDirective implements Validator {
  // @Input('validUrl') data = '';

  validate(control: AbstractControl): ValidationErrors | null {
    return validUrlValidator()(control);
  }
}