import ChannelService from "../channel/channelService";

class SocialProviderService {
  allProviders = [];
  providers = {}
  providerTypes = [];

  constructor(private $auth, private Provider, private $mdDialog, private ChannelService: ChannelService) {
    'ngInject';

    this.providerTypes = Provider.getTypes();
  }
  login(name) {
    // in the future, params, name, data, in data send the client-id or other information
    // return this.$auth.authenticate(name, {clientId: '1234'})
    return this.$auth.authenticate(name)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.message.toLowerCase().includes("the popup window was closed")) {
          return
        }

        alert(error.message)
        console.error(error, 'auth-authenticate-google')
      })
  }
  configureProviders(channel) {
    this.providerTypes.$promise.then((providerTypes) => {
      this.Provider
        .query({
          where: {
            id: channel.providers
          }
        }).$promise
        .then((providers) => {
          this.allProviders = providers;
          this.providers = {};
          providerTypes
            .forEach((prov) => {
              this.providers[prov.code] = this.allProviders.filter((i) => i.code === prov.code)
            })
        });
    })
  }
  getProviderTypeByCode(code: string) {
    return this.providerTypes.find(provType => provType.code === code)
  }
  async getProvidersById(channelId: string, exporterId: string, type: string): Promise<Array<Object>> {
    return this.ChannelService.getProvidersByExporterAndType(channelId, exporterId, type);
  }

  async openCraeteEditProvider($mdDialog, ev, provider, providerType) {
    if (!provider
      && ['soundcloud', 'google', 'twitter', 'facebook'].indexOf(providerType.code) !== -1)
      return this.login(providerType.code);
    if (!provider) provider = { code: providerType.code };
    class DialogController {
      providerType: any;
      provider: any;
      $mdDialog: any;
      constructor(provider, providerType, $mdDialog) {
        'ngInject';

        this.$mdDialog = $mdDialog;
        this.provider = provider;
        this.providerType = providerType;
      }
    }

    try {
      const dialogResult = await $mdDialog.show({
        locals: {
          providerType,
          provider,
        },
        controller: DialogController,
        controllerAs: '$ctrl',
        template: require('../provider/provider/provider.dialog.pug'),
        targetEvent: ev,
        multiple: true
      })

      if (dialogResult.id) {
        return await this.Provider.update({ id: dialogResult.id }, dialogResult).$promise
      } else {
        return await this.Provider.save(dialogResult).$promise
      }
    } catch (error) {
      if (error) {
        throw new Error(error.data.message);
      }
    }
  }
  getLabel(type: string): string {
    const element = this.providerTypes.find(provider => provider.code === type);
    return (element ? `Cuenta ${element.name}` : 'no label');
  }
  async AddAccountToExporter(ev: any, providerType: string, channelId: string, exporterId: string): Promise<boolean> {
    if (!this.providerTypes.find(provider => provider.code === providerType)) {
      throw new Error("Invalid provider type");
    }
    try {
      const typeProvider = this.getProviderTypeByCode(providerType)
      const prov = await this.openCraeteEditProvider(this.$mdDialog, ev, null, typeProvider)
      if (!prov) { return false }
      await this.ChannelService.addAccountToProvider(channelId, exporterId, providerType, prov.id)
      return true
    } catch (error) {
      if (error) { throw error }
    }
  }

  async RemoveAccountFromExporter(ev: any, providerType: string, channelId: string, providerId: string, exporterId: string): Promise<void> {
    if (!this.providerTypes.find(provider => provider.code === providerType)) {
      throw new Error("Invalid provider type");
    }
    try {
      const confirmDialog = this.$mdDialog.confirm()
        .multiple(true)
        .title('Quitar cuenta')
        .textContent('¿Estás seguro de quitar la cuenta seleccionada?')
        .ariaLabel('Borrar media')
        .targetEvent(ev)
        .ok('Borrar')
        .cancel('Cancelar');

      await this.$mdDialog.show(confirmDialog)
      await this.ChannelService.removeAccountFromProvider(channelId, exporterId, providerType, providerId)
      return;
    } catch (error) {
      if (error) {
        throw new Error(error.data.message);
      }
    }
  }

}

export default SocialProviderService