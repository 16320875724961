import fireclipApp from "../../app";
import { safeApply } from "../../utils";
import { UploadService } from "./upload.service"
import { IComponentController } from "angular";
require('./upload.panel.styl')

class UploadPanelController implements IComponentController{
	title: string = 'Subida'
	subtitle: string = 'Subiendo'
	minimized = false;
	panel: any;
	constructor($scope, private UploadService: UploadService, private $mdDialog) {
		'ngInject';
		this.calcTitles()
		UploadService.on('added', () => {
			this.minimized = false;
			this.calcTitles()
			safeApply($scope);
		})
		UploadService.on('progress', () => {
			this.calcTitles()
			safeApply($scope)
		})
		UploadService.on('error', () => {
			safeApply($scope);
		})
	}
	$onInit() {
		console.log(this.panel, '<!!!')
	}
	close(event) {
		if (this.UploadService.uploads.some(u => u.status === 'uploading' || u.status === 'starting')) {
			var confirm = this.$mdDialog.confirm()
				.title('¿Cancelar subidas?')
				.textContent('Aún hay subidas en proceso')
				.targetEvent(event)
				.ok('Cancelar descargas')
				.cancel('Continuar descargas');
			this.$mdDialog
				.show(confirm)
				.then(() => {
					this.UploadService
						.cancelAll()
						.then(() => {
							this.panel.close();
						})
						.catch(error => {
							console.error(error);
							alert('Error al cancelar las descargas re-intent')
						})
				}, () => {

				});
		} else {
			this.panel.close();
		}
	}
	calcTitles() {
		let filtered = this.UploadService.uploads.filter(u => u.status === 'starting');
		this.title = `Preparando ${filtered.length} subidas`;
		this.subtitle = '';
		filtered = this.UploadService.uploads.filter(u => u.status === 'errored')
		if (filtered.length > 0) {
			this.title = `Error al subir archivos`
			this.subtitle = `Error en ${filtered.length} subidas`
		} else {
			filtered = this.UploadService.uploads.filter(u => u.status === 'uploading')
			if (filtered.length > 0) {
				this.title = `Subiendo ${filtered.length} item${filtered.length != 1 ? 's' : ''}`
				const millisecondsToEnd = filtered.reduce((prev, u) => u.milisecondsToEnd > prev ? u.milisecondsToEnd : prev, 0);
				this.subtitle = `Faltan ${Math.ceil(millisecondsToEnd / 60000)} min`
			} else {
				filtered = this.UploadService.uploads.filter(u => u.status === 'paused')
				if (filtered.length > 0) {
					this.title = `${filtered.length} subida${filtered.length != 1 ? 's' : ''} canceladas${filtered.length != 1 ? 's' : ''}`
				} else {
					filtered = this.UploadService.uploads.filter(u => u.status === 'success')
					if (filtered.length > 0) {
						this.title = `${filtered.length} subida${filtered.length != 1 ? 's' : ''} completada${filtered.length != 1 ? 's' : ''}`
					}
				}
			}
		}
	}
}
const name = 'fcUploadPanel';

fireclipApp.component(name, {
	template: require('./upload.panel.pug'),
	controller: UploadPanelController,
	bindings: {
		panel: '<'
	}
})

export { name as UploadPanelComponent }