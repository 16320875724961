import socketService from '../socketService';
import ChannelService, { Channel } from '../channel/channelService';
import MediaService from '../media/media.service';
import PlayerService from '../../angular-video-player/service';
import * as angular from 'angular';
import { safeApply } from '../../utils';
import ExportService from '../exporter/exportService';
import { LibraryService } from '../library/library.service';
import { timeLine as TimelineComponent } from '../../timeline/timeline.component';
import Thumbnail from '../../timeline/thumbnail';
import { EditorService, EditorLib, EditorClip } from '../editor/editor.service';
import { takeUntil } from 'rxjs/operators';
require('./video.styl')

class videoController implements angular.IComponentController {
	showClipControls = true
	playerService: PlayerService;
	timelineComponent: TimelineComponent;
	editorService: EditorService
	player: any;
	playerTech: any;
	debug: boolean;
	volume: number;
	mute: boolean = false;
	play: boolean = false;
	recording: FireClip.Recording;
	channel: Channel;
	firstTime: boolean = true;
	onMediaSave: () => void
	lastExports: object[] = []
	imageExportClass = false;
	ui = {
		customDayPickerActive: false,
		customDaySelected: null,
	};
	imageDownloadService = new Thumbnail();

	constructor(private $scope: any, $location, private $mdDialog, private $mdPanel: angular.material.IPanelService, private $mdToast: angular.material.IToastService, private ChannelService: ChannelService, private MediaService: MediaService, private ExportService: ExportService, private LibraryService: LibraryService) {
		"ngInject";
		// TODO: on $location change..
		this.player = $location.search().player || theConfig.playerSelection;
		this.playerTech = $location.search().tech || theConfig.playerTech;
		this.debug = !!$location.search().debug;
		// $scope.cs = ClipService;
		$scope.deafultImageQuality = 0;

		$scope.keyup = function ($event) {
			$scope.keyPressed = $event.keyCode;
			const code = $event.keyCode;
			if (code == 37) { // left arrow
			} else if (code == 39) { // right arrow
			} else if (code == 32) { // space
				this.togglePlay();
			}
		};

		$scope.keyPressed = '';
		$scope.sideBarVisible = true;
		$scope.brand = false;
		$scope.intro = false;
		$scope.loading = true;
	}

	// use arrow function so it preserver this when called in event
	addLibraryToEditorService = (library: FireClip.Library) => {
		this.editorService.addLibrary(
			library.id,
			library.start.getTime(),
			library.duration * 1000,
			this.ChannelService.getVodURL(library, this.recording),
			(cache: Thumbnail) => {
				if (!cache) cache = new Thumbnail()
				return (time: number) => this.LibraryService.getImage(library, time, cache)
			}
		)
	}
	updateLibraryToEditorService = (library: FireClip.Library) => {
		this.editorService.modifyLibrary(library.id, library.start.getTime(), library.duration * 1000);
	}
	$onChanges(changes) {
		if (changes.recording && !changes.recording.isFirstChange()) {
			this.firstTime = true;
			this.editorService.clearLibraries()
			this.playerService.reset();

			this.LibraryService.startSubscription(changes.recording.currentValue.id)
			const range = this.timelineComponent.macroRange$.getValue()

			this.LibraryService
				.queryAndAddLibrariesToStore(this.recording.id, new Date(range.start), new Date(range.end))
				.then(libs => {
					// TODO: if clips are out of range, the system must request? 

					libs.forEach(library => this.addLibraryToEditorService(library))
					this.editorService.play()
				})
		}
	}

	$onInit() {
		socketService.onJobChange(this.onJobUpdate);
		if (this.editorService.timeLineConfig.type === 'absolute') {
			this.LibraryService.startSubscription(this.recording.id);
			this.LibraryService.on('libraryAdded', this.addLibraryToEditorService);
			this.LibraryService.on('libraryUpdated', this.updateLibraryToEditorService);
		}
	}
	$onDestroy() {
		socketService.offJobChange(this.onJobUpdate);
		if (this.editorService.timeLineConfig.type === 'absolute') {
			this.LibraryService.off('libraryAdded', this.addLibraryToEditorService)
			this.LibraryService.off('libraryUpdated', this.updateLibraryToEditorService);
			this.LibraryService.clearSubscritionAndLibraries(this.recording.id);
		}
		this.editorService.clearLibraries()
		// this.playerService.dispose();
		// this.playerService.offPlayerReady();
	}

	setVolume(value) {
		this.volume = value;
		this.playerService.volume = value;
	};

	/**
	 * called from angular-video-player/component.ts
	 */
	initPlayer(playerService: PlayerService) {
		this.playerService = playerService;
		this.editorService.play$.subscribe(data => {
			if (!data) return;
			this.editorService.stopTime = null;
			this.playerService.seekOrSetup(data.library.url, data.position / 1000);
		})
		this.playerService.on('play', () => {
			safeApply(this.$scope, () => {
				this.play = true;
			})
		})
		this.playerService.on('timeupdate', () => {
			if (this.playerService.duration() > 0) {
				this.editorService.updatePlay(this.playerService.position() * 1000);
				if (this.editorService.stopTime !== null && this.editorService.getPlayTime() >= this.editorService.stopTime) {
					this.editorService.stopTime = null;
					this.playerService.pause();
				}
			}
		})
		this.playerService.on('pause', () => { safeApply(this.$scope, () => { this.play = false; }) });
		this.playerService.on('mute', (response) => { safeApply(this.$scope, () => { this.mute = response.mute; }) });
		this.playerService.once('player-ready', () => {
			safeApply(this.$scope, () => {
				this.play = this.playerService.isPlaying();
				this.volume = this.playerService.volume;
			})
		});
	}
	initTimeline(timelineComponent: TimelineComponent) {
		this.timelineComponent = timelineComponent;
		this.editorService.playTime$.subscribe((playTime) => {
			if (playTime)
				this.timelineComponent.setPlayTime(playTime.library.id, playTime.position);
		})
		/**
		 * TODO: could this be avoided using rxjs replay (or something like that, so it addLibrary will replay all preview additions)
		 * 
		 * this happens bc in editor-vod.component i'm adding a library programaticaly... 
		 * and this happens before the subscription
		 */
		const setuplib = (eLibrary: EditorLib) => {
			const tlLib = timelineComponent.addLibrary(
				eLibrary.id,
				eLibrary.start,
				eLibrary.end,
				eLibrary.title,
				eLibrary.getImageCanvas(this.imageDownloadService)
			)
			eLibrary.start$
				.pipe(takeUntil(eLibrary.destroy$))
				.subscribe((start) => tlLib.start$.next(start))
			eLibrary.duration$
				.pipe(takeUntil(eLibrary.destroy$))
				.subscribe((duration) => tlLib.duration$.next(duration))
			eLibrary.destroy$.subscribe(() => tlLib.destroy())
		}
		if (this.editorService.libraries.length > 0) {
			this.editorService.libraries.forEach(setuplib)
		}
		this.editorService.addLibrary$.subscribe(setuplib)

		timelineComponent.on('play', (id, position) => {
			this.editorService.play(id, position);
		})
		timelineComponent.macroRange$.subscribe(async (range) => {
			if (this.editorService.timeLineConfig.type === 'absolute') {
				const libs = await this.LibraryService.queryAndAddLibrariesToStore(this.recording.id, new Date(range.start), new Date(range.end));
				libs.forEach(library => {
					this.addLibraryToEditorService(library);
				})
				if (this.firstTime) {
					this.editorService.play();
					this.firstTime = false;
				}
			}
		})
		timelineComponent.on('clipCreate', (start) => {
			this.editorService.addClip(start.getTime(), 30 * 1000, true);
		})
		timelineComponent.on('clipFinishUpdate', (_id, action) => {
			if (action === 'leftMove') {
				this.editorService.playCurrentStart()
			} else if (action === 'rightMove') {
				this.editorService.playCurrentEnd()
			}
		})
		const addClip = (editorClip: EditorClip) => {
			const tlClip = timelineComponent.addClip(editorClip.id, editorClip.start, editorClip.end, editorClip.type, editorClip.selected)
			// TODO: maybe .. editorClip.delete$ ? 
			editorClip.start$.subscribe({
				complete: () => {
					tlClip.delete()
				}
			})
			tlClip.start$.subscribe(start => editorClip.start = start)
			tlClip.end$.subscribe(end => editorClip.end = end)
			tlClip.selected$.subscribe(selected => editorClip.selected = selected);

			editorClip.start$.subscribe(start => tlClip.start = start)
			editorClip.end$.subscribe(end => tlClip.end = end)
			editorClip.selected$.subscribe(selected => tlClip.selected = selected);
		}
		if (this.editorService.clips.length > 0) {
			this.editorService.clips.forEach(addClip)
		}
		this.editorService.addClip$.subscribe(addClip)
	}

	onJobUpdate = (job) => {
		if (!job.root && job.type === 'image' && job.status === 3) {
			const media = this.MediaService.medias.find(m => m.id === job.media)
			if (media) {
				safeApply(this.$scope, () => {
					this.imageExportClass = true
					this.lastExports.unshift(job)
					setTimeout(() => {
						safeApply(this.$scope, () => {
							this.imageExportClass = false;
						})
					}, 1000);
				})
			}
		}
	}
	setDay(selected) {
		if (!selected) {
			return false;
		}
		if (selected > new Date()) {
			this.$mdDialog.show(this.$mdDialog.alert({
				title: 'Doh',
				htmlContent: 'Sólo puedes seleccionar fechas anteriores a hoy',
				ok: 'Aceptar',
			}));
			this.ui.customDaySelected = null;
			return false;
		}
		this.ui.customDaySelected = selected;
		this.customDay(selected);
	}
	toggleCustomDayPicker(event) {
		const position = this.$mdPanel
			.newPanelPosition()
			.relativeTo(event.target)
			.addPanelPosition(this.$mdPanel.xPosition.ALIGN_START, this.$mdPanel.yPosition.BELOW);
		class DatePanel {
			maxDate = new Date(Date.now() + 3600000)
			constructor(private mdPanelRef: angular.material.IPanelRef, public selected: Date, public controller: videoController) {
				'ngInject';
			}
			onChange() {
				this.controller.setDay(this.selected)
				this.mdPanelRef.close();
			}
		}
		this.$mdPanel.open({
			attachTo: angular.element(document.body),
			controller: DatePanel,
			controllerAs: 'ctrl',
			template: '<md-content><md-calendar ng-model="ctrl.selected" ng-change="ctrl.onChange()" md-max-date="ctrl.maxDate"></md-calendar></md-content>',
			position,
			locals: {
				selected: this.ui.customDaySelected,
				controller: this,
			},
			clickOutsideToClose: true,
			escapeToClose: true,
			focusOnOpen: false,
			zIndex: 2,
		});
	};
	goToPlay() {
		if (this.timelineComponent) {
			this.timelineComponent.goToPlay();
		} else {
			alert('timeline no creado')
		}
	};

	togglePlay() {
		this.playerService.togglePlay()
	}

	resetPlayer() {
		this.playerService.reset();
	};

	async takeSnapshot() {
		const imageExporter = this.channel.exporters.find(e => e.workflowCode === 'image');
		if (!imageExporter) {
			alert('Sin exportador para sacar imagenes, contactar soporte')
			return false
		}
		const playtime = this.editorService.playTime$.getValue();

		// Epoch & Unix Timestamp
		const snapshotTimestamp = this.editorService.getPlayTime();

		const playTimeTitle = new Date(snapshotTimestamp).toLocaleString()
		const newMedia = this.MediaService.createNew(this.channel.id, this.recording?.id, `Imagen ${playTimeTitle}`, `Snapshot canal ${this.channel.name} `);
		newMedia.export = {
			exporterId: imageExporter.id,
			data: {
				library: playtime.library.refId,
				time: playtime.position / 1000,
			}
		};
		const media = await this.MediaService.upsert(newMedia, true)
		if (media) {
			this.onMediaSave()
		} else {
			console.error('no media created for snapshot')
		}
		return media
	}

	async createGif() {
		const gifExporter = this.channel.exporters.find(e => e.workflowCode === 'gif');
		if (!gifExporter) {
			alert('Sin exportador para sacar GIF, contactar soporte')
			return false
		}
		if (!this.editorService.selected) {
			alert('sin clip seleccionado');
			return false
		}
		const clip = this.editorService.selected;
		if (clip.errored) {
			alert('clip debe ser correkido');
			return false
		}
		const playTimeTitle = new Date(clip.start).toLocaleString()
		const newMedia = this.MediaService.createNew(this.channel.id, this.recording.id, `GIF ${playTimeTitle}`, `GIF canal ${this.channel.name} `);
		newMedia.export = {
			exporterId: gifExporter.id,
			data: {
				ini: (clip.start - clip.startLib.start) / 1000,
				duration: (clip.end - clip.start) / 1000,
				library: clip.startLib.refId,
			}
		};
		const media = this.MediaService.upsert(newMedia, true)
		if (media) this.onMediaSave()
		return media
	}

	customDay(date: Date) {
		this.editorService.timeLineConfig.master = {
			visible: true,
			start: date.getTime(),
			end: date.getTime() + 24 * 3600 * 1000
		}
	};

	getExportData(theExport: FireClip.Job) {
		try {
			const media = this.MediaService.medias.find(m => m.id === theExport.media)
			if (media) {
				const exporter = this.ChannelService.activeChannel.exporters.find(e => e.id === theExport.exporterId)
				const theRealExport = media.exports.find(e => e.exporterId === theExport.exporterId)
				return this.ExportService.getDefaultData(exporter, media, theRealExport);
			}
		} catch (error) {
			console.error(error.message)
		}
		return null
	}
	async openExport(theExport: FireClip.Job) {
		try {
			const media = this.MediaService.medias.find(m => m.id === theExport.media)
			const exporter = this.ChannelService.activeChannel.exporters.find(e => e.id === theExport.exporterId)
			const theRealExport = media.exports.find(e => e.exporterId === theExport.exporterId)
			const message = await this.ExportService.executeDefaultAction(exporter, media, theRealExport);
			this.$mdToast.show(
				this.$mdToast.simple()
					.textContent(message)
					.position('top right')
					.hideDelay(3000)
					.parent(angular.element(document.body)));
		} catch (error) {
			alert(error.message)
		}
	}
	center() {
		if (this.editorService.clips.length > 0) {
			let min = this.editorService.clips[0].start;
			let max = this.editorService.clips[0].end;
			this.editorService.clips.forEach(clip => {
				if (clip.end > max) max = clip.end;
				if (clip.start < min) min = clip.start;
			})
			this.timelineComponent.centerOn((min + max) / 2)
		}
	}
}

export default {
	'fcVideo': {
		bindings: {
			recording: '<',
			channel: '<',
			onMediaSave: '&',
			editorService: '<',
		},
		controller: videoController,
		template: require('./video.pug')
	}
}




