import { StateService } from "@uirouter/angularjs"
import { homeController } from "./home.component";
import { Channel } from "../channel/channelService";
import Auth0Service from "../auth/auth0.service";

class NavController implements angular.IComponentController {
	channels: Channel[]
	parent: homeController
	profile: any;
	constructor(private $state: StateService, private Auth0Service: Auth0Service) {
		'ngInject';
		this.profile = this.Auth0Service.getProfile();
	}
	goToChannel(channel: Channel) {
		this.parent.openSidenav = false;
		this.$state.go('main.channel', { channel: channel.id });
	}
}
const navComponent: IComponent = {
	'fcNav': {
		controller: NavController,
		template: require('./nav.pug'),
		bindings: {
			channels: '<'
		},
		require: {
			parent: '^^fcHome'
		}
	}
}
export { navComponent }