import * as angular from 'angular';
import { mediaURL } from '../../utils';
const template = require('./index.pug')
const image = require('./gif.svg');

class ExporterGifService implements FireClip.ExportService {
	async defaultAction(theExport, media) {
		let image = mediaURL(theExport.result)
		if (image) {
			ExporterGifService.downloadImage(image, media.title)
			return 'Descargando GIF'
		} else {
			return 'No se ha encontrado el GIF.'
		}
	}
	static downloadImage(image, name) {
		const fileName = `${name}.gif`;
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.style.display = 'none';
		a.href = image
		a.download = fileName
		a.click()
		document.body.removeChild(a)
	}
}

class Controller {
	job: any;
	images: []
	result: string;
	constructor() {
		'ngInject';

	}
	$onInit() {
		if (this.job.status == 3) {
			this.result = mediaURL(this.job.result)
		}
	}
}
angular.module('fireclip').component('exporterGif', {
	template,
	controller: Controller,
	bindings: {
		job: '<',
		media: '<',
		exporter: '<'
	}
});

export default {
	code: 'gif',
	template,
	controller: Controller,
	image,
	service: new ExporterGifService(),
}