enum uiActions {
  // Exporter actions
  updateExporter = 'updateExporter',
  // Ingest actions
  rebootIngest = 'rebootIngest',
  enableIngest = 'enableIngest',
  addIngest = 'addIngest',
  editIngest = 'editIngest',
  deleteIngest = 'deleteIngest'
}

export default uiActions;