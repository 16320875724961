export default function Job($resource, API_URL) {
	'ngInject';

	return $resource(`${API_URL}job/:id`, {}, {
		resend: {
			url: `${API_URL}job/:id/resend`,
			method: 'GET',
		},
		restart: {
			url: `${API_URL}job/:id/restart`,
			method: 'GET',
		},
	});
}