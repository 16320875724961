import app from '../../app'
import * as angular from 'angular';
import { StateService } from '@uirouter/core';
import { UploadService } from '../upload/upload.service';
import '../upload/upload.button'
import { Channel, Input } from '../channel/channelService';



class uploadDialogController {
	progress: number;
	working: boolean;
	file: File;
	error: any;
	title: string;
	constructor(private $mdDialog, private $mdToast) {
		'ngInject'
		this.progress = 0;
		this.working = false;
	}

	async uploadMedia(file: File) {
		this.error = null;
		if (!file) {
			this.$mdToast.show(this.$mdToast.simple().textContent('No se ha seleccionado ningún archivo'));
			return;
		}
		this.working = true;
		this.file = file;
		this.$mdDialog.hide({
			metadata: { title: this.title },
			file
		});
	}
	close() {
		this.$mdDialog.cancel();
	};
}

const name = 'fcManagerLibraryHeader';
require('../upload/upload.service')
class LibraryHeaderController implements IComponentControllerUIRouter {
	channel: Channel
	input: Input;
	constructor(private $state: StateService, private UploadService: UploadService, private $mdDialog) {
		'ngInject';
	}
	goToInput() {
		this.$state.go('.', { input: this.input.id, library: null });
	}
	openUpload(event) {
		this.$mdDialog.show({
			controller: uploadDialogController,
			controllerAs: '$ctrl',
			locals: {
				channel: this.channel,
				recording: this.input.recording,
			},
			template: require('./dialogUpload.pug'),
			parent: angular.element(document.body),
			targetEvent: event
		}).then((data) => {
			this.UploadService.addUpload(data.file, this.channel, this.input.recording, data.metadata);
		})
	}
	doUpload(file: File) {
		this.UploadService.addUpload(file, this.channel, this.input.recording, {});
	}
}
app.component(name, {
	controller: LibraryHeaderController,
	template: require('./manager.library.header.pug'),
	bindings: {
		channel: '<',
		input: '<',
	}
})
export { name as fcManagerLibraryHeaderComponent }