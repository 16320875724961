import * as angular from 'angular';
import { copyToClipboard } from '../../utils';

const template = require('./index.pug')
const image = require('./youtube-dark-background.svg');

class Service implements FireClip.ExportService {
	async defaultAction(theExport, _media) {
		if (theExport.result) {
			return copyToClipboard(theExport.result.url).then(() => "Texto copiado")
		} else {
			return "No se puede aplicar acción rápida porque el exportador falló"
		}
	}
}
class Controller {
	exporter: FireClip.Exporter;
	job: any;
	result: any;
	media: any;
	embedUrl: string;
	constructor() {
		'ngInject';
	}
	async $onInit() {
		if (this.job.status == 0) {
			this.job.data.title = this.media.title;
			this.job.data.description = this.media.description;
			this.job.data.tags = this.media.tags || [];
		} else if (this.job.status == 3) {
			this.result = this.job.result.url;
			if (this.job.childs) {
				const youtubeJob = this.job.childs.find(j => j.type === 'youtube');
				if (youtubeJob) {
					this.embedUrl = `https://www.youtube.com/embed/${youtubeJob.result.id}?rel=0&controls=0&showinfo=0`
				}
			}
		}
	}
}
angular.module('fireclip').component('exporterYoutube', {
	template,
	controller: Controller,
	bindings: {
		job: '<',
		media: '<',
		exporter: '<',
	}
});


export default {
	code: 'youtube',
	template,
	controller: Controller,
	image,
	service: new Service(),
}