import MiniScroll from "./miniScroll"
import Konva from 'konva';
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { filter, distinctUntilChanged, takeUntil } from "rxjs/operators";
import MacroScroll from "./macroScroll";

export class Library {
	color = '#ddd'
	title: string = null
	getThumbnailCanvasForTime: { (time: number): Promise<HTMLCanvasElement> }
	commercials?: { start: number, end: number }[]
	discontinuity_times?: number[]
	start$: BehaviorSubject<number>;
	duration$: BehaviorSubject<number>
	destroy$ = new Subject();
	constructor(public readonly id: string, start: number, end: number) {
		this.start$ = new BehaviorSubject(start);
		this.duration$ = new BehaviorSubject(end - this.start);
	}
	public get start(): number {
		return this.start$.getValue()
	}
	public set start(value) {
		this.start$.next(value)
	}
	public get end(): number {
		return this.start + this.duration
	}
	public get duration(): number {
		return this.duration$.getValue()
	}
	public destroy() {
		this.destroy$.next()
		this.destroy$.complete();
	}
}

export class SmallLibrary {
	visible$ = new BehaviorSubject(false)
	rect: Konva.Rect;
	title: Konva.Text;
	constructor(public readonly lib: Library, private detailTimeline: MiniScroll, private group: Konva.Group) {
		//TODO: same as BigLibrary but there is no this.detailTimeline.ratio$ !!! 
		this.detailTimeline
			.range$
			.pipe(filter(r => r !== null), takeUntil(lib.destroy$))
			.subscribe((range) => {
				this.visible$.next(this.isVisible(range))
			})
		this.visible$
			.pipe(distinctUntilChanged(), takeUntil(lib.destroy$))
			.subscribe((visible) => {
				if (visible) {
					if (this.rect) {
						this.calcUI()
					} else {
						this.createUI()
					}
				} else {
					if (this.rect) {
						this.rect.destroy();
						this.rect = null;
					}
					if (this.title) {
						this.title.destroy();
						this.title = null;
					}
				}
			})
		// TODO: merge or something
		this.lib.start$
			.pipe(distinctUntilChanged(), takeUntil(lib.destroy$))
			.subscribe(() => {
				this.visible$.next(this.isVisible(this.detailTimeline.range$.getValue()))
				if (this.visible$.getValue()) {
					this.calcUI();
					this.detailTimeline.layer.batchDraw()
				}
			})
		this.lib.duration$
			.pipe(distinctUntilChanged(), takeUntil(lib.destroy$))
			.subscribe(() => {
				this.visible$.next(this.isVisible(this.detailTimeline.range$.getValue()))
				if (this.visible$.getValue()) {
					this.calcUI();
					this.detailTimeline.layer.batchDraw()
				}
			})
		combineLatest(
			this.detailTimeline.width$.pipe(filter(a => a !== 0)),
			this.detailTimeline.range$
		)
			.pipe(takeUntil(lib.destroy$))
			.subscribe(() => {
				if (this.visible$.getValue()) {
					this.calcUI()
					this.detailTimeline.layer.batchDraw()
				}
			})
		lib.destroy$.subscribe(() => {
			this.destroyUI()
		})
	}
	private isVisible(range: TimeLineComponent.Range) {

		if (this.lib.start == null || this.lib.end === null) return false
		/**
		 * ls <= le === true && rs <= re === true
		 * Libraries cases
		 * 1:   |===|                     out: le <= rs 
		 * 2:   |=============|            in:
		 * 3:            |=============|   in:
		 * 4:                      |===|  out: ls >= re
		 * 5:            |====|            in:
		 * 6:   |======================|   in:
		 * range  ----[----------]---   
		 *            rs         re
		 * 1 and 3: ls <= rs && le >= rs
		 * 
		 */
		return !(this.lib.end <= range.start || this.lib.start >= range.end)
	}
	private calcUI() {
		const x = (this.lib.start - this.detailTimeline.range.start) / this.detailTimeline.ratio
		if (this.title) this.title.x(x<0?0:x)
		this.rect.x(x)
		this.rect.width(this.lib.duration / this.detailTimeline.ratio);
		this.rect.fill(this.lib.color)
	}
	private createUI() {
		const bi = this.detailTimeline.range.start;
		if (this.lib.commercials && this.lib.commercials.length > 0) {
			this.lib.commercials.forEach((commercial) => {
				this.detailTimeline.librariesGroup.add(new Konva.Rect({
					offsetY: -10,
					x: (this.lib.start - bi + commercial.start * 1000) / this.detailTimeline.ratio,
					y: 60 - 10 + 2,
					height: 5,
					width: (commercial.end * 1000 - commercial.start * 1000) / this.detailTimeline.ratio,
					fill: this.lib.color,
					opacity: 0.3,
					stroke: '#000',
					strokeWidth: 1,
					strokeHitEnabled: false,
					perfectDrawEnabled: false,
					strokeEnabled: false,
					cornerRadius: 4,
				}));
			});
		}
		if (this.lib.discontinuity_times && this.lib.discontinuity_times.length > 0) {
			this.lib.discontinuity_times.forEach((time) => {
				this.detailTimeline.librariesGroup.add(new Konva.Rect({
					offsetY: -10,
					x: (this.lib.start - bi + (time * 1000)) / this.detailTimeline.ratio,
					y: 0,
					height: 8,
					width: 1000 / this.detailTimeline.ratio,
					fill: '#003300',
					opacity: 0.5,
					stroke: '#000',
					strokeWidth: 1,
					strokeHitEnabled: false,
					perfectDrawEnabled: false,
					strokeEnabled: false,
					// cornerRadius: 4
				}));
			});
		}
		if (this.lib.title) {
			this.title = new Konva.Text({
				x: (this.lib.start - bi) / this.detailTimeline.ratio,
				y: 50,
				width: 100,
				height: 20,
				text: this.lib.title,
				fontSize: 10,
				fontFamily: 'Roboto Mono',
				fill: '#fff',
				align: 'left',
				listening: false,
			})
			this.group.add(this.title);
		}
		this.rect = new Konva.Rect({
			name: 'library',
			x: (this.lib.start - bi) / this.detailTimeline.ratio,
			y: 0,
			height: 50,
			width: (this.lib.duration) / this.detailTimeline.ratio,
			// fill: lib.status == 2 ? '#77b4ac' : lib.status == 3 ? '#c3e38b' : '#f26c62',
			fill: this.lib.color,
			opacity: 0.2,
			stroke: '#fff',
			strokeWidth: 1,
			strokeEnabled: true,
			cornerRadius: 4,
			strokeHitEnabled: false,
			perfectDrawEnabled: false,
		});
		this.group.add(this.rect);
	}
	public destroyUI() {
		if (this.rect) {
			this.rect.destroy();
		}
		if (this.title) {
			this.title.destroy();
		}
	}
}

export class BigLibrary {
	visible$ = new BehaviorSubject(false)
	rect: Konva.Rect;
	constructor(public readonly lib: Library, private timeline: MacroScroll, private group: Konva.Group) {
		this.timeline
			.ratio$
			.pipe(filter(r => r !== 0), takeUntil(lib.destroy$))
			.subscribe(() => {
				this.visible$.next(this.isVisible(this.timeline.range$.getValue()))
			})
		this.timeline
			.range$
			.pipe(filter(r => r !== null), takeUntil(lib.destroy$))
			.subscribe((range) => {
				this.visible$.next(this.isVisible(range))
			})
		this.visible$
			.pipe(distinctUntilChanged(), takeUntil(lib.destroy$))
			.subscribe((visible) => {
				if (visible) {
					if (this.rect) {
						this.calcUI()
					} else {
						this.createUI()
					}
				} else {
					if (this.rect) {
						this.rect.destroy();
						this.rect = null;
					}
				}
			})
		// TODO: merge or something
		this.lib.start$
			.pipe(takeUntil(lib.destroy$))
			.subscribe(() => {
				if (this.visible$.getValue()) {
					this.calcUI();
					this.timeline.layer.batchDraw()
				}
			})
		this.lib.duration$
			.pipe(takeUntil(lib.destroy$))
			.subscribe(() => {
				if (this.visible$.getValue()) {
					this.calcUI();
					this.timeline.layer.batchDraw()
				}
			})
		combineLatest(
			this.timeline.width$.pipe(filter(a => a !== 0)),
			this.timeline.range$
		)
			.pipe(takeUntil(lib.destroy$))
			.subscribe(() => {
				if (this.visible$.getValue()) {
					this.calcUI()
					this.timeline.layer.batchDraw()
				}
			})
		lib.destroy$.subscribe(() => {
			this.destroyUI()
		})
	}

	private isVisible(range: TimeLineComponent.Range) {
		if (this.timeline.ratio$.getValue() === 0) return false;
		if (this.lib.start == null || this.lib.end === null) return false
		/**
		 * ls <= le === true && rs <= re === true
		 * Libraries cases
		 * 1:   |===|                     out: le <= rs 
		 * 2:   |=============|            in:
		 * 3:            |=============|   in:
		 * 4:                      |===|  out: ls >= re
		 * 5:            |====|            in:
		 * 6:   |======================|   in:
		 * range  ----[----------]---   
		 *            rs         re
		 * 1 and 3: ls <= rs && le >= rs
		 * 
		 */
		return !(this.lib.end <= range.start || this.lib.start >= range.end)
	}
	private calcUI() {
		this.rect.x((this.lib.start - this.timeline.range$.getValue().start) / this.timeline.ratio);
		this.rect.width(this.lib.duration / this.timeline.ratio);
		this.rect.fill(this.lib.color)
	}
	private createUI() {
		this.rect = new Konva.Rect({
			x: 0,
			y: 10,
			width: 0,
			height: 20,
			// fill: library.status == 2 ? '#77b4ac' : library.status == 3 ? '#c3e38b' : '#f26c62',
			fill: this.lib.color,
			opacity: 0.2,
			// stroke: '#000',
			// strokeWidth: 1,
			// cornerRadius: 1,
			strokeHitEnabled: false,
			perfectDrawEnabled: false,
			strokeEnabled: true,
		});
		this.calcUI();
		this.group.add(this.rect);
	}
	public destroyUI() {
		if (this.rect) {
			this.rect.destroy()
		}
	}
}
