import * as angular from 'angular';
import WorkflowInterface from '../workflowInterface';

const template = require('./index.pug');

class Controller extends WorkflowInterface {
  providers: { firesportapi: any; imageserverapi: any; amazon: any; };
  filterMatch: { selected: any; seachText: any; dateFilter: boolean; searchTextChange: () => void; selectedItemChange: (item: any) => void; };
  programSelector: { searchTerm: string; };
  result: { videoUrl: any; imageUrl: any; consoleUrl: any; };
  videoTypes: any;
  firesportGeoPolicies: any;
  championships: any;
  categories: any;
  programs: any;
  Provider: angular.resource.IResourceClass<any>;
  constructor($resource: angular.resource.IResourceService, API_URL) {
    'ngInject';
    super();
    this.Provider = $resource(API_URL + 'provider/:id/:path', {}, {});

    this.job
    // TODO: async call to providers if providers fails this will be empty

    this.filterMatch = {
      selected: null,
      seachText: null,
      dateFilter: false,
      searchTextChange: function () { },
      selectedItemChange: (item) => {
        if (item) {
          this.job.data.localTeamId = item.localTeam.id;
          this.job.data.visitorTeamId = item.visitorTeam.id;
          this.job.data.matchId = item.id;
        } else {
          delete this.job.data.localTeamId;
          delete this.job.data.visitorTeamId;
          delete this.job.data.matchId;
        }
      }
    };
    this.programSelector = {
      searchTerm: ''
    }
  }

  async $onInit() {
    this.job.data.provider ||= {}
    if (this.job.data.provider?.firesportapi) {
      this.onFiresportApiChange(this.job.data.provider.firesportapi);
    }
    if (this.job.status === 0) {
      if (this.media.exports) {
        const clipJob = this.media.exports.find(e => e.type === 'clip');
        if (clipJob) {
          let date = new Date(clipJob.data.clips[0].start);
          date.setSeconds(0, 0)
          this.job.data.recordedAt = date;
        }
      }
      this.job.data.title = this.media.title;
      this.job.data.description = this.media.description;
      this.job.data.tags = this.media.tags;
      this.job.data.appId = this.exporter.parameters.appId;
    } else if (this.job.status == 3) {
      if (this.job.type === 'firesport-live') {
        const k = this.job.childs.find(j => j.type === 'firesportapi');
        this.result = {
          videoUrl: k.result.url,
          imageUrl: k.result.image,
          consoleUrl: this.job.result.url
        }
      } else {
        this.result = {
          videoUrl: this.job.childs[0].result.url,
          imageUrl: this.job.childs[0].result.image,
          consoleUrl: this.job.result.url
        }
      }
    }
  }

  onFiresportApiChange(accountId) {
    this.videoTypes = this.Provider.query({ path: "videotype", id: accountId });
    this.firesportGeoPolicies = this.Provider.query({ path: "geopolicy", id: accountId });
    this.championships = this.Provider.query({ path: "championship", id: accountId });
    this.categories = this.Provider.query({ path: "category", id: accountId })
    this.programs = this.Provider.query({ path: "program", id: accountId })
    this.categories.$promise.catch(error => {
      console.error(error);
    });
    this.programs.$promise.catch(error => {
      console.error(error);
    });
  }
  searchMatch(providerId, championshipId, query) {
    var lowercaseQuery = query && query.toLowerCase();
    if (!championshipId) return Promise.resolve([]);
    return this.
      Provider
      .query({
        path: "match",
        id: providerId,
        championship: championshipId
      })
      .$promise
      .then(matches => matches.filter(match => {
        if (this.filterMatch.dateFilter) {
          const lastC = this.media.data.clips[this.media.data.clips.length - 1];
          const start = new Date(lastC.start);
          const end = new Date(lastC.end);
          start.setHours(start.getHours() - 24);
          end.setHours(end.getHours() + 48);
          if (!(match.date > start && match.date < end)) return false;
        }
        let result = true;
        if (lowercaseQuery) {
          var words = lowercaseQuery.split(' ');
          var local = removeSpecialChars(match.localTeam.name);
          var visitor = removeSpecialChars(match.visitorTeam.name);
          for (var i = 0; i < words.length && result; i++) {
            var word = removeSpecialChars(words[i]);
            result = result && (
              local.toLowerCase().indexOf(word) >= 0 ||
              visitor.toLowerCase().indexOf(word) >= 0
            )
          }
        }
        return result;
      }));
  }
  stopPropagation(ev) {
    ev.stopPropagation();
  }
  clearSearchTerm() {
    this.programSelector.searchTerm = '';
  };
  onVideoTypeChange(videoType) {
    switch (videoType) {
      case 'PROGRAM':
      case 'PROGRAM_CLIP':
        this.job.data.championshipId = null;
        this.job.data.matchId = null
        this.job.data.match = null;
        break;
      default:
        this.job.data.matchId = this.job.data.match && this.job.data.match.id || null;
        this.job.data.programId = null;
        this.job.data.stripId = null;
        this.job.data.episodeId = null;
        break;
    }
  }
}
angular.module('fireclip').component('exporterFiresport', {
  template,
  controller: Controller,
  bindings: {
    job: '<',
    media: '<',
    exporter: '<'
  }
});

function removeSpecialChars(query) {
  return query.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
}

const firesport = {
  code: 'firesport',
  template,
  controller: Controller,
  image: require('./firesport.svg'),
  service: null,
}

export { firesport }