import moment from 'moment'
import { IScope } from "angular";

export function safeApply($scope: IScope, fn?: { (): void }) {
	var phase = $scope.$root.$$phase;
	if (phase == '$apply' || phase == '$digest') {
		if (fn) {
			$scope.$eval(fn);
		}
	} else {
		if (fn) {
			$scope.$apply(fn);
		} else {
			$scope.$apply();
		}
	}
}

export function timeString(date: number){
	return moment(date).format('hh:mm:ss.SS')
}

export function getTileImageAsCanvas(image: HTMLImageElement, hCount: number, vCount: number, tileNum: number) {
	const width = image.width / hCount;
	const height = image.height / vCount
	const canvas = document.createElement('canvas')
	const tileX = tileNum % hCount * width;
	const tileY = Math.floor(tileNum / hCount) * height;
	canvas.width = width;
	canvas.height = height;
	canvas.getContext('2d').drawImage(image, tileX, tileY, width, height, 0, 0, width, height);
	return canvas;
}

// stealed from https://stackoverflow.com/a/14919494
export function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + ' ' + units[u];
}
