export class RecordingSchedule {
	public type = "repeat"
	public duration = 43200
	public start = new Date("1970-01-01T03:00:00.000Z")
	public end = new Date("1970-01-02T03:00:00.000Z")

	get _start() {
		return RecordingSchedule.ISOasLocal(this.start);
	}
	set _start(value: string) {
		this.start = RecordingSchedule.LocalAsISO(value);
	}
	get _end() {
		return RecordingSchedule.ISOasLocal(this.end);
	}
	set _end(value: string) {
		this.end = RecordingSchedule.LocalAsISO(value);
	}

	constructor(data: any = null) {
		if (data !== null) {
			this.type = data.type || this.type;
			this.duration = data.duration || this.duration;
			if (typeof (data.start) === 'string') data.start = new Date(data.start)
			this.start = data.start || this.start;
			if (typeof (data.end) === 'string') data.end = new Date(data.end)
			this.end = data.end || this.end;
		}
	}

	public recordingHourRange(): string {
		// parse start and end
		if (this.end.getTime() - this.start.getTime() >= 24 * 60 * 60 * 1000) {
			return "Contínua"
		} else {
			const localHourStart = this.start.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
			const localHourEnd = this.end.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
			return `${localHourStart} - ${localHourEnd}`
		}
	}
	private static ISOasLocal(date: Date) {
		if (date) {
			return new Date(date.getTime() - (1000 * 60 * date.getTimezoneOffset())).toISOString().replace('.000Z', '')
		} else {
			return null
		}
	}
	private static LocalAsISO(date: string) {
		if (date) {
			return new Date(Date.parse(date))
		} else {
			return null
		}
	}
}

export interface RecordingAbrStreamInterface {
	name: string
	rate: string
	fps: number
	resolution: string
}

export class RecordingAbr {
	streams: RecordingAbrStreamInterface[] = [
		{
			name: "low",
			rate: "200k",
			fps: 12,
			resolution: "416x234"
		},
		{
			name: "medium",
			rate: "600k",
			fps: 24,
			resolution: "640x360"
		},
		{
			name: "high",
			rate: "4500k",
			fps: 24,
			resolution: "1280x720"
		}
	]
	enabled = false;
	constructor(data: any = {}) {
		this.enabled = typeof (data.enabled) === 'boolean' ? data.enabled : this.enabled;
		this.streams = data.streams || this.streams;
	}
}

export class RecordingOptions {
	abr: RecordingAbr
	rtmp_buffer = 3000
	master_container = 'matroska'
	clip_thumbnails = 1
	clip_scene_threshold = 0.1
	matroska_reserve_index_space = '2621440'
	thumbnailsV2 = {
		container: 'webp',
		horizontalCount: 9,
		verticalCount: 8,
		width: 160,
		height: 120
	}
	enable_publish = false
	publish = []

	constructor(data: any = {}) {
		this.abr = new RecordingAbr(data.abr);
		this.rtmp_buffer = data.rtmp_buffer || this.rtmp_buffer;
		this.master_container = data.master_container || this.master_container;
		this.clip_thumbnails = data.clip_thumbnails || this.clip_thumbnails;
		this.clip_scene_threshold = data.clip_scene_threshold || this.clip_scene_threshold;
		this.matroska_reserve_index_space = data.matroska_reserve_index_space || this.matroska_reserve_index_space;
		this.enable_publish = typeof (data.enable_publish) === 'boolean' ? data.enable_publish : this.enable_publish
		// here is gonna be a problem, it doesen't follow the other syntaxis for assigning objects..
		this.thumbnailsV2 = data.thumbnailsV2 || this.thumbnailsV2
		// here is gonna be a problem, it doesen't follow the other syntaxis for assigning objects..
		this.publish = data.publish || this.publish
	}
}

export class RecordingCustomParameters {
	ingestType = 'videoSD'

	constructor(data: any = null) {
		if (data !== null) {
			this.ingestType = data?.ingestType || this.ingestType
		}
	}
}

export class RecordingInput {
	type: 'stream' | 'upload' = 'stream'
	stream = ''
	tusEndpoint = ''
	tusPath = '/temp_uploads'
	cdnEndpoint = ''
	proberEnabled = false;

	constructor(data: any = null) {
		if (data !== null) {
			this.type = data.type || this.type;
			if (this.type === 'stream') {
				this.stream = data.stream || this.stream;
				this.proberEnabled = typeof (data.proberEnabled) === 'boolean' ? data.proberEnabled : this.proberEnabled
			} else {
				this.tusEndpoint = data.tusEndpoint || this.tusEndpoint;
				this.tusPath = data.tusPath || this.tusPath;
				this.cdnEndpoint = data.cdnEndpoint || this.cdnEndpoint;
			}
		}
	}
	toJSON() {
		if (this.type === 'stream') {
			return {
				type: this.type,
				stream: this.stream,
				proberEnabled: this.proberEnabled,
			}
		} else {
			return {
				type: this.type,
				tusEndpoint: this.tusEndpoint,
				tusPath: this.tusPath,
				cdnEndpoint: this.cdnEndpoint,
			}
		}
	}
}

export class Recording {
	id?: string = null
	enabled = false
	name = ''
	storageDuration: number = null
	type = 'normal'
	customParameters = new RecordingCustomParameters()
	input = new RecordingInput()
	options = new RecordingOptions()
	schedule: RecordingSchedule = null

	constructor(data: any = null) {
		if (data !== null) {
			this.id = data.id || this.id;
			this.enabled = typeof (data.enabled) === 'boolean' ? data.enabled : this.enabled;
			this.name = data.name || this.name;
			this.storageDuration = data.storageDuration || this.storageDuration;
			this.type = data.type || this.type;
			this.options = new RecordingOptions(data.options);
			this.customParameters = new RecordingCustomParameters(data.customParameters);
			this.input = new RecordingInput(data.input);
			if (this.input.type === 'stream')
				this.schedule = new RecordingSchedule(data.schedule);
		} else {
			if (this.input.type === 'stream') {
				this.schedule = new RecordingSchedule()
			}
		}
	}
	toJSON() {
		const resultJSON = {
			id: this.id,
			enabled: this.enabled,
			name: this.name,
			storageDuration: this.storageDuration,
			type: this.type,
			customParameters: null,
			input: this.input,
			options: this.options,
			schedule: undefined
		};
		if (this.input.type === "stream") {
			resultJSON.customParameters = this.customParameters
			resultJSON.schedule = this.schedule
		}
		return resultJSON;
	}
}

export const RecordingValueKeys = {
	RecordingTypes: [
		{ "name": "Stream", "value": "stream" },
		{ "name": "Upload", "value": "upload" },
	],
	ContentTypes: [
		{ "name": "Audio", "value": "audio" },
		{ "name": "Video", "value": "normal" },
	],
	SchedulingTypes: [
		{ "name": "Calendario", "value": "calendar" },
		{ "name": "Repeticion", "value": "repeat" },
	],
	MasterContainerTypes: [
		{ "name": "Matroska", "value": "matroska" },
		{ "name": "MPEG", "value": "mpeg" },
	],
	PublishFormats: [
		{ "name": "MPEG", "value": "mpeg" },
		{ "name": "FLV", "value": "flv" }
	],
	ThumbnailContainers: [
		{ "name": "WebP", "value": "webp" },
		{ "name": "JPEG", "value": "jpeg" },
	],
	IngestTypes: [
		{ "name": "Video SD", "value": "videoSD" },
		{ "name": "Video HD", "value": "videoHD" },
		{ "name": "Video FHD", "value": "videoFHD" },
		{ "name": "Audio", "value": "audio" },
	],
	Protocols: [
		{ "name": "RTMP", "value": "rtmp" },
		{ "name": "RTMPS", "value": "rtmps" },
		{ "name": "HLS", "value": "hls" },
		{ "name": "HTTP", "value": "http" },
		{ "name": "HTTPS", "value": "https" },
	],
	EnabledStatuses: [
		{ "name": "Activo", "value": true },
		{ "name": "Inactivo", "value": false },
	],
	RecordingStatus: [
		{ "name": "SENT", "value": "sent" },
		{ "name": "RUNNING", "value": "running" },
		{ "name": "RECORDING", "value": "recording" },
		{ "name": "TIMEOUT", "value": "timeout" },
		{ "name": "STOPPED", "value": "stopped" },
		{ "name": "OUT OF SCHEDULE", "value": "out_of_schedule" },
		{ "name": "UNKNOWN", "value": "unknown" },
	]
}
