import { EventEmitter } from "events";

const socketIOClient = require('socket.io-client');
const sailsIOClient = require('sails.io.js')
let io = sailsIOClient(socketIOClient);
io.sails.url = theConfig.api_url;
// io.sails.initialConnectionHeaders
io.sails.useCORSRouteToGetCookie = false;
io.sails.reconnection = true;
io.sails.autoConnect = false;
// io.sails.transports = ['websocket'];
io.sails.environment = 'development'

class SocketService {
	io = null;
	socket = null;
	subscritions = {
		recording: {},
		channel: {},
		recordingStatus: false
	};
	socketPromise: Promise<any>;
	emitter: EventEmitter;
	constructor() {
		this.io = io;
		this.emitter = new EventEmitter();
		this.socketPromise = new Promise((f) => {
			this.emitter.on('connect', () => {
				f(this.socket)
			})
		})
	}
	setAuth(bearerToken) {
		this.io.sails.headers = {
			"Authorization": `Bearer ${bearerToken}`
		}
	}
	refreshAuth(bearerToken) {
		this.socket.headers = {
			"Authorization": `Bearer ${bearerToken}`
		}
	}
	async connect() {
		// TODO: no clue why there is a time out! reducing to 0 (from 5000) bc why not :)
		return new Promise((resolve, _reject) => {
			setTimeout(() => {
				this.socket = io.sails.connect()
				this.socket.on('connect', () => {
					this.emitter.emit('connect');
					resolve(this.socket)
				})
				this.socket.on('reconnect', () => {
					Object.keys(this.subscritions.recording).forEach(sub => {
						this.subscribeToRecordingId(sub);
					})
					Object.keys(this.subscritions.channel).forEach(sub => {
						this.subscribeToMediasByChannelId(sub);
					})
					if (this.subscritions.recordingStatus) {
						this.subscribeToRecordingsStatus();
					}
				})
				this.socket.on('channel', (data) => {
					if (data.attribute === 'medias') {
						let action, media;
						if (data.verb === 'addedTo') {
							action = 'add'
							media = data.added;
						} else if (data.verb === 'updated') {
							action = 'update'
							media = data.updated
						} else {
							action = 'delete';
							media = { id: data.id }
						}
						this.emitter.emit('media', { action, media })
					} else if (data.attribute === 'job') {
						if (data.verb === 'updated') {
							this.emitter.emit('job', data.updated);
						} else if (data.verb === 'removedFrom') {
							this.emitter.emit('job-remove', data.deleted);
						}
					}
				});
				this.socket.on('recording', (data) => { 
					if(data.attribute === 'state' && data.verb === 'updated') {
						this.emitter.emit('recordingState', data.status);
					}else {
						this.emitter.emit('recording', data)
					}
				});
				this.socket.on('job', data => this.emitter.emit('job', data));
				// this.socket.on('media', data => this.emitter.emit('media', data));
				this.socket.on('recordingStatusUpdate', data => this.emitter.emit('recordingStatuses', data));
			}, 0)
		})
	}
	
	onRecordingStatuses(callback) {
		this.emitter.on('recordingStatuses', callback)
	}
	offRecordingStatuses(callback: any) {
		this.emitter.off('recordingStatuses', callback)
	}
	onRecording(callback) {
		this.emitter.on('recording', callback)
	}
	offRecording(callback: any) {
		this.emitter.off('recording', callback)
	}
	onRecordingState(callback) {
		this.emitter.on('recordingState', callback)
	}
	offRecordingState(callback: any) {
		this.emitter.off('recordingState', callback)
	}
	onJobChange(callback) {
		this.emitter.on('job', callback)
	}
	offJobChange(callback) {
		this.emitter.off('job', callback)
	}
	onJobRemove(callback) {
		this.emitter.on('job-remove', callback)
	}
	offJobRemove(callback) {
		this.emitter.off('job-remove', callback)
	}
	onMediaChange(callback) {
		this.emitter.on('media', callback)
	}
	offMediaChange(callback: any) {
		this.emitter.off('media', callback)
	}

	async unsubscribeToMediasByChannelId(channelId) {
		if (!channelId) return;
		const socket = await this.socketPromise
		this.subscritions.channel[channelId] = false;
		socket.get(`/channel/${channelId}/media/unsubscribe`);
	}
	async subscribeToMediasByChannelId(channelId) {
		const socket = await this.socketPromise
		this.subscritions.channel[channelId] = true;
		socket.get(`/channel/${channelId}/media/subscribe`);
	}
	async subscribeToRecordingId(recordingId) {
		const socket = await this.socketPromise
		this.subscritions.recording[recordingId] = true;
		socket.get(`/recording/${recordingId}/?populate=false`, () => { });
		this.emitter.emit('recordingState', {errorLog: [], recordingState: null, monitorState: null})
	}
	async unsubscribeToRecordingId(recordingId) {
		const socket = await this.socketPromise
		delete this.subscritions.recording[recordingId];
		socket.get(`/recording/${recordingId}/unsubscribe`, () => { })
	}
	async subscribeToRecordingsStatus() {
		const socket = await this.socketPromise
		this.subscritions.recordingStatus = true;
		socket.get(`/recording/status/subscribe`, () => { });
	}
	async unsubscribeToRecordingsStatus() {
		const socket = await this.socketPromise
		this.subscritions.recordingStatus = false;
		socket.get(`/recording/status/unsubscribe`, () => { })
	}

}
export default new SocketService();