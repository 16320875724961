import * as angular from 'angular';
import { copyToClipboard } from '../app/utils';
let template = require('./template.pug');

class copyController implements angular.IComponentController {
  text: string;
  constructor(private $mdToast) {
    "ngInject";
  }
  copy($event) {
    copyToClipboard(this.text)
      .then(() => {
        this.$mdToast.show(this.$mdToast.simple().textContent('Texto copiado').position('bottom right').hideDelay(2000).parent($event.target.parentElement.parentElement));
      })
      .catch(error => console.error(error))
  }
}

angular.module('fireclip').component('fcCopy', {
  template: template,
  controller: copyController,
  bindings: {
    text: '<'
  }
})