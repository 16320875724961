import '../workflow/clip/params.component'

interface StreamParams {
  name: string; 
  video: { bitrate: number; fps: number; width: number; height: number },
  // TODO: review if needed  https://en.wikipedia.org/wiki/Audio_bit_depth
  audio: { bitrate: number; frequency: number; channels: number}
}
interface ProfileTemplate {
  name: string; streams: StreamParams[] 
}
class WorkflowParamController {
  workflowCode
  resolutionSelector = false
  firesportAPI = false
  overlayImage = false
  exporter = {
    parameters: {}
  }
  providers = []
  providersByType = {}
  workflow: any
  params: any
  profileTemplates: ProfileTemplate[]
  selectedTemplate: ProfileTemplate
  hlsProfileSelector =  false;
  wordpress: boolean
  wordpressRequestMethod: any
  wordpressBodyFilter: any

  constructor(Provider) {
    'ngInject';

    this.providers = Provider.query();
    this.providers.$promise.then((providers) => {
      providers.forEach(p => {
        this.providersByType[p.code] = this.providersByType[p.code] || [];
        switch (p.code) {
          case 'google':
          case 'facebook':
          case 'twitter':
            p.name = p.data && (p.data.displayName || p.data.id) || 'ERROR: no data'
            break;
          case 'soundcloud':
            p.name = p.data.username;
            break;
          default:
            p.name = p.identifier;
        }
        this.providersByType[p.code].push(p);
      })
    })
  }
  changeStreamTemplate() {
    this.params.streams = this.selectedTemplate.streams
    this.selectedTemplate = null;
  }
  $onInit() {
    if (!this.workflow) return console.error('no workflow defined')
    if (this.workflow.code === 'c9') {
      this.workflow.providers = ['amazon'];
      this.exporter.parameters = this.exporter.parameters || { provider: { 'amazon': [] } };
    }
    if (!this.exporter.parameters) {
      this.exporter.parameters = {};
    }
    this.workflowCode = this.workflow.code;
    this.params = this.exporter.parameters;
    if (this.workflowCode == 'wordpress') {
      if (!this.params?.wordpressRequestMethod) this.params.wordpressRequestMethod = 'POST';
      if (!this.params?.wordpressBodyFilter) this.params.wordpressBodyFilter = `{\n`+
      `  type: "post",\n`+
      `  status: .parameters.wordpress.post.status,\n`+
      `  tags: .parameters.wordpress.post.tags,\n`+
      `  title: .parameters.wordpress.post.title,\n`+
      `  excerpt: .parameters.wordpress.post.description,\n`+
      `  format: "audio",\n`+
      `  acf: {\n`+
      `    url: .element\n`+
      `  }\n`+
      `}`;
      this.wordpress = true;
    }
    if (['hls', 'wordpress'].includes(this.workflowCode)) {
      this.hlsProfileSelector = true;
      this.profileTemplates = [{
        name: "HD", 
        streams: [
          {
            name: 'low',
            video: { bitrate: 200*1024, fps: 24, width: 320, height: 180 },
            audio: {bitrate: 96*1024, channels: 2, frequency: 44100}
          },
          { 
            name: 'medium', 
            video: { bitrate: 600*1024, fps: 30, width: 640, height: 360 },
            audio: {bitrate: 96*1024, channels: 2, frequency: 44100}
          },
          { name: 'high', 
            video: { bitrate: 1000*1024, fps: 30, width: 1280, height: 720 },
            audio: {bitrate: 96*1024, channels: 2, frequency: 44100}
          }
        ]
      }, {
        name: "SD", 
        streams: [
          {
            name: 'low',
            video: { bitrate: 200*1024, fps: 24, width: 320, height: 180 },
            audio: {bitrate: 96*1024, channels: 2, frequency: 44100}
          },
          { 
            name: 'medium', 
            video: { bitrate: 600*1024, fps: 30, width: 640, height: 360 },
            audio: {bitrate: 96*1024, channels: 2, frequency: 44100}
          },
        ]
      }]
      this.selectedTemplate = null;
    }
    if (this.workflowCode == 'firesport') {
      this.resolutionSelector = true;
      this.params.streams = this.params.streams || [
        { name: 'low', rate: '200k', fps: 24, resolution: '320x180' },
        { name: 'medium', rate: '600k', fps: 30, resolution: '640x360' },
        { name: 'high', rate: '1000k', fps: 30, resolution: '1280x720' }
      ]
    }
    if (this.workflow.providers.indexOf('firesportapi') !== -1) {
      this.params.appId = this.params.appId || [];
      if (typeof (this.params.appId) === 'string') {
        this.params.appId = this.params.appId.split(',');
      }
      this.firesportAPI = true;
    }
    if (!this.params.destinations) {
      this.params.destinations = [
        { path: '/', enabled: true },
        { path: '/', enabled: false }
      ]
    }
    if (this.workflowCode == 'facebook') {
      this.overlayImage = true;
    }
    if (this.workflowCode == 'clip') {
      if (!this.params?.clip_thumbnails) this.params.clip_thumbnails = 1;
      if (!this.params?.clip_scene_threshold) this.params.clip_scene_threshold = 0.1;
    }
  }
  toggle(items, id) {
    var index = items.indexOf(id);
    if (index == -1)
      items.push(id);
    else
      items.splice(index, 1);
  }
}
import fireclipApp from '../../app'
fireclipApp.component('workflowParams', {
  template: require('./workflowParams.pug'),
  bindings: {
    exporter: '=',
    workflow: '=',
    form: '<'
  },
  controller: WorkflowParamController
})

export { WorkflowParamController }