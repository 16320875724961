import app from '../../app';
import { IComponentController } from "angular";
import * as angular from 'angular';
import { ContentSelectorCtrl } from './content-selector/content-selector.controller';
import Auth0Service from '../auth/auth0.service';

const name = 'fcHeader';
class controller implements IComponentController {
	name: string;
	picture: string;
	alternativeEnvironment: { name: string; url: string; };
	environmentVersion: string;
	constructor(private $mdPanel, private Auth0Service: Auth0Service) {
		'ngInject';
		const profile = this.Auth0Service.getProfile();
		this.name = profile.name;
		this.picture = profile.picture;
		this.alternativeEnvironment = theConfig.environments[Object.keys(theConfig.environments).filter(k => k != environment)[0]]
		this.environmentVersion = `${environment}@${version}`
	}
	openContentSelector($event) {
		var position = this.$mdPanel.newPanelPosition()
			.relativeTo('.content-menu')
			.addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.BELOW);
		var config = {
			attachTo: angular.element(document.body),
			controller: ContentSelectorCtrl,
			controllerAs: '$ctrl',
			template: require('./content-selector/content-selector.panel.pug'),
			panelClass: 'demo-menu-example',
			position: position,
			locals: {
			},
			openFrom: $event,
			clickOutsideToClose: true,
			escapeToClose: true,
			focusOnOpen: false,
			zIndex: 2
		};

		this.$mdPanel.open(config);
	}
}
app.component(name, {
	bindings: {
		layout: '@',
		flex: '@'
	},
	controller: controller,
	require: {
		home: '^^fcHome'
	},
	template: require('./home.header.pug')
})

export { name as fcHeaderComponent };