import app from '../../../app';
import { EditorService } from '../editor.service';
import { Channel } from '../../channel/channelService';
import { LibraryService } from '../../library/library.service';
import Thumbnail from '../../../timeline/thumbnail';
const name = 'editorVod'

export class EditorVodController implements IComponentControllerUIRouter {
	channel: Channel;
	recording: FireClip.Recording;
	library: FireClip.Library;
	jobChange: any;
	filter: { owner?: string, types?: string[] } = {};
	showOwnedByMe = true;
	showClips = true;
	showImages = true;
	showGifs = true;
	editorService: EditorService;
	constructor(private LibraryService: LibraryService) {
		'ngInject';
		this.setupMediaFilter()
		this.editorService = new EditorService('relative');
	}
	$onInit() {
		if (this.library) {
			this.editorService.appendRelativeLibrary(
				this.library.id,
				this.library.duration * 1000,
				this.LibraryService.getVodURL(this.library, this.recording),
				(cache: Thumbnail) => {
					if (!cache) cache = new Thumbnail()
					return (time: number) => {
						return this.LibraryService.getImage(this.library, time, cache)
					}
				},
				this.library.title
			)
			this.editorService.addClip(0, this.library.duration * 1000, true)
		}
	}
	uiOnParamsChanged(_newParams) {
		// if (newParams.library) {
		// 	debugger
		// }
	}
	setupMediaFilter() {
		const newFilter = {
			types: [],
			owner: null,
		}
		newFilter.types.push('clip')
		if (!this.showOwnedByMe) newFilter.owner = '*';
		// assign new object, so below component gets notified in $onChanges event handler, read: https://docs.angularjs.org/guide/component#component-based-application-architecture
		this.filter = newFilter;
	}
}
app.component(name, {
	bindings: {
		library: '<',
		channel: '<',
		layout: '@',
		flex: '@'
	},
	controller: EditorVodController,
	template: require('./editor-vod.pug')
})

export { name as EditorVodComponentName };