import angular, { module } from 'angular';

const fireclipApp = module('fireclip');

class simpleFormController implements angular.IComponentController {
	schema: any;
	provider: any;
	$onInit() {
		// will asumme the schema is allways type object.. 
		this.process(this.schema.properties, this.provider)
	}
	process(schemaProperties, data) {
		let propertySchema, propertyName: any;
		for ([propertyName, propertySchema] of Object.entries(schemaProperties)) {
			if (typeof (propertySchema.default) !== 'undefined') {
				if (data) {
					switch (propertySchema.type) {
						case 'boolean':
							if (typeof (data[propertyName]) !== propertySchema.type) {
								data[propertyName] = propertySchema.default;
							}
							break;
						default:
							//lazy comparison... 
							if (typeof (data[propertyName]) === 'undefined' || data[propertyName] === null) {
								data[propertyName] = propertySchema.default;
							}
					}
				}
			}
			if (propertySchema.properties) {
				this.process(propertySchema.properties, data[propertyName]);
			}
		}
	}
}
fireclipApp.component('fcSimpleForm', {
	controller: simpleFormController,
	template: require('./simpleform.pug'),
	bindings: { provider: '=', schema: '=' }
});
