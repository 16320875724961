import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, Injectable, OnInit, ViewChild } from "@angular/core";
// import 'zone';
import 'reflect-metadata';
import { LOCALE_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { RangeValidatorDirective } from "./common/range-validator.directive";
import { NgForm } from "@angular/forms";
import { RecordingValueKeys } from "./recording.model";
import RecordingService from "./recording.service";
import { MatSnackBar } from "@angular/material/snack-bar";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const TEMPLATE_CONTENT: string = require("./recording-edit-dialog.template.pug");

@Injectable()
@Component({
	selector: "recording-edit-dialog-component",
	template: TEMPLATE_CONTENT,
	styles: []
}) 
export class RecordingEditDialogComponent implements OnInit, AfterViewInit, AfterContentChecked {
	@ViewChild('mainForm') mainForm: NgForm;

	displayJson = false;
	resolutionRegex = /^\d+(x|X)\d+$/
	bitrateRegex = /^\d+(m|k|g)?$/i
	public gdAreas = "";
	public gdColumns = "";
	types = RecordingValueKeys;

	constructor(
		@Inject('RecordingService') private RecordingService: RecordingService,
		@Inject(LOCALE_ID) public locale: string,
		@Inject(MAT_DIALOG_DATA) public data,
		private snack: MatSnackBar,
		private cdref: ChangeDetectorRef,
		private dialogRef: MatDialogRef<RecordingEditDialogComponent>
	) {
		this.onResize({ target: { innerWidth: window.innerWidth } })
	}

	toggleJsonView() {
		this.displayJson = !this.displayJson
	}

	addPublish() {
		if (!this.data.recording.options.publish) this.data.recording.options.publish = []
		this.data.recording.options.publish.push({
			url: "",
			format: this.types.PublishFormats[0].value
		})
	}

	removePublish(index) {
		this.data.recording.options.publish.splice(index, 1)
	}

	addAbr() {
		if (!this.data.recording.options.abr) this.data.recording.options.abr = { streams: [] }
		this.data.recording.options.abr.streams.push({
			name: "",
			rate: "",
			fps: null,
			resolution: ""
		})
	}

	removeAbr(index) {
		this.data.recording.options.abr.streams.splice(index, 1)
	}

	getFormCompomentName(formName = "", index = 0) {
		return `${formName}-${index}`
	}

	async saveRecording() {
		try {
			let result = null;
			if (this.data.recording.id) {
				delete this.data.recording.updatedAt;
				delete this.data.recording.createdAt;
				result = await this.RecordingService.update(this.data.recording, this.data.recording.id);
			} else {
				delete this.data.recording.id;
				result = await this.RecordingService.save(this.data.recording);
			}
			if (result) {
				this.dialogRef.close(result);
			}
		} catch (error) {
			this.snack.open(`No es posible actualizar o crear la ingesta: ${error.message}.`, 'Cerrar', { duration: 2000 });
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(_event) {
		const isStream = () => (this.data.recording?.input?.type === 'stream');
		if (document.body.clientWidth < 920) {
			this.gdAreas = `header | source ${isStream() ? '| schedule' : ''} | output | abr | thumbnails | customparams | footer`;
			this.gdColumns = '100%';
		} else {
			this.gdAreas = `header header | ${isStream() ? 'source schedule' : 'source source'} | output abr | thumbnails thumbnails | customparams customparams | footer footer`;
			this.gdColumns = '50% 50%';
		}
	}

	copyJson() {
		const _tempElement = document.createElement('textarea');
		_tempElement.style.position = 'fixed';
		_tempElement.style.left = '0';
		_tempElement.style.top = '0';
		_tempElement.style.opacity = '0';
		_tempElement.value = JSON.stringify(this.data.recording, null, 2);
		document.body.appendChild(_tempElement);
		_tempElement.focus();
		_tempElement.select();
		document.execCommand('copy');
		document.body.removeChild(_tempElement);
		this.snack.open(`Definición JSON para recording ${this.data.recording.name} copiada al portapapeles.`, "OK", { duration: 2000 });
	}

	ngOnInit(): void {
		this.onResize({ target: { innerWidth: window.innerWidth } })
	}

	ngAfterContentChecked(): void {
		this.cdref.detectChanges();
		for (const form in this.mainForm?.controls || []) {
			const control = this.mainForm.controls[form]
			control.updateValueAndValidity()
			control.markAllAsTouched()
		}
	}

	ngAfterViewInit(): void {
		//
	}
}