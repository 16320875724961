function ShowCustomToast(mdToast: any, type: 'success'|'error', message: string, hideDelay: number = 0, position: string = 'top') {
	mdToast.show({
		hideDelay: hideDelay,
		position: position,
		controller: toastController,
		controllerAs: 'ctrl',
		bindToController: true,
		locals: {toastMessage: message, isError: (type === 'error' ? true : false)},
		template: require('./customToast.pug')
	})
}

function toastController($mdToast) {
	'ngInject';
	var ctrl = this;
	ctrl.closeToast = () => $mdToast.hide('undo')
}

export default ShowCustomToast;