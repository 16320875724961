/* eslint-disable class-methods-use-this */
import * as angular from 'angular';
import ClipService from '../clip/clipService';
import MediaService from './media.service';
import { LibraryService } from '../library/library.service';
import { EditorService, EditorClip, EditorLib } from '../editor/editor.service';
import Thumbnail from '../../timeline/thumbnail';
import { Channel } from '../channel/channelService';
import { LibraryDialogController } from './bumper.controller';
import { clone } from 'lodash';
require('./media.edit.styl')

class MediaEditController implements angular.IComponentController {
	editorService: EditorService
	onMediaSave: () => void;
	saving: boolean;
	errorToken: boolean;
	media: FireClip.Media = {
		title: null,
		description: null,
		tags: [],
		channel: null,
		recording: null,
	};
	channel: Channel
	recording: FireClip.Recording
	initialExporters: FireClip.Exporter[];
	defaultExporter: FireClip.Exporter;
	moreExporter: boolean = false;
	library: FireClip.Library;
	editHandler: any;
	clipErrorMessage: string;
	// @ts-ignore
	constructor(private ClipService: ClipService, private $mdToast, private $mdDialog: angular.material.IDialogService, private MediaService: MediaService, private LibraryService: LibraryService) {
		'ngInject';
		this.saving = false;
		this.editHandler = this.edit.bind(this);
		this.MediaService.onEdit(this.editHandler)
	}
	$onInit() {
		if (!this.editorService) {
			alert('Internal exception, code 1432');
			console.error(`missing editorService input in mediaEditComponent`)
		}
		this.initialExporters = this.channel.exporters.filter(e => e.workflowCode == 'clip');
		if (this.initialExporters.length >= 0) {
			this.defaultExporter = this.initialExporters[0];
			if (this.initialExporters.length > 1) {
				this.moreExporter = true;
			}
		}
	}
	$onDestroy() {
		this.MediaService.offEdit(this.editHandler);
	}
	$onChanges(changes) {
		if (changes.media && !changes.media.isFirstChange()) {
			this.createMedia(changes.media);
		}
	}
	addLibrary(targetEvent) {
		const inputs = this.channel.inputs.filter(i => !i.isBumperInput && i.recording.input.type === 'upload')
		if (inputs.length === 0) { alert("No existen bibliotecas de subidas de archivos disponibles para este canal"); return;}
		this.$mdDialog
			.show({
				controller: LibraryDialogController,
				targetEvent,
				locals: { inputs },
				clickOutsideToClose: true,
				controllerAs: '$ctrl',
				template: require('./bumper.dialog.pug'),
				parent: angular.element(document.body)
			})
			.then((library: FireClip.Library) => {
				this.editorService.appendRelativeLibrary(
					library.id,
					library.duration * 1000,
					this.LibraryService.getVodURL(library, this.recording),
					(cache: Thumbnail) => {
						if (!cache) cache = new Thumbnail()
						return (time: number) => {
							return this.LibraryService.getImage(library, time, cache)
						}
					},
					library.title
				)
			})
	}

	// @ts-ignore
	addBumper(relativePosition: 0 | -1, targetEvent) {
		const inputs = this.channel.inputs.filter(i => i.isBumperInput)
		if (inputs.length === 0) { alert("No existen bibliotecas de cortinas disponibles para este canal") ; return;}
		this.$mdDialog
			.show({
				controller: LibraryDialogController,
				targetEvent,
				locals: { inputs },
				clickOutsideToClose: true,
				controllerAs: '$ctrl',
				template: require('./bumper.dialog.pug'),
				parent: angular.element(document.body)
			})
			.then((library: FireClip.Library) => {
				this.editorService.addBumber(library.id, library.duration * 1000, library.title, relativePosition)
			})
	}
	async createMedia(media: FireClip.Media) {
		this.editorService.clearClips();
		if (media?.data) {
			const mediaClips = media.data.clips.filter(c => c.type !== 'bumper' && c.relativePosition !== 0 && c.relativePosition !== -1)

			// legacy, no timeline config in media, autodetect
			if (!media.data.timeLineConfig) {
				let timeLineConfig: FireClip.TimeLineConfig = {
					type: 'absolute',
					master: clone(this.editorService.timeLineConfig.master),
					detail: clone(this.editorService.timeLineConfig.detail),
					libraries: []
				};
				// best guess timeline config 
				if (this.media.type === 'upload') {
					timeLineConfig.type = 'relative'
					timeLineConfig.master.start = 0;
					// set to null so it can be calculated
					timeLineConfig.master.end = null
					// push libraries without repeating
					mediaClips
						.forEach(c => {
							if (timeLineConfig.libraries.findIndex(l => l.id === c.libraryId) === -1)
								timeLineConfig.libraries.push({ id: c.libraryId })
						})
				}
				media.data.timeLineConfig = timeLineConfig;
			} else {
				if (!media.data.timeLineConfig.libraries) media.data.timeLineConfig.libraries = []
			}
			if (this.editorService.timeLineConfig.type !== media.data.timeLineConfig.type) {
				// TODO: maybe alert, but give option to move to the other editor?
				alert(`media creada en otro editor ${media.data.timeLineConfig.type} pero usada en editor ${this.editorService.timeLineConfig.type}`)
				throw `media created on editor ${media.data.timeLineConfig.type} but used on ${this.editorService.timeLineConfig.type}`
			}
			// TODO: timelineconfig should be a class and transform data. what if timelineC.. is null ? or malformed?
			this.editorService.timeLineConfig.detail = media.data.timeLineConfig.detail;
			this.editorService.timeLineConfig.master = media.data.timeLineConfig.master;
			let librariesIds = [];
			if (media.data.timeLineConfig.type === 'relative') {
				this.editorService.clearLibraries();
				librariesIds = media.data.timeLineConfig.libraries.map(l => l.id)
			} else {
				mediaClips
					.forEach(c => {
						if (librariesIds.indexOf(c.libraryId) === -1) librariesIds.push(c.libraryId)
					})
			}
			const dbLibs = await this.LibraryService.findByIds(librariesIds);
			if (librariesIds.length !== dbLibs.length) {
				alert('Bibliotecas no encontradas')
				return
			}
			if (this.editorService.timeLineConfig.type === 'absolute') {
				dbLibs.forEach(library => this.editorService.addLibrary(
					library.id,
					library.start.getTime(),
					library.duration * 1000,
					this.LibraryService.getVodURL(library, this.recording),
					(cache: Thumbnail) => {
						if (!cache) cache = new Thumbnail()
						return (time: number) => this.LibraryService.getImage(library, time, cache)
					}
				))
			} else {
				/**
				 * TODO: por el momento esto sirve, pero que pasa si las bibliotecas tienen start/end distintos? 
				 * ahora simplemente se agregan en orden, pero a futuro podría tener: 
				 * TLConfig.libraries = { id: '', start: 0 }
				 * 
				 * - seguir probando interoperabildiad entre absoluto y relativo
				 * - copia de la copia
				 * - copia sin refrescar navegador (ver si la data que se guarda en memoria es buena)
				 */
				// const orderedLibraries = this.editorService.timeLineConfig.libraries.map(libId => libs.find(lib => lib.id === libId))
				media.data.timeLineConfig.libraries.forEach(lib => {
					const startPosition = lib.start;
					const dbLibrary = dbLibs.find(f => f.id === lib.id);
					console.log(`adding ${dbLibrary.title} from ${startPosition}`)
					this.editorService.addLibrary(
						dbLibrary.id,
						startPosition, 
						dbLibrary.duration * 1000,
						this.LibraryService.getVodURL(dbLibrary, this.recording),
						(cache: Thumbnail) => {
							if (!cache) cache = new Thumbnail()
							return (time: number) => {
								return this.LibraryService.getImage(dbLibrary, time, cache)
							}
						},
						dbLibrary.title,
						false
					)
				})
			}
			if (this.editorService.timeLineConfig.type === 'absolute') {
				// what?: removes duplicates, google it :P
				const recordings = [...new Set(dbLibs.map(l => l.recordingId))];
				if (recordings.length === 1) {
					if (this.recording.id !== recordings[0]) {
						alert('El clip pertenece a otra entrada, se copiaran los clips al actual.')
					}
				} else {
					alert('No es posible copiar, clip tiene multiples entradas asociadas.')
				}
			}
			media.data.clips
				// get bumpers
				.filter(c => c.type === 'bumper' || c.relativePosition === 0 || c.relativePosition === -1)
				.forEach(async c => {
					const newClip = new EditorClip(c.start, c.duration * 1000, false);
					const lib = await this.LibraryService.findById(c.libraryId)
					newClip.startLib = newClip.endLib = new EditorLib(lib.id, 0, lib.duration * 1000, null, null)
					newClip.title = c.title;
					newClip.type = 'bumper'
					newClip.relativePosition = c.relativePosition;
					if (c.relativePosition === 0) {
						this.editorService.bumperIn = newClip;
					} else {
						this.editorService.bumperOut = newClip;
					}
				})
			mediaClips.forEach(c => this.editorService.addClip(c.start, c.duration * 1000, false))
		}
	}
	async edit(media: FireClip.Media, attribute: string) {
		if (attribute) {
			if (!this.media) this.media = { title: null, recording: null, channel: null };
			this.media[attribute] = media[attribute];
		} else {
			try {
				await this.createMedia(media);
				this.media = media;
			} catch(error) {
				console.error(error)
			}
		}
	}

	getDuration(clip) {
		if (!clip) return 0;
		return (clip.end.getTime() - clip.start.getTime()) / 1000;
	}

	reset(form = null, defaults = null) {
		this.media = {
			title: null,
			channel: null,
			recording: null,
			tags: [],
		};
		if (defaults) {
			this.media.title = defaults.title;
			this.media.description = defaults.description;
			this.media.tags = defaults.tags;
		}
		if (form) {
			form.$setPristine();
			form.$setUntouched();
			delete form.$error.clips;
		}
		this.editorService.clearClips();
	}

	public async saveMedia(form, media: FireClip.Media, _mode, process = false, exporter: FireClip.Exporter, event) {
		console.error('aca tengo que reiniciar editorService!')
		if (this.saving) return alert('Guardando documento, por favor espere. Error [saveMedia]');
		if (!form.$valid) {
			alert('hay campos inválidos');
			return;
		}
		if (!media) {
			alert('nada que guardar');
			return;
		}
		if (!exporter) {
			alert('No hay exportador configurado para este Canal')
			return;
		}
		this.clipErrorMessage = null;
		if (!media.data) {
			media.data = {};
		}
		console.warn('aca tengo que sacar los clips en formato fireclip clip')
		if (this.editorService.clips.length === 0) {
			this.clipErrorMessage = 'Debes tener a lo menos un clip';
		}
		if (!this.editorService.clips.every(c => c.duration > 0)) {
			this.clipErrorMessage = 'se han detectado clips con duración 0, eliminalos antes de intentar procesar';
		}
		if (this.editorService.clips.some(clip => clip.errored)) {
			this.clipErrorMessage = 'se han detectado clips con errores, por favor cambialo o borralo en la pestaña de clips';
		}
		if (this.clipErrorMessage) {
			this
				.$mdDialog
				.show(this
					.$mdDialog
					.alert()
					.parent(angular.element(document.body))
					.title('Error')
					.textContent(this.clipErrorMessage)
					.ok('Aceptar')
					.targetEvent(event));
			return;
		}
		media.data.timeLineConfig = this.editorService.getTimeLineConfigJSON()
		//TODO: there is a timeliconfig to json.. why not a clip to json? or a from json in that case?
		media.data.clips = this.editorService.clips.map(clip => {
			return {
				id: clip.id,
				start: clip.start, // absolute
				end: clip.end, // absolute
				duration: clip.duration / 1000,
				type: 'clip',
				library: clip.startLib.refId, // will deprecate
				libraryId: clip.startLib.refId, // new value
				from: (clip.start - clip.startLib.start) / 1000,
				title: clip.title,
				relativePosition: clip.relativePosition,
			}
		})
		if (this.editorService.bumperOut) {
			media.data.clips.push({
				id: this.editorService.bumperOut.id,
				start: this.editorService.bumperOut.start, // absolute
				end: this.editorService.bumperOut.end, // absolute
				duration: this.editorService.bumperOut.duration / 1000,
				type: 'bumper',
				library: this.editorService.bumperOut.startLib.refId, // will deprecate
				libraryId: this.editorService.bumperOut.startLib.refId, // new value
				from: (this.editorService.bumperOut.start - this.editorService.bumperOut.startLib.start) / 1000,
				title: this.editorService.bumperOut.title,
				relativePosition: this.editorService.bumperOut.relativePosition,
			})
		}
		if (this.editorService.bumperIn) {
			media.data.clips.unshift({
				id: this.editorService.bumperIn.id,
				start: this.editorService.bumperIn.start, // absolute
				end: this.editorService.bumperIn.end, // absolute
				duration: this.editorService.bumperIn.duration / 1000,
				type: 'bumper',
				library: this.editorService.bumperIn.startLib.refId, // will deprecate
				libraryId: this.editorService.bumperIn.startLib.refId, // new value
				from: (this.editorService.bumperIn.start - this.editorService.bumperIn.startLib.start) / 1000,
				title: this.editorService.bumperIn.title,
				relativePosition: this.editorService.bumperIn.relativePosition,
			})
		}
		this.saving = true;
		media.export = {
			exporterId: exporter.id,
		};
		if (!media.id) {
			media.channel = this.channel.id;
			if (this.recording) // recording is not in VoD editor
				media.recording = this.recording.id;
		}
		this.MediaService
			.upsert(media, process)
			.then((media) => {
				this.MediaService.add(media);
				this.reset(form, media);
				console.error('aca borro todos los clips')
				this.editorService.clearClips();
				this.onMediaSave();
				this.saving = false;
				this.errorToken = null;
			})
			.catch((error) => {
				if (error.data && error.data.code === 'invalid_token' && error.data.name === "UnauthorizedError") {
					this.errorToken = true;
				}
				const toast = this.$mdToast.simple()
					.content('Ocurrió un error al guardar, re intenta en un momento')
					.action('OK')
					.highlightAction(true)
					.position('bottom right');
				this.$mdToast.show(toast);
				this.saving = false;
				console.error(error);
			});
	}
}

const mediaEditComponent = {
	'fcEditMedia': {
		controller: MediaEditController,
		bindings: {
			channel: '<',
			recording: '<',
			library: '<',
			onMediaSave: '&',
			media: '<',
			editorService: '<',
		},
		template: require('./media.edit.pug'),
	}
}

export default mediaEditComponent;