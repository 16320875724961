export declare interface User {
	password?: string
	user_id: string
	email: string
	name: string
	disabled: boolean
	channels: string[],
	profiles: string[],
	identities: {
		user_id: string
		provider: string // auth0
		connection: string
		isSocial: boolean
	}[]
	initialized: boolean
}
export function UserResource($resource, API_URL) {
	'ngInject';

	return $resource(`${API_URL}user/:id`, { id: '@id' }, {
		query: {
			method: 'GET',
			isArray: false
		},
		updateMe: {
			url: `${API_URL}user/me`,
			method: 'PUT',
		},
		create: {
			method: 'POST',
		},
		delete: {
			method: 'DELETE',
		},
		migrate: {
			url: `${API_URL}user/migrate`,
			method: 'POST',
		},
	});
}