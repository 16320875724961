import { v4 } from "uuid";
import { EventEmitter } from "events";
import { LibraryService } from "../library/library.service";
import { resolveThumbnailTemplate } from "../utils"
const path = require('path')

function getThumbnailData(secsFromStart = null, library: FireClip.Library) {
	if (secsFromStart === null || !library?.thumbnailsV2) return { src: null, tileNum: null, vCount: null, hCount: null }
	secsFromStart = Math.floor(secsFromStart)
	const verticalCount = library.thumbnailsV2.verticalCount || library.thumbnailsV2.tiles_vert_count;
	const horizontalCount = library.thumbnailsV2.horizontalCount || library.thumbnailsV2.tiles_horiz_count;
	if (!library.thumbnailsV2) return null;
	const spriteNum = Math.floor(secsFromStart / (verticalCount * horizontalCount) + 1);
	let fileName = resolveThumbnailTemplate(library.thumbnailsV2.fileTemplate, spriteNum);
	let newUrl = theConfig.library_storage_url + path.join('/', library.path, library.thumbnailsV2.path, fileName);
	return {
		src: newUrl,
		tileNum: secsFromStart % (verticalCount * horizontalCount),
		vCount: verticalCount,
		hCount: horizontalCount,
	}
}
const minimunClipLength = 30000;
class ClipService extends EventEmitter {
	private clips: FireClip.Clip[];

	on(event: string | symbol, listener: (...args: any[]) => void): this {
		return super.on(event, listener);
	}

	off(event: string | symbol, listener: (...args: any[]) => void): this {
		return super.off(event, listener);
	}
	constructor(private LibraryService: LibraryService) {
		'ngInject';

		super()
		this.clips = [];
	}

	private calculatedParams(clip: FireClip.Clip, defaultRecording: FireClip.Recording = null) {
		// TODO: clip depends on position of libraries in timeline
		try {
			if (clip.recording.input.type === 'upload') { // its a library that has being uploaded
				clip.start = null;
				clip.end = null;
				if (clip.library) {
					if (!clip.startLibrary && !clip.endLibrary)
						clip.endLibrary = clip.startLibrary = this.LibraryService.getLibraryById(clip.library);
					clip.endThumbnail = getThumbnailData(clip.duration, clip.endLibrary)
					clip.startThumbnail = getThumbnailData(clip.from, clip.startLibrary)
				}
			} else { // this library y absolute in time, it has a start and end
				if (clip.start && clip.end) {
					// wait for libraries to load, 
					if (defaultRecording) clip.recording = defaultRecording;
					if (clip.startLibrary?.recording.id !== clip.recording.id) {
						clip.library = null;
						clip.startLibrary = this.LibraryService.getLibraryForTime(clip.start.getTime())
					}
					if (clip.endLibrary?.recording.id !== clip.recording.id)
						clip.endLibrary = this.LibraryService.getLibraryForTime(clip.end.getTime());
				}
				// its a recording library
				// clip.intersectsLibraries = this.LibraryService.libraries.some(lib => this.caseAorCorD(lib, clip));
				if (clip.startLibrary) {
					clip.from = (clip.start.getTime() - clip.startLibrary.start.getTime()) / 1000;
				} else {
					clip.from = null;
				}
				clip.duration = (clip.end.getTime() - clip.start.getTime()) / 1000;
				clip.startThumbnail = getThumbnailData(clip.from, clip.startLibrary)
				if (clip.endLibrary)
					clip.endThumbnail = getThumbnailData(clip.duration, clip.endLibrary)
			}
		} catch (error) { console.error(error) }
	}

	create(recording: FireClip.Recording, start: Date = null, end: Date = null, type: 'clip' | 'fragment' = 'clip', library: FireClip.Library = null, from = null, duration = null, title?: string, relativePosition = null): FireClip.Clip {
		const clip: FireClip.Clip = {
			id: v4(),
			recording,
			library: library && library.id,
			start: null, // start || new Date(start),
			end: null, // end || new Date(start.getTime() + minimunClipLength),
			type,
			from,
			duration,
			description: undefined,
			title: title,
			relativePosition,
			selected: false
		};
		if (start) {
			clip.start = new Date(start.getTime());
		}
		if (end) {
			clip.end = new Date(end.getTime());
		}
		if (!clip.start && clip.end) {
			clip.start = new Date(clip.end.getTime() - minimunClipLength);
		}
		if (!clip.end && clip.start) {
			clip.end = new Date(clip.start.getTime() + minimunClipLength);
		}
		if (library) {
			clip.startLibrary = library;
			clip.endLibrary = library;
		}
		if ((!clip.end || !clip.start) && (clip.library === null || clip.from === null || clip.duration === null)) {
			throw "start and/or end values not provided";
		}
		this.calculatedParams(clip, recording);
		this.clips.push(clip);
		this.emit('clipAdd', clip);
		this.sort()
		return clip;
	}


	async generateClipsFromMedia(clips: FireClip.Clip[] = [], _fragments: FireClip.Clip[] = [], defaultRecording: FireClip.Recording) {
		if (clips.length > 0) {
			await Promise.all(clips.map(async (clip) => {
				// find recordings and libs...what a mess
				const lib = await this.LibraryService.findById(clip.library)
				if (lib.recording.input.type === 'upload')
					this.create(lib.recording, new Date(clip.start), new Date(clip.end), 'clip', lib, clip.from, clip.duration, clip.title, clip.relativePosition)
				else
					this.create(defaultRecording, new Date(clip.start), new Date(clip.end), 'clip', lib, clip.from, clip.duration, clip.title, clip.relativePosition)
			}));
			// const last = this.clips[clips.length - 1];
			// this.select(last);
		}
	}

	filterClips(type?: string) {
		if (!type) return this.clips;
		return this.clips.filter(c => c.type === type)
	}

	sort() {
		this.clips.sort((a, b) => {
			if (a.relativePosition === -1 || b.relativePosition === 0) return 1;
			if (a.relativePosition === 0 || b.relativePosition === -1) return -1;
			return a.start.getTime() - b.start.getTime()
		});
	}

	/**
	 * used to get processed clips for storage
	 */
	public getProcessedClips(type = 'clip'): FireClip.Clip[] {
		this.sort();
		const filtered = this.clips.filter(c => c.type === type)
		return filtered.map(clip => {
			return {
				id: clip.id,
				library: clip.library || clip.startLibrary && clip.startLibrary.id,
				start: clip.start,
				end: clip.end,
				from: clip.from,
				duration: clip.duration,
				title: clip.title,
				description: clip.description,
				relativePosition: clip.relativePosition,
			}
		})
	}

	/**
	 * used to get processed fragments for storage
	 */
	public getProcessedFragments() {
		const clips = this.getProcessedClips();
		const fragments = this.getProcessedClips('fragment');
		const processed = [];
		let totalTime = 0;
		console.warn(' review this for library relative')
		clips.forEach((clip) => {
			fragments.forEach((fragment) => {
				// fragment is inside clip
				if (fragment.start > clip.start && fragment.start < clip.end && fragment.end > clip.start && fragment.end < clip.end) {
					processed.push({
						start: fragment.start,
						end: fragment.end,
						from: totalTime + fragment.start.getTime() - clip.start.getTime(),
						to: totalTime + fragment.end.getTime() - clip.start.getTime(),
						title: fragment.title,
						description: fragment.description,
					});
				}
			});
			totalTime += clip.duration;
		});
		return processed;
	}

	/**
	 * 
	 * @param clips array ordered or not, with or w/o intersected clips
	 * @param movementStepSize (millisecods) defaults to 6 hours 
	 * @param timelineSize (millisecods) defaults to 24 hours
	 */
	calcualteTimelineStartEnd(clips: FireClip.Clip[]) {
		if (clips.length > 0) {
			let min = clips[0].start.getTime();
			let max = clips[0].end.getTime();
			clips.forEach(clip => {
				if (clip.end.getTime() > max) max = clip.end.getTime();
				if (clip.start.getTime() < min) min = clip.start.getTime();
			})
			return new Date((min + max) / 2);
		}
		return null
	}
}

export default ClipService
