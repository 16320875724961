import angular from "angular";
import Auth0Service from "../auth/auth0.service";
import socketService from "../socketService";

class adminController implements angular.IComponentController {
	constructor(private Auth0Service: Auth0Service) {
		'ngInject';
	}
	$onInit(){
		socketService.setAuth(this.Auth0Service.getAccessToken());
		socketService.connect().then(() => {
			this.Auth0Service.on('newAccessToken', (token) => {
				socketService.refreshAuth(token);
			})
		})
	}
}

const adminComponent: IComponent = {
	'fcAdmin': {
		bindings: {
			layout: '@',
			flex: '@',
			'layout-fill': '@'
		},
		controller: adminController,
		template: require('./admin.pug')
	}
}

export { adminComponent }
