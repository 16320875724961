// TODO: refactor this, separate files
require('./style.styl')
import * as angular from 'angular';

import { LibraryThumbnailComponent } from '../libraryThumbnail/libraryThumnail.component'
angular.module('fireclip').component(LibraryThumbnailComponent);

import mediaEditComponent from './../media/media.edit.component';
angular.module('fireclip').component(mediaEditComponent);

import videoComponent from './video.component'
angular.module('fireclip').component(videoComponent);

import liveComponent from './live.component';
angular.module('fireclip').component(liveComponent);