import Auth0Service from "../auth/auth0.service";

export class AdminNavController implements angular.IController {
	constructor(private Auth0Service: Auth0Service) {
		'ngInject';
	}

	pageVisibility(page: string): boolean {
		return this.Auth0Service.getAdminMenuClaim().includes(page)
	}
}
