import './fcProviderSelector/fcProviderSelector'
import { firesport } from './firesport';
import c9 from './c9';
import clip from './clip';
import facebook from './facebook';
import gif from './gif';
import hls from './hls';
import image from './image';
import ned from './ned';
import processLibrary from './process-library';
import radioCdr from './radio-cdr';
import s3 from './s3';
import twitter from './twitter';
import wordpress from './wordpress';
import youtube from './youtube';

require('./workflowParams');

const exporters = [
	c9,
	clip,
	facebook,
	firesport,
	gif,
	hls,
	image,
	ned,
	processLibrary,
	radioCdr,
	s3,
	twitter,
	wordpress,
	youtube,
]
export default exporters