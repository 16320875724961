import * as angular from 'angular';
import WorkflowInterface from '../workflowInterface';
const template = require('./index.pug');
const image = require('./facebook.svg');

class Controller extends WorkflowInterface {
  Provider: angular.resource.IResourceClass<any>;
  job: any;
  result: { url: any; embed: string; };
  media: FireClip.Media;
  pages: any;
  exporter: FireClip.Exporter;
  constructor($resource: angular.resource.IResourceService, API_URL) {
    'ngInject';
    super();
    this.Provider = $resource(API_URL + 'provider/:id/:path', {}, {});
  }
  async $onInit() {
    this.job.data.provider ||= {};
    if (this.job.status == 3) {
      let k = this.job.childs.find(j => (j.type === 'facebook' || j.type === 'wf-engine-gw'));
      this.result = {
        url: this.job.result.url,
        embed: `https://www.facebook.com/video/embed?video_id=${k.result.id}`,
      };
    } else if (this.job.status === 0) {
      this.job.data.video = {
        title: this.media.title,
        description: this.media.description,
      }
    }
    if (this.job.data.provider?.facebook) {
      this.selectProvider(this.job.data.provider.facebook, null)
      if (!this.job.data.pageId)
        this.pages.$promise.then((data) => {
          this.job.data.pageId = data[0].id
        })
    }
  }
  selectProvider(accountId, form) {
    this.pages = this.Provider.query({ path: 'me', id: accountId, fields: 'accounts.limit(100){id,name}' });
    if (form) {
      form.pages.$error.other = false;
      this.pages.$promise.catch((err) => {
        if (err.data && err.data.message) {
          form.pages.$error.other = err.data.message
        } else {
          form.pages.$error.other = 'Error desconocido al buscar cuentas'
        }
      })
    }
  };
}
angular.module('fireclip').component('exporterFacebook', {
  template,
  controller: Controller,
  bindings: {
    job: '<',
    media: '<',
    exporter: '<'
  }
});


export default {
  code: 'facebook',
  template,
  controller: Controller,
  image,
  service: null,
}