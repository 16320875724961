export default function ($resource, API_URL) {
	'ngInject';

	return $resource(`${API_URL}auth/local/:id`, {}, {
		register: {
			url: `${API_URL}auth/local/register`,
			method: 'POST',
		},
	});
}
