const TheKonva = require('konva')
import * as angular from 'angular';
import MiniScroll from './miniScroll';
import MacroScroll from './macroScroll';
import Thumbnail from './thumbnail';
import { auditTime, distinctUntilChanged, take } from "rxjs/operators";
import { Library } from './library';
import { EventEmitter } from "events";
import { Clip } from "./clipers";
import { Subject, BehaviorSubject, Subscription } from "rxjs";
import Konva from 'konva';
import { EditorLib } from '../app/editor/editorLib';


export interface EditorTimeLineConfig {
	/**
	 * absolute: used for live, where range start and end is an absolute date, ie: new Date(*Range.start|end)
	 * relative: used for library editor, where range si relative to 0, ie: start = 0, end = library.duration
	 */
	type: 'absolute' | 'relative',
	/**
	 * ordered libraries ids 
	 */
	libraries?: EditorLib[],  
	detail: { //TODO: refactor to detailRange rxjs subjet
		visible: boolean,
		start: number,
		end: number
	},
	master: { //TODO: refactor to masterRange rxjs subjet
		visible: boolean,
		start: number,
		end: number
	}
}

declare interface timeLine {
	on(event: 'center', listener: (middle: Date) => void);
	emit(event: 'center', middle: Date);
	off(event: 'center');

	on(event: 'requestLibraries', listener: (macroRange: { start: Date; end: Date; }) => void);
	emit(event: 'requestLibraries', macroRange: { start: Date; end: Date; });
	off(event: 'requestLibraries');

	on(event: 'play', listener: (url: string, totalDiff: number) => void);
	emit(event: 'play', url: string, totalDiff: number);
	off(event: 'play');

	on(event: 'clipAdd', listener: (id: string, start: Date, end: Date, type: string, selected: boolean) => void);
	emit(event: 'clipAdd', url: string, totalDiff: number);
	off(event: 'clipAdd');

	on(event: 'clipCreate', listener: (start: Date, type: 'clip' | 'fragment') => void);
	emit(event: 'clipCreate', start: Date, type: string);
	off(event: 'clipCreate');

	on(event: 'clipFinishUpdate', listener: (id: string, action: 'rightMove' | 'leftMove') => void);
	emit(event: 'clipFinishUpdate', id: string, action: 'rightMove' | 'leftMove')
}




require('./style.styl');

const deltaMove = 3600000 * 6;


class timeLine extends EventEmitter implements angular.IComponentController {
	libraries: Library[] = [];
	clips: Clip[] = [];
	playTime: any;
	macroRange: { start: number, end: number } = null
	microRange: { start: number, end: number } = null
	config: EditorTimeLineConfig ;

	playTime$: BehaviorSubject<{ library: Library, time: number }> = new BehaviorSubject(null);
	microRange$: BehaviorSubject<{ start: number, end: number }>
	macroRange$: BehaviorSubject<TimeLineComponent.Range>;
	width$: BehaviorSubject<number>;

	currentLibrary: Library
	imageDownloadService: any;
	thumbnailUrl: string;
	thumbnailImage: HTMLImageElement;
	onInit: (param: { service: timeLine }) => any
	element: HTMLDivElement;
	bigTimeline: MacroScroll;
	smallTimeline: MiniScroll;
	stage: Konva.Stage;
	absolutePlayTime: number;
	s: Subscription;
	observer: ResizeObserver;

	constructor($element: angular.IRootElementService) {
		'ngInject';
		super();

		this.element = <HTMLDivElement>$element.children()[1];
		this.width$ = new BehaviorSubject<number>(0);
		this.observer = new ResizeObserver(() => {
			this.width$.next(parseFloat(getComputedStyle(this.element).width));
		})
		this.observer.observe(this.element)
		this.stage = new TheKonva.Stage({
			container: this.element,
			width: this.width$.getValue(),
			offsetX: 0,
			offsetY: 0,
			height: 320,
		});

		this.imageDownloadService = new Thumbnail();
	}

	$onChanges(changesObj) {
		if (changesObj.macroRange && !changesObj.macroRange.isFirstChange()) {
			// TODO: PERFORMANCE: prev an next day use async data request, if too many clics and first requests gets after last request then data is mixed
			// 1- prevent from executing until request is satisfied
			// 2- cancel previous request (the api will sitll be fulfilling the request.)
			this.macroRange$.next({ start: this.macroRange.start, end: this.macroRange.end })
		}
		if (changesObj.microRange && !changesObj.microRange.isFirstChange()) {
			// TODO: PERFORMANCE: prev an next day use async data request, if too many clics and first requests gets after last request then data is mixed
			// 1- prevent from executing until request is satisfied
			// 2- cancel previous request (the api will sitll be fulfilling the request.)
			this.microRange$.next({ start: this.microRange.start, end: this.microRange.end })
		}
		if (changesObj.config) {
			console.log(changesObj.config)
		}
	}

	$onDestroy() {
		this.bigTimeline.destroy();
		this.stage.destroy();
		this.observer.disconnect();
		this.s.unsubscribe()
		this.clearLibraries()
		this.stage.destroy()
		this.playTime$.complete();
		this.width$.complete();
		this.addLibrary$.complete();
		this.macroRange$.complete();
		this.microRange$.complete();
	}

	$onInit() {
		console.log(this.config, '<<')
		this.width$.pipe(auditTime(200), distinctUntilChanged()).subscribe(width => {
			this.stage.width(width > 200 ? width : 200);
		})
		this.microRange$ = new BehaviorSubject({ start: this.microRange.start, end: this.microRange.end });
		this.macroRange$ = new BehaviorSubject({ start: this.macroRange.start, end: this.macroRange.end })
		this.smallTimeline = new MiniScroll(this);
		this.bigTimeline = new MacroScroll(this);
		this.s = this.macroRange$.subscribe()

		this.width$.pipe(auditTime(200), distinctUntilChanged(), take(1)).subscribe((a) => {
			console.log('width ready!!!', a)
			this.onInit({ service: this })
			this.requestLibraries(this.macroRange$.getValue());
		})
	}

	goToPlay() {
		if (this.playTime) {
			// show play at end
			let delta = 60 * 60 * 1000;
			if (this.microRange.end && this.microRange.start) {
				delta = this.microRange.end - this.microRange.start;
			}
			this.microRange$.next({ start: this.microRange.end - delta, end: this.playTime + delta * 0.1 })
		}
	}

	// TODO: PERFORMANCE: prev an next day use async data request, if too many clics and first requests gets after last request then data is mixed
	// 1- prevent from executing until request is satisfied
	// 2- cancel previous request (the api will sitll be fulfilling the request.)
	public async prevDay() {
		this.macroRange$.next({
			start: this.macroRange$.getValue().start - deltaMove,
			end: this.macroRange$.getValue().end - deltaMove
		})
	}

	public async nextDay() {
		this.macroRange$.next({
			start: this.macroRange$.getValue().start + deltaMove,
			end: this.macroRange$.getValue().end + deltaMove
		})
	}


	setPlayTime(libraryId: string, position: number) {
		const lib = this.libraries.find(l => l.id === libraryId)
		this.playTime = lib.start + position;
		this.absolutePlayTime = lib.start + position
		this.playTime$.next({
			library: lib,
			time: position
		});
	}
	playToLibraryTime(library: Library, time: number) {
		this.emit('play', library.id, time);
	}
	clearLibraries() {
		// do a slice, bc l.destroy mutates this.libraries
		this.libraries.slice().forEach(l => l.destroy())
	}
	requestLibraries(macroRange: TimeLineComponent.Range) {
		this.emit('requestLibraries', { start: new Date(macroRange.start), end: new Date(macroRange.end) })
	}
	addClip(id: string, start: number, end: number, type: 'clip' | 'fragment' | 'bumper', selected: boolean) {
		const timelineClip = new Clip(id, start, end, type, selected);
		this.clips.push(timelineClip);
		this.smallTimeline.addClip(timelineClip);
		this.bigTimeline.addClip(timelineClip);
		return timelineClip;
	}
	changeClip(id: string, start: Date, end: Date, selected: boolean) {
		const timelineClip = this.clips.find(c => c.id === id);
		if (timelineClip) {
			console.log('timeline recieved a clip change')
			console.table({
				old: { id: id, start: timelineClip.start, end: timelineClip.end, selected: timelineClip.selected },
				new: { id: id, start: start.getTime(), end: end.getTime(), selected },
			});
			timelineClip.start = start.getTime()
			timelineClip.end = end.getTime();
			// timelineClip.setEnd(end, timelineClip.subEnd);
			timelineClip.selected = selected;
		}
	}
	finishChange(timelineClip: Clip, action: 'rightMove' | 'leftMove') {
		this.emit('clipFinishUpdate', timelineClip.id, action)
	}
	// removeLibrary(id: string) {
	// 	const l = this.libraries.find(l => l.id === id);
	// 	if (l) {
	// 		l.destroy();
	// 	}
	// }
	addLibrary$ = new Subject<Library>();
	addLibrary(id: string, start: number, end: number, title: string, getThumbnailCanvasForTime: { (time: number): Promise<HTMLCanvasElement> }) {
		let timelineLibrary = this.libraries.find(l => l.id === id)
		if (!timelineLibrary) {
			timelineLibrary = new Library(id, start, end);
			if (this.config.type === 'relative')
				timelineLibrary.title = title
			timelineLibrary.getThumbnailCanvasForTime = getThumbnailCanvasForTime
			timelineLibrary.destroy$.subscribe(() => {
				const i = this.libraries.findIndex(l => l === timelineLibrary);
				if (i >= 0) this.libraries.splice(i, 1);
			})
			this.libraries.push(timelineLibrary);
			this.addLibrary$.next(timelineLibrary);
		}
		return timelineLibrary
	}
	// getLibraryForTime(time: number) {
	// 	for (let i = 0; i < this.libraries.length; i++) {
	// 		const l = this.libraries[i];
	// 		const lend = l.start + l.duration$.getValue();
	// 		if (l.start <= time && time <= lend) { return l; }
	// 	}
	// 	return null;
	// }


	public centerOn(middle: number) {
		//TODO: refactor to use variable values from timeline
		const movementStepSize = 21600000, timelineSize = 86400000
		const middleStep = Math.round(middle / movementStepSize) * movementStepSize;// get the nearest step to the center of all clips.
		this.macroRange$.next({ start: middleStep - timelineSize / 2, end: middleStep + timelineSize / 2 })
		this.microRange$.next({ start: middle - 3600 * 1000 / 2, end: middle + 3600 * 1000 / 2 })
	}

}

// angular.module('fireclip').directive('timeLine', timeLine);
angular.module('fireclip').component('timeline', {
	template: require('./timeline.pug'),
	bindings: {
		macroRange: '<',
		microRange: '<',
		config: '<',
		onInit: '&',
	},
	controller: timeLine,
});

export { timeLine }