import app from '../../app';
import { IComponentController } from "angular";
import angular from 'angular';
import { humanFileSize } from '../../utils';

const name = 'fcUploadButton';

class controller implements IComponentController {
	upload: (data: { $file: File }) => void
	constructor(private $mdDialog: angular.material.IDialogService) {
		'ngInject'
	}
	setfile($files, _$file, _$newFiles, _$duplicateFiles, $invalidFiles, $event) {
		if ($invalidFiles.length > 0) {
			let dialog
			if ($files.length > 0) {
				dialog = this.$mdDialog.confirm()
			} else {
				dialog = this.$mdDialog.alert()
			}
			// TODO: beatuify this message
			dialog.parent(angular.element(document.body))
				.clickOutsideToClose(true)
				.title('Error en algunos archivos')
				.htmlContent(`<p>${$invalidFiles.map(f => {
					if (f.$error === 'maxSize') {
						return `Archivo: ${f.name} con tamaño ${humanFileSize(f.size, true)} sobrepasa el límite de ${humanFileSize(f.$errorParam, true)}`
					} else {
						return `Archivo: ${f.name} con error código "${f.$error}", informar a soporte`
					}
				}).join('<br>')}</p>`)
				.ariaLabel('Error en archivos')
				.targetEvent($event)
			if ($files.length > 0) {
				dialog.cancel('Cancelar')
					.ok(`Continuar con ${$files.length} archivo(s) correctos?`)
				this.$mdDialog.show(dialog)
					.then(() => {
						$files.forEach(file => {
							this.upload({ $file: file })
						})
					})
			} else {
				dialog.ok(`Cancelar`)
				this.$mdDialog.show(dialog)
			}
		} else {
			$files.forEach(file => {
				this.upload({ $file: file })
			})
		}
	}
}
app.component(name, {
	bindings: {
		upload: '&',
	},
	controller: controller,
	template: `
	<md-button class="md-icon-button" ngf-multiple="true" ngf-select ngf-max-size="30000000000" ngf-change="$ctrl.setfile($files, $file, $newFiles, $duplicateFiles, $invalidFiles, $event)">
		<md-icon> file_upload </md-icon>
	</md-button>
	`
})

export { name as fcUploadButton };