import Auth0Service from "../../auth/auth0.service";

const editorLiveIcon = require('./editor-live.svg');
const editorVoDIcon = require('./editor-vod.svg');
const listLibraryIcon = require('./list-library.svg');
const listMediaIcon = require('./list-media.svg');

export class ContentSelectorCtrl implements angular.IController {
	profile: any;
	editorLiveIcon = editorLiveIcon
	editorVoDIcon = editorVoDIcon
	listLibraryIcon = listLibraryIcon
	listMediaIcon = listMediaIcon
	constructor(private mdPanelRef, Auth0Service: Auth0Service) {
		'ngInject';
		this.profile = Auth0Service.getProfile()
	}
	close() {
		this.mdPanelRef.close();
	}
}
