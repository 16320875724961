import ExportService from "./exportService";

class exportItemController implements angular.IComponentController {
	workflowCode: string;
	jobName: string;
	workflow: any;
	errorMessage: string;
	errorDetail: string;
	mailErrorSubject: string;
	mailErrorBody: string;
	constructor(public exporter, private media, private job, private WorkflowController, private ExportService: ExportService, private $mdDialog, private $mdToast, private Job) {
		'ngInject';
		this.ExportService = ExportService;
		this.$mdToast = $mdToast;
		this.workflowCode = exporter.workflowCode;
		this.Job = Job;
	}
	$onInit() {
		if (!this.job) throw new Error('job expected');
		this.ExportService.workflows.$promise.then(() => {
			this.jobName = this.ExportService.getJobName(this.workflowCode)
			this.workflow = this.ExportService.getWorkflow(this.workflowCode)
		})
		if (this.job.status == 4) {
			this.errorMessage = this.job.status.error;
			const error = this.job.childs?.[0]?.error
			// if error is a string, print as is, if not try to print it's message field
			// defaults onto prototype's toString method
			this.errorDetail = (typeof error === 'string') ? error : (error.message || error);
			this.mailErrorSubject = "Error en FireClip al exportar";
			this.mailErrorBody = encodeURIComponent(`
			
			## Escribe tu mensaje arriba ##
			General params:
			url: ${location.href}
			Media details:
			id: ${this.job.media}
			title: ${this.media?.title}
			created: ${this.media && new Date(this.media.createdAt).toISOString()}
			Job details:
			id: ${this.job.id}
			type: ${this.job.type}
			sent at time: ${new Date(this.job.sentAt).toISOString()}
			general message: ${this.errorMessage}
			detail message: ${this.errorDetail}
			`)
		}
	}
	close() {
		this.$mdDialog.cancel()
	};
	export(job) {
		this.WorkflowController.export(this.media, job).then(() => this.$mdDialog.hide())
	};
	deleteJob(event, job, media) {
		var confirm = this.$mdDialog.confirm()
			.title('¿Deseas borrar la exportación?!')
			.textContent('Sólo se marcará como borrada, el proces continuará si es que aún está procesando.')
			.ariaLabel('Borrar Exportación')
			.targetEvent(event)
			.ok('Borrar')
			.cancel('Cancelar')
			.multiple(true);
		return this.$mdDialog.show(confirm)
			.then(() => {
				return this.ExportService.removeJob(job, media)
					.then(() => {
						this.$mdDialog.hide();
						this.$mdToast.show(
							this.$mdToast.simple()
								.textContent('Exportación borrada con éxito')
								.position('top right')
								.hideDelay(3000)
						)
					}).catch((error) => {
						// this.$mdDialog.cancel();
						console.error(error)
						this.$mdToast.show(
							this.$mdToast.simple()
								.textContent('Error al borrar exportación: ' + error.data?.message && error.message)
								.position('top right')
								.hideDelay(4000)
						)
					});
			});
	}
	retryJob() {
		if (this.job.childs[0]) {
			this.Job.resend({ id: this.job.childs[0].id }).$promise.then(() => this.close())
		} else {
			alert('Esta exportación está malformada, no se puede re-enviar')
		}
	}
}

export default exportItemController