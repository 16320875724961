import * as  angular from 'angular';

const template = require('./index.pug');
const image = require('./wordpress.svg');

class Controller implements angular.IComponentController {
	providers: { amazon: any; wordpress: any };
	exporter: any;
	job: any;
	media: any;
	result: any;
	constructor() {
		"ngInject";
	}
	async $onInit() {
		if (this.job.status == 3) {
			this.result = this.job.result.url;
		}
	}
}
angular.module('fireclip').component('exporterWordpress', {
	template: template,
	controller: Controller,
	bindings: {
		job: '<',
		media: '<',
		exporter: '<'
	}
});

export default {
	code: 'wordpress',
	template,
	controller: Controller,
	image,
	service: null,
}