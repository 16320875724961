import MediaService from '../media/media.service';
import { setUserLocalSetting } from '../utils'
import { EditorService } from '../editor/editor.service';
import { Channel } from '../channel/channelService';
import Auth0Service from '../auth/auth0.service';

class liveController implements IComponentControllerUIRouter {
	channel: Channel;
	recording: FireClip.Recording
	tabSelected = 0
	editHandler: any;
	media: FireClip.Media;
	showOwnedByMe = true;
	showClips = true;
	showImages = true;
	showGifs = true;
	filter: { owner?: string, types?: string[] } = {};
	liveEditor: EditorService
	constructor(private MediaService: MediaService, private Auth0Service: Auth0Service) {
		"ngInject";
		this.editHandler = this.edit.bind(this);
		this.setupMediaFilter()
		this.MediaService.onEdit(this.editHandler)
		this.liveEditor = new EditorService();
	}
	$onInit() {
		setUserLocalSetting(this.Auth0Service.getProfile().id, 'lastActiveChannelID', this.channel.id)
	}
	$onDestroy() {
		this.liveEditor.destroy();
		this.MediaService.offEdit(this.editHandler);
	}
	uiOnParamsChanged(newParams) {
		if (newParams.input) {
			this.recording = this.channel.inputs.find(i => i.id === newParams.input).recording
		}
	}
	setupMediaFilter() {
		const newFilter = {
			types: [],
			owner: null,
			archive: false,
		}
		if (!this.showImages || !this.showGifs || !this.showClips) {
			if (this.showImages) newFilter.types.push('image');
			if (this.showGifs) newFilter.types.push('gif')
			if (this.showClips) newFilter.types.push('clip')
		}

		if (!this.showOwnedByMe) newFilter.owner = '*';
		// assign new object, so below component gets notified in $onChanges event handler, read: https://docs.angularjs.org/guide/component#component-based-application-architecture
		this.filter = newFilter;
	}
	edit() {
		this.tabSelected = 0;
	}
}

export default {
	'fcLive': {
		bindings: {
			channel: '<',
			recording: '<',
		},
		template: require('./live.pug'),
		controller: liveController,
	}
}