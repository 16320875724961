import socketService from '../socketService';
import { EventEmitter } from 'events';
import { cloneDeep, clone } from 'lodash';
import { mediaURL } from '../utils';
import { Channel } from '../channel/channelService';
import Auth0Service from '../auth/auth0.service';
// class Media {
//   id: string
//   constructor(id: string) {

//   }
// }

class MediaService extends EventEmitter {
	medias: FireClip.Media[] = []
	loading = false
	mediaChange: () => void;
	jobChange: () => void;
	jobRemove: () => void;
	filter: {
		archive?: boolean,
		type?: string[],
		owner?: string
	} = { archive: null };
	Media: FireClip.angularResource<FireClip.IResourceMedia>;
	constructor($resource, private $rootScope, private WebNotification, private MEDIA_STORAGE_HOST, private Auth0Service: Auth0Service) {
		'ngInject';
		super()
		this.Media = $resource(`${theConfig.api_url}/media/:id`, { id: '@id' })
		this.mediaChange = this.onMediaChange.bind(this);
		this.jobChange = this.onJobChange.bind(this);
		this.jobRemove = this.onJobRemove.bind(this)
	}
	onUpdate() {
		socketService.onMediaChange(this.mediaChange)
		socketService.onJobChange(this.jobChange);
		socketService.onJobRemove(this.jobRemove);
	}
	offUpdate() {
		socketService.offMediaChange(this.mediaChange)
		socketService.offJobChange(this.jobChange);
		socketService.offJobRemove(this.jobRemove);
	}
	getById(_id: string): PromiseLike<FireClip.Media> {
		throw "not implemented";
		// return this.Media.one({ id }).$promise
	}
	update(media: FireClip.Media) {
		return this.Media.update({ id: media.id }, {
			title: media.title,
			description: media.description,
			tags: media.tags
		})
	}
	upsert(media: FireClip.Media, process: boolean): any {
		if (media.id) {
			return this.Media.update({ id: media.id, process }, media).$promise;
		} else {
			return this.Media.save({ process }, media).$promise;
		}
	}
	onJobChange(job) {
		if (!job.media) {
			// console.error('no media attribute for job ' + job.id);
			return
		}
		var id = typeof (job.media) == 'object' ? job.media.id : job.media;
		var media = this.medias.find(m => m.id == id);
		if (!media) {
			// TODO: this is not an error, media may not be found bc of filters.
			// console.error(`no media ID: ${id} found for job ID: ${job.id}`)
			return;
		}
		if (job.root) {
			if (!media.exports) media.exports = [];
			const rootJobFound = media.exports.find(j => j.id === job.root)
			if (rootJobFound) {
				rootJobFound.childs ||= []
				const jobFound = rootJobFound.childs.find(j => j.id === job.id)
				if (jobFound) {
					Object.assign(jobFound, job);
				} else {
					if (rootJobFound.childs) {
						rootJobFound.childs.push(job);
					} else {
						rootJobFound.childs = [job];
					}
				}
			} else {
				/**
				 * this job has no root job, this is an error
				 * workaround could be to add a placeholder {id: job.root, childs:[job]}, but this can cause errors in the UI
				 * fttb will skip
				 */
			}
		} else {
			if (media.exports) {
				const jobFound = media.exports.find(j => j.id === job.id);
				if (jobFound) {
					Object.assign(jobFound, job);
				} else {
					media.exports.push(job);
				}
			} else {
				media.exports = [job];
			}
			if (job.status === 3 || job.status === 4) {
				this._notify(job, media);
			}
		}
		return this.$rootScope.$digest();
	}
	mediaIsInFilter(media) {
		let correctOwnership = true;
		let correctArchived = true;
		let correctTypes = true;
		let inDateRange = true;
		if (this.filter.owner !== '*') { // are we filtering by current user is owner
			correctOwnership = media.user.id === this.Auth0Service.getProfile().id;
			// console.log(`${media.id} is from user ${media.user.id} and it should be ${correctOwnership ? 'shown' : 'hidden'}`)
			if (!correctOwnership) return false;
		}
		/**
		 * filterArchive | mediaArchive | correctArchived?
		 *          true |         true | true
		 *          true |        false | false
		 *         false |         true | false
		 *         false |        false | true
		 */
		correctArchived = this.filter.archive === !!media.archivedAt; // date to boolean
		// console.log(`${media.id} is ${media.archivedAt ? '' : 'not'} archived and it should be ${correctArchived ? 'shown' : 'hidden'}`)
		if (!correctArchived) return false;
		if (this.filter.type?.length > 0) {
			correctTypes = this.filter.type.includes(media.type)
			// console.log(`${media.id} is of type ${media.type} and it should be ${correctTypes ? 'shown' : 'hidden'}`)
			if (!correctTypes) return false;
		}
		const lastMedia = this.medias[this.medias.length - 1];
		if (lastMedia?.createdAt) {
			inDateRange = new Date(media.createdAt) >= new Date(lastMedia.createdAt);
			// console.log(`${media.id} is from ${media.createdAt} and it should be ${inDateRange ? 'shown' : 'hidden'}`)
			if (!inDateRange) return false;
		}
		return true;
	}
	onMediaChange(response) {
		// console.log("onMediaChange", response)
		const index = this.medias.findIndex(m => m.id === response.media.id);
		if (index !== -1) { // media found in local list
			// the order of execution matters, when action is delete media is this object: {id: string}
			if (response.action !== 'delete' && this.mediaIsInFilter(response.media)) {
				this.medias[index] = response.media;
			} else {
				this.removeMediaFromList(response.media);
			}
			this.$rootScope.$digest();
		} else { // media not found in local list
			if (response.action !== 'delete' && this.mediaIsInFilter(response.media)) { // media visible, should update
				this.medias.unshift(response.media) // adding a media will assume is a newer media
				this.$rootScope.$digest();
			} else {
				// do nothing, media is not in current list and shouldn't be added
			}
		}
	}

	_notify(job, media) {
		if ((this.WebNotification.isSupported() && this.WebNotification.permission() === 'granted') && job.parent === null) {
			let icon;
			if (media.mainJob?.result?.image) icon = this.MEDIA_STORAGE_HOST + '/' + media.mainJob.result.image;
			const name = job.type === 'clip' ? 'Media' : 'Exportación';
			let title = `${name} procesada correctamente`;
			if (job.status === 4) title = `${name} con error`;
			this.WebNotification.create(title, { body: media.title, icon });
		}
	}

	clear() {
		this.medias.length = 0;
	}
	onJobRemove(job) {
		const media = this.medias.find(m => m.id === job.media);
		if (media) {
			this.removeJob(media, job)
			this.$rootScope.$digest();
		}
	}
	removeJob(media, job) {
		if (media.exports) {
			const idx = media.exports.findIndex(e => e.id === job.id)
			if (idx != -1) {
				media.exports.splice(idx, 1);
			}
		}
	}
	addNewRootJobToMedia(media, job) {
		media.exports = media.exports || [];
		var index = media.exports.findIndex(j => j.id === job.id)
		if (index != -1) return false; //job not added
		media.exports.push(job);
		return true;
	}
	add(media) {
		const index = this.medias.findIndex(item => item.id === media.id)
		if (index === -1) {
			this.medias.unshift(media);
		} else {
			this.medias[index] = media;
		}
		if (media.jobs) {
			media.mainJob = media.jobs.find(j => j.type == 'upload' || j.type == 'clip');
		}
	}
	removeMediaFromList(media: FireClip.Media) {
		var index = this.medias.findIndex(m => m.id === media.id)
		if (index >= 0) this.medias.splice(index, 1);
	}
	delete(media) {
		return this.Media
			.delete({ id: media.id })
			.$promise
			.then(() => {
				this.removeMediaFromList(media);
			})
	}
	load(channel: Channel, clear, fromDate, toDate = new Date(), archive: boolean = null, title: string, type: string[], owner: string = null): PromiseLike<boolean> {
		this.filter.archive = archive;
		this.filter.type = type;
		this.filter.owner = owner;
		console.log(this.filter)
		if (!channel) return Promise.reject();
		if (this.loading) return Promise.resolve(true)
		if (clear) this.clear();
		var pageSize = 30;
		if (this.medias.length > 0) {
			toDate = this.medias[this.medias.length - 1].createdAt;
		}
		this.loading = true;
		const query = {
			channel: channel.id,
			limit: pageSize,
			to: toDate,
			from: fromDate,
			archived: undefined,
			title,
			type,
			owner,
		};
		if (archive !== null) {
			if (archive === false) {
				query.archived = false;
			} else
				query.archived = true;
		}
		return this.Media.query(query).$promise
			.then((items) => {
				this.loading = false;
				//pageByLastMedia keep the last media in the list, we can use that to get the next 10
				if (items.length > 0) {
					this.medias = this.medias.concat(items);
				}
				this.loading = false;
				return (items.length == pageSize)
			})
			.catch(error => {
				this.loading = false;
				console.error(error)
				throw "error loading"
			})
	}
	getVideoURL(media) {
		if (media.type == 'upload') {
			if (media.mainJob && media.mainJob.status == 3 && media.mainJob.result) {
				return this.MEDIA_STORAGE_HOST + media.mainJob.result.output.path + '/' + media.mainJob.result.output.file;
			}
		} else {
			if (media.mainJob && media.mainJob.result) {
				return this.MEDIA_STORAGE_HOST + media.mainJob.result.path;
			}
		}
		return null;
	}
	getThumbnail(media) {
		if (media.type === 'gif') {
			const gifExporter = media.exports.find(e => e.type === 'gif')
			if (gifExporter?.result) {
				return {
					url: mediaURL(gifExporter.result),
					data: null
				}
			}
		} else if (media && media.exports) {
			const clipExport = media.exports.find(e => e.type === 'clip' || e.type === 'image')
			if (clipExport && clipExport.status == 3) {


				if (clipExport.result?.image) {
					return {
						url: mediaURL(clipExport.result?.image),
						data: null
					}
				}

				// TODO: Delete this block after full migration to nws, as it will become obsolete
				if (clipExport.childs.length === 1) {
					if (clipExport.type === 'clip') {
						const result = clipExport.childs[0].result;
						if (result.image) {
							return {
								url: mediaURL(result.image),
								data: null
							}
						} else {
							return { url: null, data: null }
						}
					} else {
						//TODO: take first image only.. should show more?
						return {
							url: null,
							data: `data:image/png;base64,${clipExport.childs[0].result?.images?.[0]}`
						}
					}
				}
			}
		}
		return {
			url: require('./back_in_time.jpg'),
			data: null,
		}
	}
	createNew(channelId: string, recordingId: string, title: string = null, description: string = null, tags: string[] = null): FireClip.Media {
		return {
			id: null,
			channel: channelId,
			recording: recordingId,
			title,
			description,
			tags,
			trash: false,
			archivedAt: null
		}
	}
	create(media: FireClip.Media): PromiseLike<FireClip.Media> {
		return this.Media.save(media).$promise
	}
	async archive(media, value): Promise<FireClip.Media> {
		this.Media.query().$promise
		return this.Media
			.update(
				{ id: media.id },
				{ archivedAt: value ? new Date() : null })
			.$promise;
	}
	edit(media) {
		const clonedMedia = cloneDeep(media);
		this.emit('edit', clonedMedia);
	}
	setTagsToCurrentMedia(tags: string[]) {
		this.emit('edit', { tags: clone(tags) }, 'tags');
	}
	setDescriptionToCurrentMedia(description: string) {
		this.emit('edit', { description }, 'description');
	}
	setTitleToCurrentMedia(title: string) {
		this.emit('edit', { title }, 'title');
	}

	duplicate(media: FireClip.Media) {
		const clonedMedia: FireClip.Media = {
			title: media.title,
			tags: media.tags,
			description: media.description,
			type: media.type,
			channel: media.channel,
			data: {
				clips: media.data.clips,
				timeLineConfig: media.data.timeLineConfig
			}
		};
		this.emit('edit', clonedMedia);
	}
	onEdit(callback: (media: FireClip.Media) => void) {
		this.on('edit', callback);
	}
	offEdit(callback) {
		this.off('edit', callback);
	}
}

export default MediaService