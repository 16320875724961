import { AfterViewInit, Component, ElementRef, Inject, Injectable, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { from, merge, of, Subject } from "rxjs";
import { catchError, debounceTime, map, startWith, switchMap } from "rxjs/operators";
import { RecordingService } from "./recording.service";
import 'reflect-metadata';
import socketService from "../socketService";
import { DatePipe } from "@angular/common";
import { LOCALE_ID } from '@angular/core';
// import { MatPaginator } from "@angular/material/paginator";
import { Recording, RecordingSchedule, RecordingValueKeys } from "./recording.model";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RecordingEditDialogComponent } from "./recording-edit-dialog.component";
import { ConfirmDialog } from "../common/dialog-confirm.component";
import Auth0Service from "../auth/auth0.service";
import uiActions from "../auth/uiActions";
require('./recording.styl')


// eslint-disable-next-line @typescript-eslint/no-var-requires
const TEMPLATE_CONTENT: string = require("./recording-list.template.pug");

interface IngestRow {
	statuses: { monitor: any; recording: any; };
	enabled: any;
	statusSpanBoxClass: string;
	runningSince: string;
	id: string;
	name: string
	ingestResource: string
	inputType: string
	contentType: string
	ingestType: string
	recordingStatus: any
	monitorStatus: any
	schedule: any
}


const States = {
	READY: {
		SENT: "PREPARING",
		RUNNING: "RUNNING",
		RECORDING: "RECORDING",
		TIMEOUT: "TIMED OUT",
		STOPPED: "STOPPED",
		OUT_OF_SCHEDULE: "OUT OF SCHEDULE"
	},
	STOPPED: {
		SENT: "STOPPING",
		RUNNING: "STOPPING",
		RECORDING: "STOPPING",
		TIMEOUT: "STOPPING",
		STOPPED: "STOPPED",
		OUT_OF_SCHEDULE: "OUT OF SCHEDULE"
	},
	CHANGES_PENDING: {
		SENT: "UPDATING",
		RUNNING: "RUNNING",
		RECORDING: "RECORDING",
		TIMEOUT: "UPDATING",
		STOPPED: "UPDATING",
		OUT_OF_SCHEDULE: "UPDATING"
	},
	PROCESSING_SIGNAL: {
		SENT: "PREPARING",
		RUNNING: "RUNNING",
		RECORDING: "RECORDING",
		TIMEOUT: "TIMED OUT",
		STOPPED: "STOPPED",
		OUT_OF_SCHEDULE: "STOPPED"
	},
	TAINTED: {
		SENT: "STOPPING",
		RUNNING: "STOPPING",
		RECORDING: "STOPPING",
		TIMEOUT: "STOPPING",
		STOPPED: "STOPPED",
		OUT_OF_SCHEDULE: "STOPPING"
	},
	CONCILIATING: {
		SENT: "UPDATING",
		RUNNING: "RUNNING",
		RECORDING: "RECORDING",
		TIMEOUT: "UPDATING",
		STOPPED: "UPDATING",
		OUT_OF_SCHEDULE: "UPDATING"
	},
	DEFAULT_STATE: {
		SENT: "STOPPED",
		RUNNING: "STOPPED",
		RECORDING: "STOPPED",
		TIMEOUT: "STOPPED",
		STOPPED: "STOPPED",
		OUT_OF_SCHEDULE: "OUT OF SCHEDULE"
	},
};

@Injectable()
@Component({
	selector: "recording-list-component",
	template: TEMPLATE_CONTENT,
	styles: []
})
export class RecordingListComponent implements AfterViewInit {
	@ViewChild("padding") padding: ElementRef;
	@ViewChild("tableContainer") tableContainer: ElementRef;
	// @ViewChild("paginatorContainer", { read: ViewContainerRef }) paginatorContainer;
	// @ViewChild(MatPaginator) paginator: MatPaginator;

	isLoading = true
	filters = {
		showOnlyActive: false,
		...RecordingValueKeys
	}
	refreshClick = new Subject<void>()
	displayedColumns: string[] = [
		'name',
		// 'ingestResource',
		'contentType',
		'inputType',
		// 'ingestType',
		'recordingStatus',
		'schedule',
		'duration',
		'restart',
		'edit',
		'delete',
		'enable',
	];
	searchTerm = "";
	recordings: Recording[] = []
	dataSource = new MatTableDataSource<IngestRow>();
	lastUpdate = "";
	showFirstLastButtons = true;
	statusUpdates = new Map();
	total = 0;
	datePipe: DatePipe;
	showIngestRestartButton = false
	showIngestDeleteButton = false
	showIngestEditButton = false
	showIngestEnableButton = false

	constructor(
		@Inject('RecordingService') private RecordingService: RecordingService,
		@Inject('Auth0Service') private Auth0Service: Auth0Service,
		@Inject(LOCALE_ID) public locale: string,
		public dialog: MatDialog,
		public snack: MatSnackBar,
	) {

		this.showIngestRestartButton = this.Auth0Service.profileHasUIAction(uiActions.rebootIngest);
		this.showIngestDeleteButton = this.Auth0Service.profileHasUIAction(uiActions.deleteIngest);
		this.showIngestEditButton = this.Auth0Service.profileHasUIAction(uiActions.editIngest);
		this.showIngestEnableButton = this.Auth0Service.profileHasUIAction(uiActions.enableIngest);

		this.datePipe = new DatePipe(locale);
		for (const filter in RecordingValueKeys) {
			this.filters[filter] = [];
			if (Object.prototype.hasOwnProperty.call(RecordingValueKeys, filter)) {
				const filterDef = RecordingValueKeys[filter];
				this.filters[filter] = filterDef.map(e => ({
					...e,
					selected: true,
					toggle: ($event, filter) => {
						filter.selected = !filter.selected;
						$event.stopPropagation()
						this.refreshClick.next()
					}
				}));
			}
		}
	}

	public recordingHourRange(schedule: RecordingSchedule): string {
		if (!schedule) return "N/A";
		if (schedule.end.getTime() - schedule.start.getTime() >= 24 * 60 * 60 * 1000) return "Contínua"
		return `${this.datePipe.transform(schedule.start, "HH:mm")} - ${this.datePipe.transform(schedule.end, "HH:mm")}`
	}

	matchIngestStatus(monitorState: string, recordingState: string): string {
		return States?.[monitorState]?.[recordingState] || "STOPPED"
	}

	confirmDialog(title: string, message: string) {
		return this.dialog
			.open(ConfirmDialog, {
				disableClose: true,
				data: { title, message }
			})
	}

	openSnack(message: string, action = 'OK') {
		this.snack.open(message, action, { duration: 5000 })
	}

	delete(recording) {
		this
			.confirmDialog('Borrar ingesta', `¿Está seguro que desea borrar la ingesta ${recording.name} ?`)
			.afterClosed()
			.subscribe(async confirmed => {
				if (confirmed) {
					try {
						this.isLoading = true;
						await this.RecordingService.delete(recording.id)
						this.openSnack('Ingesta eliminada correctamente.')
						this.isLoading = false;
						this.refreshClick.next()
					} catch (error) {
						this.isLoading = false;
						this.openSnack(`Error al eliminar "${recording.name}": ${error.message}`, 'Cerrar')
					}
				}
			})
	}

	async edit(recording) {
		try {
			this.isLoading = true;
			const foundRecording = await this.RecordingService.get(recording.id);
			this.isLoading = false;
			this.showEditCreateDialog(foundRecording)
		} catch (error) {
			this.isLoading = false;
			this.openSnack(`Error al obtener ingesta "${recording.name}": ${error.message}`, 'Cerrar')
		}
	}

	create() {
		this.showEditCreateDialog(new Recording())
	}

	showEditCreateDialog(recording) {
		let success, title;
		if (recording.id) {
			title = 'Editar ingesta'
			success = 'Ingesta editada correctamente.'
		} else {
			title = 'Crear ingesta';
			success = 'Ingesta creada correctamente.'
		}
		const dialog = this.dialog.open(RecordingEditDialogComponent, {
			disableClose: true,
			data: {
				title,
				recording,
			}
		})
		dialog.afterClosed().subscribe((result) => {
			if (result) {
				this.openSnack(success)
				this.refreshClick.next()
			}
		})
	}

	restart(recording) {
		this
			.confirmDialog('Reiniciar ingesta', `¿Está seguro que desea reiniciar la ingesta "${recording.name}"?`)
			.afterClosed()
			.subscribe(async confirmed => {
				if (confirmed) {
					try {
						await this.RecordingService.restart(recording.id)
						this.openSnack('Ingesta reiniciada correctamente.')
					} catch (error) {
						this.openSnack(`Error al reiniciar "${recording.name}": ${error.message}`, 'Cerrar')
					}
				}
			})
	}

	toggleEnabled(recording, _event) {
		this
			.confirmDialog(`${recording.enabled ? 'Activar' : 'Desactivar'} ingesta`, `¿Está seguro que desea ${recording.enabled ? 'activar' : 'desactivar'} la ingesta "${recording.name}"?`)
			.afterClosed()
			.subscribe(async confirmed => {
				if (confirmed) {
					try {
						await this.RecordingService.enable(recording.id, recording.enabled)
						this.openSnack(`Ingesta ${!recording.enabled ? "desactivada" : "activada"} correctamente.`)
						this.refreshClick.next()
					} catch (error) {
						_event.source.checked = !_event.source.checked;
						this.openSnack(`Error al ${!recording.enabled ? "desactivar" : "activar"} "${recording.name}": ${error.message}`, 'Cerrar')
					}
				} else {
					_event.source.checked = !_event.source.checked;
				}
			})
	}

	updateStatuses(params = "[]") {
		const recordingMonitorStatus = JSON.parse(params);
		for (const key in recordingMonitorStatus) {
			const update = recordingMonitorStatus[key];
			this.statusUpdates.set(update.ingest_id, {
				monitorStatus: update.monitorState?.toUpperCase(),
				recordingStatus: update.reportedState?.toUpperCase(),
				lastUpdate: new Date()
			});
		}
		this.dataSource.data = this.dataSource.data.map(row => {
			const update = this.statusUpdates.get(row.id);
			if (update) {
				row.monitorStatus = update.monitorStatus;
				row.recordingStatus = update.recordingStatus;
			}
			return row;
		});
	}

	recordingToRow(recording: Recording): any {
		let sourceURL: URL = null;
		try {
			if ("stream" === recording.input.type) {
				sourceURL = new URL((<any>recording.input).stream);
			} else if ("upload" === recording.input.type) {
				sourceURL = new URL((<any>recording.input).tusEndpoint + (<any>recording.input).tusPath);
			}
		}
		catch (e) {
			//
		}
		const protocol = (sourceURL?.protocol.replace(":", "") || "invalid").toLowerCase()
		return {
			'id': recording.id,
			'name': recording.name || "invalid",
			'ingestResource': sourceURL?.toString(),
			'inputType': RecordingValueKeys.RecordingTypes.find(t => t.value === recording.input.type)?.name || "invalid",
			'contentType': RecordingValueKeys.ContentTypes.find(t => t.value === recording.type)?.name || "invalid",
			'ingestType': RecordingValueKeys.Protocols.find(t => t.value === protocol)?.name || "invalid",
			'monitorStatus': 'UNKNOWN',
			'recordingStatus': 'UNKNOWN',
			'schedule': recording?.schedule ? recording.schedule.recordingHourRange() : "N/A",
			'duration': (() => {
				if (!recording?.schedule?._start || !recording?.schedule?._end) return "N/A"
				const secondsDiff = (recording.schedule.end.getTime() - recording.schedule.start.getTime()) / 1000
				return `${Math.floor(secondsDiff / 3600)}h ${Math.floor((secondsDiff % 3600) / 60)}m ${Math.floor((secondsDiff % 3600) % 60)}s`
			})(),
			'enabled': recording.enabled,
			'statusSpanBoxClass': '',
		}
	}

	updateTablePage() {
		const data: Recording[] = this.recordings.filter((item) => {
			if (this.filters.showOnlyActive && !item.enabled) return false;
			let status = this.statusUpdates.get(item.id)
			status = status ? status : { recordingStatus: "unknown" };
			const filterPassed = this.filters.RecordingTypes.find((t: any) => t.selected && t.value === item.input.type) &&
				this.filters.ContentTypes.find((t: any) => t.selected && t.value === item.type) &&
				this.filters.Protocols.find((t: any) => t.selected && (item.input.stream || item.input.tusEndpoint || '').toLowerCase().startsWith(t.value)) &&
				this.filters.RecordingStatus.find((t: any) => t.selected && t.value === (status.recordingStatus || "").toLowerCase()) &&
				this.filters.EnabledStatuses.find((t: any) => t.selected && t.value === item.enabled);
			if (!filterPassed) return false;
			if (!this.searchTerm) return true;
			return JSON.stringify(item).includes(this.searchTerm);
		})
		// .slice(this.paginator.pageIndex * this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize);
		this.dataSource.data = data.map(this.recordingToRow)
		this.updateStatuses();
	}

	ngAfterViewInit() {
		socketService.onRecordingStatuses((e) => { this.updateStatuses(e) });
		socketService.subscribeToRecordingsStatus();
		merge(
			// this.paginator.page,
			this.refreshClick
		)
			.pipe(
				debounceTime(600),
				startWith({}),
				switchMap(() => {
					this.isLoading = true;
					return from(this.RecordingService.query({ populate: false, }))
						.pipe(catchError((err) => {
							console.error(err)
							this.openSnack(`Error al cargar datos: ${err.message}`, 'Cerrar')
							return of([])
						}))
				}),
				map((result: any[]) => {
					this.isLoading = false;
					this.recordings = result;
					this.total = result?.length;
					this.updateTablePage();
				})
			)
			.subscribe();
	}
}

