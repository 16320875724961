import * as angular from 'angular';
import ExportService from '../exporter/exportService';
import ChannelService, { Channel } from '../channel/channelService';
import MediaService from './media.service';
import { merge } from 'lodash'
import { copyToClipboard } from '../utils';

class MediaViewController implements angular.IController {
	saving = false
	errorMessage: string;
	message: string;
	constructor(private media, private $mdDialog: angular.material.IDialogService, private MediaService: MediaService, private $mdToast: angular.material.IToastService) {
		'ngInject';
	}
	close() {
		this.$mdDialog.cancel();
	}
	save() {
		this.errorMessage = null;
		this.saving = true;
		this
			.MediaService
			.update(this.media)
			.$promise
			.then(newMedia => this.$mdDialog.hide(newMedia))
			.catch(error => {
				console.error(error)
				this.saving = false;
				this.errorMessage = "Ocurrió un error al guardar.";
				if (error && error.data) {
					this.errorMessage += ` Mensaje: ${error.data.message}`
				}
			})
	}
	copyTitleToMedia(title: string) {
		this.MediaService.setTitleToCurrentMedia(title);
	}
	copyDescriptionToMedia(description: string) {
		this.MediaService.setDescriptionToCurrentMedia(description);
	}
	copyTagsToMedia(tags: string[]) {
		this.MediaService.setTagsToCurrentMedia(tags);
	}
	copyToClipboard(text: string, successMessage: string) {
		copyToClipboard(text)
			.then(() => {
				this.$mdToast.show(this.$mdToast.simple()
					.textContent(successMessage)
					.position('top right')
					.parent(document.querySelector('#media-view-editor').querySelector('md-dialog-content'))
				);
			})
	}
}

require('./media.item.styl')
class mediaItemController implements angular.IComponentController {
	openExport: boolean;
	workflow: boolean;
	editing: boolean;
	archiving: boolean;
	channel: Channel;
	exporters: FireClip.Exporter[];
	pendingAction: boolean;
	MediaListController: any;
	errorMessage: string;
	enabledExporter: FireClip.Exporter[];
	media : FireClip.Media
	constructor(private ExportService: ExportService, private ChannelService: ChannelService, private MediaService: MediaService, private $mdToast: angular.material.IToastService, private $mdDialog) {
		'ngInject';

		this.openExport = false; //workflow config
		this.workflow = false;
		this.editing = false;
		this.archiving = false;
		this.channel = ChannelService.activeChannel;
		this.exporters = this.channel?.exporters;
		this.enabledExporter = [];
		this.ExportService.workflows.$promise.then(() => {
			this.exporters.forEach(exporter => {
				exporter.workflow = this.ExportService.getWorkflow(exporter.workflowCode)
			});
		})
	}
	getMediaPermalink(id) {
		return location.href + '?media=' + id;
	}
	reprocessClip(clipJob) {
		if (clipJob) {
			this.ExportService.export(clipJob);
			//missing then..
		} else {
			console.error('Cant reprocessClip if no job')
		}
	}
	getVideoUrl(media) {
		return this.MediaService.getVideoURL(media);
	}
	getThumbnail(media) {
		// TODO: get url for job type .... gif..
		return this.MediaService.getThumbnail(media);
	}
	message(text) {
		this.$mdToast.show(this.$mdToast.simple().textContent(text));
	}
	archive(media: FireClip.Media, archiveValue = true) {
		if (!this.pendingAction) {
			this.pendingAction = true;
			this.MediaService
				.archive(media, archiveValue)
				.then((upMedia) => {
					media.archivedAt = upMedia.archivedAt;
					if (!this.MediaService.mediaIsInFilter(media)) {
						this.MediaService.removeMediaFromList(media) // no apply? 
					}
					this.message('Media archivada correctamente');
				})
				.catch(() => {
					this.message('Ha ocurrido un problema al archivar');
				})
				.then(() => {
					this.pendingAction = false;
				});
		}
	}
	clone(media) {
		this.MediaService.duplicate(media)
	}
	openMedia(media, event) {
		if (!media.exports || media.exports.length === 0) {
			this.MediaService.edit(media)
			return
		}
		this.$mdDialog.show({
			controller: MediaViewController,
			targetEvent: event,
			locals: {
				media: angular.copy(media)
			},
			controllerAs: '$ctrl',
			template: require('./media.view.pug'),
			parent: angular.element(document.body)
		})
			.then(updatedMedia => {
				merge(media, updatedMedia)
			})
	}
	async openMenu($mdMenu, ev) {
		this.enabledExporter = this.exporters.filter(exporter => {
			return exporter.active && this.media.exports.find(e => e.type === exporter.workflow.requires)
		})
		$mdMenu.open(ev);
	}
	async openJob(theExport, media, event) {
		try {
			const exporter = this.ChannelService.activeChannel.exporters.find(e => e.id === theExport.exporterId)
			if (theExport.status === 5) {
				let message = 'No se puede ejecutar esta exportación:\n  Dependencia no terminada';
				if(this.isReadyToSend(media, theExport)) message = await this.ExportService.resendJob(theExport);
				return this.$mdToast.show(
					this.$mdToast.simple()
						.textContent(message)
						.position('top right')
						.hideDelay(3000)
						.parent(angular.element(document.body)))
			}
			if (event.shiftKey) {
				const message = await this.ExportService.executeDefaultAction(exporter, media, theExport);
				this.$mdToast.show(
					this.$mdToast.simple()
						.textContent(message)
						.position('top right')
						.hideDelay(3000)
						.parent(angular.element(document.body)));
				return
			}
			if (!theExport.exporterId) throw 'error, no exporterId';
			this.ExportService
				.openExportDialog(exporter, media, null, theExport, event)
				.catch(error => {
					console.error(error)
				})
		} catch (error) {
			console.error(error);
			alert(error.message)
		}
	}
	isReadyToSend(media: FireClip.Media, job: FireClip.Job){
		if (job.dependsOn){
			return (media.exports.find(e => e.id===job.dependsOn).status === 3) 
		}
		return true;
	}
	openCreateJob(exporter, media, event) {
		this.ExportService.openExportDialog(exporter, media, null, null, event);
	};
	delete(media, event) {
		const confirmDialog = this.$mdDialog.confirm()
			.title('Confirmar borrado')
			.textContent('¿Estás seguro que deseas borrar este elemento?')
			.ariaLabel('Borrar media')
			.targetEvent(event)
			.ok('Borrar')
			.cancel('Cancelar');
		this.$mdDialog.show(confirmDialog).then(() => {
			this.pendingAction = true;
			this.MediaService.delete(media)
				.then(() => {
					console.log('media borrada')
				})
				.catch((error) => {
					console.error(error)
					this.errorMessage = 'Error al borrar media'
					if (error.data && error.data.message) {
						this.errorMessage += `: ${error.data.message}`;
					}
				})
				.then(() => {
					this.pendingAction = false;
				})
		});
	}
}


angular.module('fireclip').component('fcMediaItem', {
	controller: mediaItemController,
	template: require('./media.item.pug'),
	require: {
		MediaListController: '^^mediaList'
	},
	bindings: {
		media: '='
	}
})
