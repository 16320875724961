import { IComponentController } from "angular";
import './style.styl'
import Thumbnail from "../../timeline/thumbnail";
import { LibraryService } from "../library/library.service";

class LibraryThumbnail implements IComponentController {
	imageElement: HTMLImageElement;
	dateTime: Date = new Date(0, 0, 0, 0, 0, 0, 0);
	imageDownloadService: Thumbnail = new Thumbnail()
	showTime = true
	library: FireClip.Library;
	time: number;
	position: number;
	relative: boolean;
	absolute: boolean;
	thumbnail: FireClip.Thumbnail;
	lastSrc: string;
	lastTileNum: number;
	$doCheck() {
		if (this.thumbnail && (this.thumbnail.src != this.lastSrc || this.thumbnail.tileNum !== this.lastTileNum)) {
			this.setImageForTime()
			this.calcTime();
			this.lastSrc = this.thumbnail.src;
			this.lastTileNum = this.thumbnail.tileNum;
		}
	}
	constructor(private LibraryService: LibraryService, $element: angular.IRootElementService) {
		'ngInject';

		this.imageElement = <HTMLImageElement>$element.children()[0];
		this.showTime = true;
	}
	async setImageForTime() {
		if (this.library && typeof this.position !== undefined) {
			const canvas = await this.LibraryService.getImage(this.library, this.position * 1000, this.imageDownloadService);
			if (canvas) this.imageElement.src = canvas.toDataURL()
		} else {
			this.imageElement.src = ""
		}
	}
	calcTime() {
		if (this.time) {
			this.setAbsolute();
			this.dateTime = new Date(this.time)
		} else if (typeof this.position !== "undefined") {
			this.setRelative();
		}
	}
	setAbsolute() {
		this.absolute = true;
		this.relative = false;
	}
	setRelative() {
		this.absolute = false;
		this.relative = true;
	}
	$onInit() {
		if (this.showTime === undefined) this.showTime = true;
		this.calcTime();
	}
	$onChanges(changes) {
		if (changes.position || changes.time) {
			this.setImageForTime()
			this.calcTime();
		}
	}
}
const LibraryThumbnailComponent = {
	'fcLibraryThumbnail': {
		template: require('./libraryThumbnail.pug'),
		bindings: {
			time: '<',
			library: '<',
			position: '<',
			showTime: '<',
			thumbnail: '<'
		},
		controller: LibraryThumbnail,
	}
}

export { LibraryThumbnailComponent }