import * as  angular from 'angular';
import MediaService from '../media/media.service';
import exporters from '../workflow/index';
import exportItemController from '../exporter/exportItemController'

const defaultIcon = require('./defaultIcon.svg');

// function autoSelect(providers) {
//   if (providers && providers.length > 0)
//     return providers[0].id;
//   return null;
// }

class ExportService {
	workflows;
	constructor(private $mdDialog: angular.material.IDialogService, private Job, Workflow, private MediaService: MediaService) {
		'ngInject';
		this.workflows = Workflow.query();
	}
	getDefaultData(exporter: FireClip.Exporter, media: FireClip.Media, theExport: FireClip.Job) {
		const service = this.getDefaultWorkflowService(exporter.workflowCode);
		if (service && service.getDefaultData) {
			return service.getDefaultData(theExport, media)
		}
		return null;
	}
	async executeDefaultAction(exporter: FireClip.Exporter, media: FireClip.Media, theExport): Promise<string> {
		const service = this.getDefaultWorkflowService(exporter.workflowCode);
		if (service) {
			return service.defaultAction(theExport, media)
		}
		return "Este exportador no tiene acción rápida definida";
	}
	async resendJob(job: FireClip.Job): Promise<string> {
		try{
			const result = await this.Job.restart({ id: job.id }).$promise;
			return result.message;
		} catch (err) {
			return err.data.message;
		}
	}
	createExport(exporter: FireClip.Exporter, media: FireClip.Media): FireClip.Job {
		const workflowCode = exporter.workflowCode;
		let workflow = this.workflows.find(w => w.code == workflowCode);
		if (!workflow) throw new Error(`no workflow found for ${workflowCode}`)
		const job : FireClip.Job = {
			type: workflowCode,
			exporterId: exporter.id,
			status: 0,
			media: media.id,
			data: <any>{}
		};
		return job;
	}
	openExportDialog(exporter: FireClip.Exporter, media: FireClip.Media, library: FireClip.Library, job: FireClip.Job, targetEvent) {
		if (!job) {
			job = this.createExport(exporter, media);
		}
		return this.$mdDialog.show({
			controller: exportItemController,
			targetEvent,
			locals: {
				exporter,
				workflowCode: exporter.workflowCode,
				media,
				library,
				job,
			},
			controllerAs: '$ctrl',
			template: require('./dialogExport.pug'),
			parent: angular.element(document.body)
		})
	}
	export(job) {
		let promise = null;
		if (job.id) {
			job.status = 0;
			job.progress = 0;
			promise = this.Job.save({
				id: job.id
			}, job).$promise
		} else {
			promise = this.Job.save(job).$promise;
		}
		return promise.then(function (newJob) {
			return newJob;
		}).catch(function (error) {
			console.error(error)
			let message = 'Error desconocido al exportar';
			if (error) {
				message = "Error al exportar: ";
				if (error.data) {
					if (error.data.errors && error.data.errors.length > 0) {
						message += ': ' + error.data.errors.join(',');
					}
					if (error.data.message) message += error.data.message;
					if (error.data.constructor == String) message += error.data
				}
			}
			return Promise.reject(message);
		});
	}
	getWorkflow(type) : FireClip.workflow{
		return this.workflows.find(w => w.code == type);
	}
	getJobName(type) {
		const found = this.workflows.find(w => w.code == type);
		return found ? found.name : '';
	}
	getJobResult(job) {
		let result;
		if (job.status == 3) {
			if (job.result) {
				if (job.result.url)
					result = job.result.url;
				else
					result = job.result;
			}
		}
		return result;
	}
	removeJob(job, media) {
		if (!job.id) return Promise.reject(new Error('no job id'));
		if (!media) return Promise.reject(new Error('no media'));
		return this.Job
			.remove({ id: job.id })
			.$promise
			.then(() => this.MediaService.removeJob(media, job))
	}
	getDefaultWorkflowImage(workflowCode): string {
		const exporter = exporters.find(e => e.code === workflowCode);
		if (exporter) {
			return exporter.image;
		}
		return defaultIcon;
	}

	getDefaultWorkflowService(workflowCode) {
		const exporter = exporters.find(e => e.code === workflowCode);
		if (exporter) {
			return exporter.service;
		}
		return null;
	}
}

angular.module('fireclip').service('ExportService', ExportService);

export default ExportService