import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { EditorLib } from "./editorLib";
import { distinctUntilChanged, map, share, auditTime } from "rxjs/operators";
import { v4 } from 'uuid'


class EditorClip {
	id: string;
	clipConflictError = false;
	libraryConflictError = false;
	type: "clip" | "fragment" | "bumper" = 'clip';

	start$: BehaviorSubject<number>
	private startLib$ = new BehaviorSubject<EditorLib>(null)
	getStartSrc: (time: number) => Promise<HTMLCanvasElement>;
	startSrc$: Observable<string>;
	startSrc: string;

	end$: BehaviorSubject<number>
	private endLib$ = new BehaviorSubject<EditorLib>(null)
	getEndSrc: (time: number) => Promise<HTMLCanvasElement>;
	endSrc$: Observable<string>;
	endSrc: string;
	title: string;

	get errored() { return this.clipConflictError || this.libraryConflictError }

	set startLib(newStartLib: EditorLib) {
		// console.log('s', newStartLib?.id, this.startLib?.id)
		if (this.startLib !== newStartLib) {
			if (newStartLib?.getImageCanvas) {
				const canvasFunction = newStartLib.getImageCanvas();
				this.getStartSrc = (time: number) => {
					return canvasFunction(time - newStartLib.start)
				}
				this.libraryConflictError = this.endLib !== newStartLib;
			} else {
				this.libraryConflictError = true;
				this.getStartSrc = null
			}
			this.startLib$.next(newStartLib);
		}
	}
	get startLib() { return this.startLib$.getValue() }

	set endLib(newEndLib) {
		// console.log('e', newEndLib?.id, this.endLib?.id)
		if (this.endLib !== newEndLib) {
			if (newEndLib?.getImageCanvas) {
				const canvasFunction = newEndLib.getImageCanvas();
				this.getEndSrc = (time: number) => {
					return canvasFunction(time - newEndLib.start)
				}
				this.libraryConflictError = this.startLib !== newEndLib;
			} else {
				this.libraryConflictError = true;
				this.getEndSrc = null
			}
			this.endLib$.next(newEndLib);
		}
	}
	get endLib() { return this.endLib$.getValue() }

	duration$: BehaviorSubject<number>
	selected$: BehaviorSubject<boolean>
	relativePosition: number;
	constructor(start: number, duration: number, selected: boolean) {
		this.id = v4();
		this.title = `ID# ${this.id.substring(this.id.length - 5)}`;
		this.start$ = new BehaviorSubject(start);
		this.end$ = new BehaviorSubject(start + duration);
		this.duration$ = new BehaviorSubject(duration);
		this.selected$ = new BehaviorSubject(selected);
		this.startSrc$ = new Observable<string>((a) => {
			// console.log('subsribed to thumbnail start changes')
			const s = combineLatest(this.start$.pipe(auditTime(100)), this.startLib$).subscribe({
				complete: () => {
					// console.log('complete start src oberver')
					a.complete()
				},
				next: async ([start, _lib]) => {
					// console.log('next in start')
					if (this.getStartSrc) {
						this.startSrc = (await this.getStartSrc(start))?.toDataURL()
					} else {
						this.startSrc = 'data:image/png;base64,';
					}
					a.next(this.startSrc)
				}
			})
			return () => {
				s.unsubscribe();
			}
		}).pipe(share())
		this.endSrc$ = new Observable<string>((a) => {
			// console.log('subsribed to thumbnail end changes')
			const s = combineLatest(this.end$.pipe(auditTime(100)), this.endLib$)
				.subscribe({
					complete: () => {
						a.complete()
					},
					next: async ([end, _lib]) => {
						// console.log('next in end')
						this.endSrc = 'data:image/png;base64,';
						if (this.getEndSrc) {
							this.endSrc = (await this.getEndSrc(end))?.toDataURL()
						} else {
							this.endSrc = 'data:image/png;base64,';
						}
						// console.log('next in end!')
						a.next(this.endSrc)
					}
				})
			return () => {
				s.unsubscribe();
				// console.log('unsubscribe to end')
			}
		}).pipe(share())


		combineLatest(this.start$, this.end$)
			.pipe(map(a => a[1] - a[0]), distinctUntilChanged(d => d !== this.duration))
			.subscribe((d) => {
				this.duration$.next(d)
			})
	}
	get duration() {
		return this.duration$.getValue()
	}
	get start() {
		return this.start$.getValue()
	}
	set start(value) {
		if (this.start != value) {
			this.start$.next(value)
		}
	}
	get selected() {
		return this.selected$.getValue()
	}
	set selected(value) {
		if (this.selected !== value) {
			this.selected$.next(value);
		}
	}
	get end() {
		return this.end$.getValue();
	}
	set end(value) {
		if (this.end !== value) {
			this.end$.next(value)
		}
	}

	collides(a: EditorClip) {
		return (this.start <= a.start) && (this.end > a.start) || ((this.start >= a.start) && (this.end <= a.end)) || (this.start < a.end) && (this.end >= a.end)
	}

	destroy() {
		this.selected = false;
		this.start$.complete();
		this.end$.complete();
		this.duration$.complete();
		this.selected$.complete();
		this.startLib$.complete();
		this.endLib$.complete();
	}
}
export { EditorClip }