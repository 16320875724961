export function logTimes(times: number[]) {
  console.log(times.map(t => new Date(t).toLocaleTimeString()).join('\t'))
}
export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// function formatValue(value, leftValue) {
//   if (value < 10 && leftValue > 0) {
//     return `0${value}`;
//   }
//   return value;
// }

export function formatTime(hours: number, minutes: number, seconds: number, ms: number = null) {
  let text = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  if (ms !== null) return text + `.${ms}`;
  return text
}

export function clipTextSeconds(duration: number, shrink = true) {
  const date = new Date(duration);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  if (shrink) {
    let text = '';
    if (hours > 0) { text += `${hours.toString().padStart(2, '0')}:`; }
    if (text || minutes > 0) { text += `${minutes.toString().padStart(2, '0')}:`; }
    if (text || seconds > 0) { text += seconds.toString().padStart(2, '0') } // + '.' + secs.getUTCMilliseconds();
    return text;
  } else {
    return formatTime(hours, minutes, seconds)
  }
}

export function formatDurationTotime(duration: number) {
  const dInSecs = duration / 1000;
  const hours = Math.floor(dInSecs / 3600)
  const minutes = Math.floor(dInSecs % 3600 / 60)
  const seconds = dInSecs % 60
  // const ms = duration % 1000 / 1000
  return formatTime(hours, minutes, seconds)
}
export function formatDateToTime(date: Date) {
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return formatTime(hour, minute, second)
}
