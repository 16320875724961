import * as angular from 'angular';

const template = require('./index.pug')
const image = require('./twitter.svg');
class Controller implements angular.IComponentController {
  providers: { twitter: any };
  exporter: any;
  job: any;
  media: any;
  result: any;
  constructor() {
    "ngInject";
  }
  async $onInit() {
    if (this.job.status === 0) {
      this.job.data.status = this.media.title;
    } else if (this.job.status == 3) {
      this.job.data.status = this.media.title
    }
  }

}
angular.module('fireclip').component('exporterTwitter', {
  template,
  controller: Controller,
  bindings: {
    job: '<',
    media: '<',
    exporter: '<',
  }
});

export default {
  code: 'twitter',
  template,
  controller: Controller,
  image,
	service: null,
}