import * as angular from 'angular';
const template = require('./template.pug')
const name = 'fcProviderSelector';

import Auth0Service from "../../auth/auth0.service";
import uiActions from "../../auth/uiActions";
import SocialProviderService from '../../provider/socialProviderService';
import ShowCustomToast from "../customToast";

class Controller implements angular.IController {
	addRemoveButtonsVisibility: boolean = false

	exporterId: string;
	channelId: string;
	type: string;

	providerId: string
	label: string;
	ngModel: angular.INgModelController;
	providers: any[];

	constructor(private $mdToast, private SocialProviderService: SocialProviderService, Auth0Service: Auth0Service) {
		'ngInject';

		this.addRemoveButtonsVisibility = Auth0Service.profileHasUIAction(uiActions.updateExporter)
	}

	async $onInit() {
		this.ngModel.$render = () => {
			this.providerId = this.ngModel.$viewValue;
		}
		this.label = this.SocialProviderService.getLabel(this.type)
		await this.loadProviders()
		this.providerId = this.providers[0]?.id
		this.onChange()
	}

	async removeAccount(ev) {
		try {
			await this.SocialProviderService.RemoveAccountFromExporter(ev, this.type, this.channelId, this.providerId, this.exporterId)
			await this.loadProviders()

			this.providerId = this.providers[0]?.id
			this.onChange()

			ShowCustomToast(this.$mdToast, "success", "Cuenta removida exitosamente")
		} catch (error) {
			ShowCustomToast(this.$mdToast, "error", `Error removiendo cuenta: ${error.message}`)
		}
	}

	async addAccount(ev) {
		try {
			const created = await this.SocialProviderService.AddAccountToExporter(ev, this.type, this.channelId, this.exporterId)

			if (!created) { return }

			const newProv = await this.getNewProviders()

			this.providerId = newProv[0]?.id
			this.onChange()

			ShowCustomToast(this.$mdToast, "success", "Cuenta agregada exitosamente")
		} catch (error) {
			ShowCustomToast(this.$mdToast, "error", `Error agregando cuenta: ${error.message}`)
		}
	}

	onChange() {
		this.ngModel.$setViewValue(this.providerId)
	}

	async loadProviders() {
		this.providers = await this.SocialProviderService.getProvidersById(this.channelId, this.exporterId, this.type)
	}

	async getNewProviders() {
		const tempProvidersIds = this.providers.map(x => x.id)
		await this.loadProviders()
		return this.providers.filter(x => !tempProvidersIds.includes(x.id))
	}

	labelSelectedOption(provider) {
		if (!provider) { return }
		switch (this.type) {
			case 'twitter':
			case 'google':
			case 'facebook':
				return provider.data.displayName
			default:
				return provider.identifier
		}
	}

}

angular.module('fireclip').component(name, {
	require: {
		ngModel: '^ngModel'
	},
	template,
	bindings: {
		type: '<',
		channelId: '<',
		exporterId: '<'
	},
	controller: Controller
})
export { name as fcProviderSelector }
