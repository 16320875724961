import app from '../../app'
import { IComponentController } from 'angular';
import { homeController } from '../home/home.component';

const name = 'fcManagerHeader';
require('../upload/upload.service')
app.component(name, {
	controller: class a implements IComponentController {
		home: homeController
		constructor() {
			'ngInject';
		}
	},
	template: require('./manager.header.pug'),
	require: {
		home: '^^fcHome'
	}
})
export { name as fcManagerHeaderComponent }