import { BehaviorSubject, Subscription, merge, combineLatest } from 'rxjs';
import { auditTime, map, distinctUntilChanged } from 'rxjs/operators'
import { EventEmitter } from 'events';
import MacroScroll from './macroScroll';
import Konva from 'konva';
import MiniScroll from './miniScroll';
import { clipTextSeconds } from './helpers';


class SmallClip extends EventEmitter {
	id: string
	selectionBigBarText: any;
	clipBox: Konva.Rect;
	leftFocusBar: Konva.Line;
	rightFocusBar: Konva.Line;
	hoverOpacity = 0.9;
	normalOpacity = 0.6;
	selectedOpacity = 1;
	leftChange: (val: { start: Date; end: Date; }) => void;
	rightChange: (val: { start: Date; end: Date; }) => void;
	length: number;
	clipers: Konva.Group
	subscription1: Subscription;
	subscription3: Subscription;

	constructor(private clip: Clip, private timeLine: MiniScroll, group) {
		super();
		this.id = clip.id;
		const cliperTotalHeight = group.getHeight();
		this.length = 0;
		this.clipBox = new Konva.Rect({
			height: cliperTotalHeight,
			fill: clip.color,
			opacity: this.normalOpacity,
			name: 'clip',
		});
		this.selectionBigBarText = new Konva.Text({
			x: 0,
			y: 10,
			width: 10,
			height: cliperTotalHeight,
			text: clipTextSeconds(this.clip.duration),
			fontSize: 10,
			fontFamily: 'Roboto Mono',
			fill: '#000',
			align: 'center',
			listening: false,
		});

		function rightDragBoundFunc(pos) {
			const newPos = {
				y: this.rightFocusBar.getAbsolutePosition().y,
				x: pos.x,
			};
			if (newPos.x - 5 < this.leftFocusBar.getAbsolutePosition().x) {
				newPos.x = this.leftFocusBar.getAbsolutePosition().x + 5;
			} else if (newPos.x > this.timeLine.width) {
				newPos.x = this.timeLine.width;
			}
			return newPos;
		}
		this.rightFocusBar = new Konva.Line({
			name: 'rightFocusBar',
			fill: clip.color,
			closed: true,
			stroke: '#303030',
			strokeWidth: 1,
			points: [
				0, 0,
				0, cliperTotalHeight,
				-(3 - 10), cliperTotalHeight,
				10, cliperTotalHeight - 3,
				10, 3,
				-(3 - 10), 0
			],
			draggable: true,
			dragBoundFunc: rightDragBoundFunc.bind(this),
		})
		function leftDragBoundFunc(pos) {
			const newPos = {
				y: this.leftFocusBar.getAbsolutePosition().y,
				x: pos.x,
			};
			if (newPos.x < 10) {
				newPos.x = 10;
			} else if (newPos.x > this.rightFocusBar.getAbsolutePosition().x - 5) {
				newPos.x = this.rightFocusBar.getAbsolutePosition().x - 5;
			}
			return newPos;
		}
		this.leftFocusBar = new Konva.Line({
			name: 'leftFocusBar',
			fill: this.clip.color,
			closed: true,
			stroke: '#303030',
			strokeWidth: 1,
			points: [
				0, 0,
				0, cliperTotalHeight,
				3 - 10, cliperTotalHeight,
				-10, cliperTotalHeight - 3,
				-10, 3,
				3 - 10, 0
			],
			draggable: true,
			dragBoundFunc: leftDragBoundFunc.bind(this),
		})


		// this.rightFocusBar.setOffsetX(this.rightFocusBar.getClientRect().width);
		// this.leftFocusBar.setOffsetX(this.leftFocusBar.getClientRect().width);
		this.rightFocusBar.on('dragmove', this.rightFocusBarDrag.bind(this));
		this.rightFocusBar.on('dragend', dragEnd.bind(this));
		this.rightFocusBar.on('mouseover', showPointer);
		this.rightFocusBar.on('mouseout', hidePointer);

		this.leftFocusBar.on('dragmove', this.leftFocusBarDrag.bind(this));
		this.leftFocusBar.on('dragend', dragEnd.bind(this));
		this.leftFocusBar.on('mouseover', showPointer);
		this.leftFocusBar.on('mouseout', hidePointer);

		this.clipBox.on('mouseout', () => {
			this.clipBox.opacity(this.clip.selected ? this.selectedOpacity : this.normalOpacity);
		});
		this.clipBox.on('mouseenter', () => {
			this.clipBox.opacity(this.clip.selected ? this.selectedOpacity : this.hoverOpacity);
		});
		this.clipBox.on('click', (data) => {
			this.clip.selected = !data.evt.shiftKey;
			// console.log(data, '<<<')
		});

		function showPointer() {
			// e-resize, ew-resize, nw ne ns etc.. use cordinates NSEW
			document.body.style.cursor = 'pointer';
		}

		function hidePointer() {
			document.body.style.cursor = 'default';
		}
		function dragEnd(e) {
			if (e.target.getName() == 'leftFocusBar') {
				this.clip.start = this.timeLine.positionToTime(e.target.attrs.x);
				this.emit('leftChangeEnd', this.clip);
			} else if (e.target.getName() == 'rightFocusBar') {
				this.clip.end = this.timeLine.positionToTime(e.target.attrs.x);
				this.emit('rightChangeEnd', this.clip);
			}
		}

		this.clipers = new Konva.Group({});
		this.clipers.add(this.rightFocusBar);
		this.clipers.add(this.leftFocusBar);
		this.clipers.add(this.clipBox);
		this.clipers.add(this.selectionBigBarText);
		// console.log('calc1')
		this.calc()
		this.calcSelected()
		group.add(this.clipers);
		/******* after build UI */
		merge(clip.start$, clip.end$)
			.pipe(auditTime(100))
			.subscribe(() => {
				// console.log('calc2')
				this.calc()
				this.timeLine.layer.batchDraw()
			})
		this.subscription3 = clip.selected$.subscribe(() => {
			this.calcSelected()
			this.timeLine.layer.batchDraw()
		})
	}

	private leftFocusBarDrag(e) {
		this.clip.start = this.timeLine.positionToTime(e.target.attrs.x);
		this.clipBox.x(e.target.attrs.x);
		this.clipBox.width(this.rightFocusBar.x() - e.target.attrs.x);
		this.selectionBigBarText.setX(e.target.attrs.x);
		this.selectionBigBarText.setWidth(this.rightFocusBar.x() - e.target.attrs.x);
		this.selectionBigBarText.setText(clipTextSeconds(this.clip.duration));
	}

	private rightFocusBarDrag(e) {
		this.clip.end = this.timeLine.positionToTime(e.target.attrs.x);
		this.clipBox.width(e.target.attrs.x - this.leftFocusBar.x());
		this.selectionBigBarText.setWidth(e.target.attrs.x - this.leftFocusBar.x());
		this.selectionBigBarText.setText(clipTextSeconds(this.clip.duration));
	}

	calcSelected() {
		if (this.clip.selected) {
			this.leftFocusBar.visible(true);
			this.rightFocusBar.visible(true);
			this.clipBox.fill(this.clip.color);
			this.clipBox.opacity(1);
			this.clipers.moveToTop();
		} else {
			this.leftFocusBar.visible(false);
			this.rightFocusBar.visible(false);
			this.clipBox.fill(this.clip.color);
			this.clipBox.opacity(0.8);
		}
	}
	calc() {
		if (this.clip.start === null || this.clip.end === null) return
		const posLeft = Math.round(this.timeLine.timeToX(this.clip.start));
		const posRight = Math.round(this.timeLine.timeToX(this.clip.end));
		this.clipBox.x(posLeft);
		this.selectionBigBarText.setX(posLeft);
		this.leftFocusBar.x(posLeft);
		this.rightFocusBar.x(posRight);
		this.clipBox.width(posRight - posLeft);
		this.selectionBigBarText.setText(clipTextSeconds(this.clip.duration));
		this.selectionBigBarText.setWidth(posRight - posLeft);
	}
	destroy() {
		this.clipers.destroy();
		this.subscription1.unsubscribe();
		this.subscription3.unsubscribe();
	}
}

class BigClip {
	macroClip: Konva.Rect;
	constructor(public clip: Clip, private macroScroll: MacroScroll) {
		this.macroClip = new Konva.Rect({
			x: 0,
			y: 15,
			height: 10,
			width: 20,
			fill: '#FF8000',
			opacity: 1,
		});
		merge(clip.start$, clip.end$).subscribe(() => this.calc())
	}
	calc() {
		if (this.clip.start === null || this.clip.end === null) return
		const xStart = this.macroScroll.timeToPosition(this.clip.start);
		const xEnd = this.macroScroll.timeToPosition(this.clip.end);
		let w = xEnd - xStart;
		if (w < 2) w = 2;
		this.macroClip.x(xStart);
		this.macroClip.width(w)
		this.macroScroll.layer.batchDraw();
	}
	destroy() {
		this.macroClip.destroy();
	}
}
class Clip {
	public start$: BehaviorSubject<number>
	public end$: BehaviorSubject<number>;
	public duration$: BehaviorSubject<number>
	public type$: BehaviorSubject<string>
	public selected$: BehaviorSubject<boolean>
	public color: string;

	constructor(public id: string, start: number = null, end: number = null, type: 'fragment' | 'clip' | 'bumper', selected: boolean = false) {
		this.start$ = new BehaviorSubject(start);
		this.end$ = new BehaviorSubject(end);
		this.duration$ = new BehaviorSubject(end - start);
		this.type$ = new BehaviorSubject<string>(type);
		this.selected$ = new BehaviorSubject<boolean>(selected)
		combineLatest(this.start$, this.end$)
			.pipe(map(a => a[1] - a[0]), distinctUntilChanged(d => d !== this.duration))
			.subscribe((d) => this.duration$.next(d))
		if (type === 'clip') {
			this.color = '#FF9800'
		} else if (type === 'fragment') {
			this.color = '#F44336'
		}
		//IDEA: no crear el objeto conva si es que está fuera de rango, así sé que debo o no debo dibujar
	}

	set selected(value) {
		if (this.selected !== value) this.selected$.next(value)
	}
	get selected(): boolean {
		return this.selected$.getValue()
	}

	set start(value: number) {
		if (this.start$.getValue() !== value) this.start$.next(value);
	}
	get start(): number {
		return this.start$.getValue();
	}

	get duration() {
		return this.duration$.getValue()
	}

	get end(): number {
		return this.end$.getValue();
	}

	set end(value) {
		if (this.end !== value) this.end$.next(value);
	}

	delete() {
		this.start$.complete();
		this.end$.complete();
		this.duration$.complete();
		this.type$.complete();
		this.selected$.complete();
	}

}
export { SmallClip, Clip, BigClip };
