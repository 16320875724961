import { join } from 'path';

export function mediaURL(path) {
	const videoURL = new URL(theConfig.media_storage_url);
	videoURL.pathname = join(videoURL.pathname, path);
	return videoURL.toString();
}
export function copyToClipboard(text: string) {
	return navigator.clipboard.writeText(text)
}

const regex = /(.*)(%(\d+)d)(.*)/;
export function resolveThumbnailTemplate(template, spriteNumber) {
	const matchResult = template.match(regex);
	let padding = 3
	if (matchResult) {
		padding = parseInt(matchResult[3], 10);
		return template.replace(matchResult[2], spriteNumber.toString().padStart(padding, '0'));
	}
	// How should we handle errors here?
	return template;
}


export function getUserLocalSetting(userId: string, setting: string) {
	let valueToReturn;
	let userSettings = {};
	const userItemKey = `_U_${userId}`;

	if (localStorage[userItemKey]) {
		try {
			userSettings = JSON.parse(localStorage[userItemKey])
		} catch {
			console.warn && console.warn('LOCAL USER SETTINGS ARE CORRUPTED')
			userSettings = {}
		}
		if (userSettings[setting]) {
			valueToReturn = userSettings[setting]
		}
	}

	return valueToReturn
}

export function setUserLocalSetting(userId: string, setting: string, value: any) {
	let userSettings = {}
	const userItemKey = `_U_${userId}`

	if (localStorage[userItemKey]) {
		try {
			userSettings = JSON.parse(localStorage[userItemKey])
		} catch {
			console.warn && console.warn('LOCAL USER SETTINGS ARE CORRUPTED AND WILL BE RE WRITTEN')
			userSettings = {}
		}
	}
	userSettings[setting] = value
	localStorage[userItemKey] = JSON.stringify(userSettings)
	return value
}

export function cleanLocalStorage(options: cleanLocalStorageOptions) {
	let keepItem = false
	if (options.keep && options.keep.length > 0) {
		for (let i in localStorage) {
			keepItem = false
			for (let j = 0; j < options.keep.length; j++) {
				if (options.keep[j] === i) {
					keepItem = true
				}
			}
			if (!keepItem) {
				localStorage.removeItem(i)
			}
		}

	} else {
		for (let i in localStorage) {
			localStorage.removeItem(i)
		}
	}
}