// import { isReturnStatement } from 'typescript';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import Player from './player';
// require('video.js/src/css/vjs.scss')
require('!style-loader!css-loader!video.js/dist/video-js.css');

// require('../providers/vjs-hls');
// const videojsHlsjsSourceHandler = require('videojs-hlsjs-plugin');
// videojsHlsjsSourceHandler.register(videojs);

videojs.log('debug')
class VideoJSWrapper extends Player {
	playerInstance: VideoJsPlayer

	hlsLoadStopped: boolean

	playbackAvailable: any

	wantedPosition: any

	playableSegmentFinding: any

	eventHandlersReady: boolean

	constructor(element: HTMLElement, tech: string, debug: boolean) {
		super(element, tech, debug);
		element.className = 'video-js vjs-default-skin vjs-fill';
		const options: VideoJsPlayerOptions = {
			autoplay: true,
		};
		this.playerInstance = videojs(this.element, options);
		this.playerInstance.on('timeupdate', (data) => this.emit('timeupdate', data))
		this.playerInstance.on('play', (data) => this.emit('play', data))
		this.playerInstance.on('pause', (data) => this.emit('pause', data))
		this.playerInstance.on('volumechange', () => {
			if (this.playerInstance.volume() == 0) {
				this.emit('mute', { mute: true })
			}
		})
	}

	setup(file: string, start) {
		this.playerInstance.src(file);
		this.playerInstance.currentTime(start);
		this.playerReady();
	}

	position() {
		return this.playerInstance.currentTime();
	}

	duration() {
		return this.playerInstance.duration();
	}

	seek(pos: number) {
		this.playerInstance.currentTime(pos);
	}

	play() {
		return this.playerInstance.play();
	}

	pause() {
		this.playerInstance.pause();
	}

	mute(val: boolean) {
		return this.playerInstance.muted(val);
	}

	volume(vol: number) {
		if (vol > 0) {
			this.playerInstance.volume(vol / 100);
		} else {
			this.playerInstance.volume(vol);
		}
		return this.playerInstance.volume() * 100
	}

	isPlaying() {
		return !this.playerInstance.paused();
	}

	dispose() {
		if (this.playerInstance) { this.playerInstance.dispose(); }
	}

	reset() {
		this.playerInstance.reset()
	}
}
export default VideoJSWrapper;
