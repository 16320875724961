/* eslint-disable indent */
import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export function rangeValidator(min = null, max=null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
		if (value===null) {
			return null;
		}
    if (min && (value < min)) return { range: `El valor debe ser mayor o igual a ${min}` };
		if (max && (value > max)) return { range: `El valor debe ser menor o igual a ${max}` };
		return null;
  };
}

@Directive({
	selector: '[rangeValidator]',
	providers: [{ provide: NG_VALIDATORS, useExisting: RangeValidatorDirective, multi: true }]
})
export class RangeValidatorDirective implements Validator {
  @Input() min? = null;
  @Input() max? = null;

  validate(control: AbstractControl): ValidationErrors | null {
		
    return rangeValidator(this.min, this.max)(control);
  }
}