import { TransitionService } from "@uirouter/core";

export default function run($state, $transitions: TransitionService, $trace) {
	'ngInject';
	$trace.disable();
	$transitions.onError({
		from: '*'
	}, (transition) => {
		const error = transition.error();
		if (error.detail?.message === 'no auth') {
			$state.go('login');
		} else if (error.detail?.message === 'Not authorized') {
			$state.go('main')
		}
	})

}
