import * as angular from 'angular';
import Component from './component';

require('./main.styl');

const name = 'angular-video-player';
angular.module(name, []);

angular.module(name).component('videoPlayer', {
	bindings: {
		id: '@',
		setupVars: '=',
		player: '<',
		playerTech: '<',
		debug: '<',
		onInit: '&',
	},
	controller: Component,
});

export default name;
