import app from '../../app';
import { IComponentController } from "angular";
import * as angular from 'angular';
import ExportService from '../exporter/exportService';
import { LibraryService } from './library.service';
import { LibraryListController } from './library.list.component';
import './library.item.styl';
import { Channel } from '../channel/channelService';
const path = require('path')
const name = 'fcLibraryItem';

class controller implements IComponentController {
	channel: Channel;
	recording: FireClip.Recording;
	library: any;
	LibraryListController: LibraryListController
	constructor(private $mdDialog: angular.material.IDialogService, private ExportService: ExportService, private LibraryService: LibraryService) {
		'ngInject';
	}
	openJob(theExport, library, event) {
		if (!theExport.exporterId) alert('error, no exporterId')
		const exporter = this.channel.exporters.find(e => e.id === theExport.exporterId)
		this.ExportService.openExportDialog(exporter, null, library, theExport, event);
	}
	openLibrary() {
		this.LibraryListController.showMediaDialog(this.library)
	}
	editLibrary(event) {
		var confirm = this.$mdDialog.prompt()
			.title('Editar')
			.textContent('Cambiar título.')
			.placeholder('Título')
			.initialValue(this.library.title)
			.targetEvent(event)
			.required(true)
			.ok('Guardar')
			.cancel('Cancelar');

		this.$mdDialog.show(confirm).then((title) => {
			this.LibraryService.save({ id: this.library.id, title })
				.then(() => {
					this.library.title = title;
				})
				.catch((error) => alert(error.message))
		});
	}
	libraryDownload(library: FireClip.Library) {
		let m = new URL(theConfig.library_storage_url);
		m.pathname = path.join(m.pathname, library.path, library.filePrefix + library.ext);
		return m.href
	}
}
app.component(name, {
	bindings: {
		library: '<',
		channel: '<',
		recording: '<',
	},
	require: {
		LibraryListController: '^^fcLibraryList'
	},
	controller: controller,
	template: require('./library.item.pug')
})

export { name as fcLibraryListComponent };