import * as angular from "angular";
import fireclipApp from "../../app";
import { Observable } from "rxjs";
import { getTileImageAsCanvas } from "../../utils";
import Thumbnail from "../../timeline/thumbnail";
import { share } from "rxjs/operators";
const path = require('path')

function transformResponse(rawData, _headers, status) {
	if (status !== 200) return rawData;
	const libs = angular.fromJson(rawData);
	if (libs.constructor === Array) {
		libs.forEach(transformRawLib);
	} else {
		transformRawLib(libs);
	}
	return libs;
}
export function transformRawLib(lib) {
	if (lib.start) lib.start = new Date(lib.start);
	if (lib.end) lib.end = new Date(lib.end);
	switch (typeof lib.recording) {
		case 'string':
			lib.recordingId = lib.recording;
			break;
		case 'object':
			lib.recordingId = lib.recording?.id;
			break;
		default:
			lib.recordingId = null;
			break;
	}
}

function LibraryResource($resource: angular.resource.IResourceService, API_URL) {
	'ngInject';

	return $resource(`${API_URL}library/:id`, { id: '@id' }, {
		get: {
			method: 'GET',
			transformResponse,
		},
		query: {
			url: `${API_URL}library/`,
			method: 'GET',
			isArray: true,
			transformResponse,
		},
	});
}

export class Library {
	title: string
	startSrc$: Observable<string>;
	createdAt: Date;
	id: string;
	duration: number;
	thumbnails: FireClip.ThumbnailParams;
	path: string;
	filePrefix: string;
	constructor(public dbLibrary: FireClip.Library) {
		this.id = dbLibrary.id;
		this.title = dbLibrary.title
		this.createdAt = new Date(dbLibrary.createdAt)
		this.duration = dbLibrary.duration
		this.thumbnails = dbLibrary.thumbnailsV2
		this.path = dbLibrary.path
		this.filePrefix = dbLibrary.filePrefix;
		this.startSrc$ = new Observable<string>((a) => {
			// console.log('get image for lib', this.id)
			this.getImage(0, new Thumbnail()).then(dataUrl => {
				a.next(dataUrl.toDataURL());
			})
			return () => { }
		}).pipe(share())
	}
	async getImage(time: number, imageService: Thumbnail) {
		try {
			const secsFromStart = Math.floor(time / 1000);
			const spriteNum = Math.floor(secsFromStart / (this.thumbnails.verticalCount * this.thumbnails.horizontalCount) + 1);
			const newUrl = theConfig.library_storage_url + path.join('/', this.path, this.thumbnails.path || 'thumbnails_v2', `${this.filePrefix}_${spriteNum.toString().padStart(3, '0')}.jpg`);
			const image = await imageService.getImage(newUrl);
			const tileNum = Math.floor((time / 1000)) % (this.thumbnails.verticalCount * this.thumbnails.horizontalCount);
			if (image) {
				const canvas = getTileImageAsCanvas(image, this.thumbnails.horizontalCount, this.thumbnails.verticalCount, tileNum)
				return canvas;
			} else {
				return null
			}
		} catch (error) {
			const canvas = document.createElement('canvas');
			//168x126 = 4:3, 224x126 =  16:9// pixeles cuadrados
			canvas.width = 160; //160
			canvas.height = 120; //90
			canvas.getContext('2d').fillStyle = 'red';
			canvas.getContext('2d').fillRect(0, 0, 160, 120);
			return canvas;
		}
	}
}
fireclipApp.factory('Library', LibraryResource);