import app from '../../app'
import { StateService } from '@uirouter/angularjs';
import { Channel, Input } from '../channel/channelService';
import socketService from '../socketService';
import { concatAll, debounceTime, delay, map, take } from 'rxjs/operators';
import angular from 'angular';
import { from, Observable } from 'rxjs';
import Auth0Service from "../auth/auth0.service";
import uiActions from "../auth/uiActions";
import RecordingService from '../recording/recording.service';

const name = 'fcLiveHeader';
class LiveHeaderController implements IComponentControllerUIRouter {
	channel: Channel
	input: Input
	recordingState: string;
	monitorState: string;
	iconColor: string;
	icon: string;
	ffmpegErrorLog: string[];
	canRestart: boolean;
	showIngestRestartButton: boolean = false
	showStatus = false;
	restartObservable: Observable<void>;

	tokenPermissons: string[]

	constructor(private $state: StateService, private $mdDialog: angular.material.IDialogService, private $scope, private $mdToast:angular.material.IToastService, private RecordingService: RecordingService, Auth0Service: Auth0Service) {
		'ngInject';
		
		this.showIngestRestartButton = Auth0Service.profileHasUIAction(uiActions.rebootIngest);
	}

	$onInit() {
		socketService.onRecordingState((state: FireClip.RecordingState) => { 
			this.showStatus = false;
			if (state.recordingState === 'RECORDING') {
				this.recordingState = 'Grabando';
				this.iconColor = 'green';
				this.icon = 'check_circle';
				this.showStatus = true;
			} else if (state.recordingState === 'RUNNING') {
				if (state.errorLog.length > 0) {
					this.recordingState = state.errorLog[0];
					this.iconColor = 'yellow';
					this.icon = 'warning';
				} else {
					this.recordingState = `No grabando / Error.`;
					this.iconColor = 'red';
					this.icon = 'error';
				}
				this.showStatus = true;
			} else {
				if (this.input.recordingEnabled){
					if (state.monitorState === 'conciliating' && state.recordingState === 'STOPPED') {
						this.recordingState = 'Iniciando';
						this.showStatus = true;
					} else {
						this.recordingState = 'Desconocido / Error';
						this.showStatus = true;
						this.iconColor = 'red';
						this.icon = 'error';
					}
				} else {
					this.recordingState = 'Ingesta inactiva';
					this.showStatus = true;
				}
				this.iconColor = 'black';
				this.icon = 'error';
			}
			this.monitorState = state.monitorState;
			this.ffmpegErrorLog = state.errorLog;
			this.canRestart = true;
			this.restartObservable = new Observable<void>(subscriber => {
				subscriber.next();
			}).pipe(
				debounceTime(250), 
				take(1),
				map(() => from(this.doRestart())),
				delay(2000),
				concatAll()
			);
			this.$scope.$digest();
		});
	}
	async doRestart(){
		const result = await this.RecordingService.restart(this.input.recordingId, this.channel.id);
		if (result.error) {
			this.$mdToast.show(this.$mdToast.simple().textContent('Error al reiniciar la ingesta').position('top right'));
		} else {
			this.$mdToast.show(this.$mdToast.simple().textContent('Ingesta reiniciada').position('top right'));
		}
	}

	restart(event){
		this.$mdDialog.show(
			this.$mdDialog.confirm()
				.title('Reinicio de ingesta')
				.textContent(`¿Seguro que desea reiniciar la ingesta ${this.input.name}? Esto afectara a todos los usuarios que esten usando esta grabación.`)
				.ok('Si')
				.cancel('Cancelar')
				.targetEvent(event),
		)
		.then(async () => {
			this.canRestart = false;
			this.$mdToast.show(this.$mdToast.simple().textContent('Reiniciando ingesta...').position('top right'));
			this.restartObservable.subscribe(()=>{
				this.canRestart = true;
				this.$scope.$digest();
			});
		});
	}

	uiOnParamsChanged(newParams) {
		this.input = this.channel.inputs.find(i => i.id === newParams.input)
	}
	goToInput() {
		this.$state.go('.', { input: this.input.id });
	}

}
app.component(name, {
	template: require('./live.header.pug'),
	controller: LiveHeaderController,
	bindings: {
		channel: '<',
		input: '<',
	}
})

export { name as fcLiveHeaderComponent }