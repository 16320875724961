import { EventEmitter } from "events";
class Player extends EventEmitter {
	element: HTMLVideoElement;
	constructor(element: Element, protected playerTech: string, protected debug: boolean) {
		super()
		this.element = document.createElement('video');
		element.appendChild(this.element);
	}

	onPlayerReady() { }
	playerReady() {
		this.emit('player-ready');
	}

	onReady(callback) {
		this.on('player-ready', () => { callback(); });
	}

	setup(_file: string, _start, _format) { }

	position(): number { return 0 }

	duration(): number { return 0 } 

	seek(_pos) { }

	play() { }

	pause() { }

	mute(_val?) { }

	volume(_vol) { }

	isPlaying(): boolean {
		return false
	}

	dispose() { }

	reset() {}
}

export default Player;
