import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
require('./job.list.styl');

export interface Workflow {
	code: string
	name: string
}
export interface DBJob {
	id: string
	status: number
	createdAt: Date
	error: string
	channel: string
	type: string
}
@Component({
	selector: 'job-list-component',
	template: require('./job.list.pug'),
	styles: [],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class JobListComponent implements AfterViewInit {
	displayedColumns: string[] = ['expand', 'id', 'createdAt', 'type', 'status', 'error', 'trash', 'action'];
	dataSource = new MatTableDataSource<DBJob>();
	selection = new SelectionModel<DBJob>(true, []);
	jobService: JobService | null;
	jobType = new FormControl()
	jobTypes: Workflow[] = []
	status = new FormControl()
	jobStatus = [0, 1, 2, 3, 4, 5]
	showTrashed = new FormControl()
	isLoading = true;
	data: DBJob[] = []
	resultsLength = 0;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	workflowService: WorkflowService;
	refreshClick = new Subject<void>()
	expandedJob: DBJob | null;
	constructor(private httpClient: HttpClient,
		private snack: MatSnackBar) { }

	ngAfterViewInit(): void {
		this.status.setValue([4], { emitEvent: false })
		this.jobService = new JobService(this.httpClient)
		this.workflowService = new WorkflowService(this.httpClient)
		this.workflowService.getWorkflows().subscribe(workflows => {
			this.jobTypes = workflows
		})
		merge(this.paginator.page,
			this.jobType.valueChanges,
			this.status.valueChanges,
			this.refreshClick,
			this.showTrashed.valueChanges)
			.pipe(
				debounceTime(600),
				startWith({}),
				switchMap(() => {
					this.isLoading = true;
					// non-null assertion operator
					return this.jobService!
						.getJobs(this.paginator.pageIndex, this.jobType.value?.map(w => w.code), this.status.value, this.showTrashed.value === true ? null : false, this.paginator.pageSize)
						.pipe(catchError(error => {
							this.snack.open(`Error al cargar usuarios: ${error.error.message || error.message}`, 'OK')
							return of(null)
						}))
				}),
				map(result => {
					this.isLoading = false;
					if (result === null) {
						return []
					}
					this.resultsLength = result?.total || 0;
					return result.items || [];
				})
			)
			.subscribe(data => {
				this.selection.clear()
				this.dataSource.data = data;
				this.data = data;
			});
	}
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.dataSource.data);
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: DBJob): string {
		if (!row) {
			return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
	}

	send(job: DBJob) {
		this.jobService.reSend(job).subscribe(() => {
			job.status = 2
		})
	}
	refresh() {
		this.refreshClick.next()
	}
}

export class JobService {
	constructor(private httpClient: HttpClient) { }
	getJobs(page = 0, type: string[], status: number, trash: boolean = null, limit = 10) {
		const params: { page: number, limit: number, trash?: boolean } = { page, limit };
		if (trash !== null) params.trash = trash;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (type) params.type = type;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (status) params.status = status;
		return this.httpClient.get<DBJob>(`${theConfig.api_url}/job`, {
			params,
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
			}
		})
	}
	reSend(rootJob: DBJob) {
		return this.httpClient.post<DBJob>(`${theConfig.api_url}/job/${rootJob.id}/resendroot`, null, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
			}
		})
	}
}

export class WorkflowService {
	constructor(private httpClient: HttpClient) { }
	getWorkflows() {
		return this.httpClient.get<Workflow[]>(`${theConfig.api_url}/workflow`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
			}
		})
	}
}