import * as angular from "angular";
import { Channel } from "../channel/channelService";
require('../jobProgress/jobProgressComponent');

class managerController implements angular.IComponentController {
  channel: Channel;
  constructor() {
    'ngInject'
  }
  openUpload() {
  }
}

angular.module('fireclip').component('fcManager', {
  template: require('./manager.pug'),
  controller: managerController,
  bindings: {
    channel: '<'
  }
});