export default function config($authProvider, $mdThemingProvider, $resourceProvider, $mdAriaProvider, $httpProvider, $analyticsProvider) {
	'ngInject';

	if(theConfig.googleTrackingId){
		$analyticsProvider.settings.ga = {
			userId: null
		};
		$analyticsProvider.registerSetUsername((userId) => {
			if ((window as any).gtag) (window as any).gtag('set', {'user_id': userId});
		});
		$analyticsProvider.registerPageTrack((path) => {
			const gtag = (window as any).gtag;
			if (!gtag) return
			gtag('event', 'page_view',{
				'page_title': document.title,
				'page_location': window.location.href,
				'page_path': path
			});
		});
		$analyticsProvider.registerEventTrack((action, properties) => {
			const gtag = (window as any).gtag;
			if (!gtag) return
			properties = properties || {};
			gtag('event', properties.event || 'interaction',{
				'target': properties.category,
				'action': action,
				'target-properties': properties.label,
				'value': properties.value,
				'interaction-type': properties.noninteraction
			});
		});
	}

	$mdAriaProvider.disableWarnings();

	$resourceProvider.defaults.actions.update = { method: 'PATCH' };

	$mdThemingProvider
		.theme('admin')
		.primaryPalette('amber')
		.accentPalette('deep-orange')
		.warnPalette('red')
		.backgroundPalette('grey')
		.dark();

	var neonRedMap = $mdThemingProvider.extendPalette('amber', {
		'500': '#303030',
		'contrastDefaultColor': 'light'
	});
	$mdThemingProvider.definePalette('neonRed', neonRedMap)

	$mdThemingProvider
		.theme('black')
		.primaryPalette('neonRed')
		.accentPalette('deep-orange')
		.warnPalette('red')
		.backgroundPalette('grey')
		.dark();

	$mdThemingProvider
		.theme('orange')
		.primaryPalette('orange')
		.accentPalette('deep-orange')
		.warnPalette('red');

	// used for jobprogress loading icons
	$mdThemingProvider
		.theme('green')
		.primaryPalette('green')
		.accentPalette('blue')
		.warnPalette('red');


	$authProvider.loginUrl = `${theConfig.api_url}/auth/local`;
	$authProvider.google({
		clientId: theConfig.providers.google.clientId,
		url: `${theConfig.api_url}/auth/google/link`,
		scope: ['https://www.googleapis.com/auth/youtube.upload', 'profile', 'openid'],
		accessType: 'offline',
		approvalPrompt: 'force',
		requiredUrlParams: ['scope', 'access_type', 'approval_prompt']
	});
	$authProvider.facebook({
		clientId: theConfig.providers.facebook.clientId,
		url: `${theConfig.api_url}/auth/facebook/link`,
		scope: ['pages_manage_posts', 'pages_read_engagement', 'pages_show_list']
	});
	$authProvider.twitter({
		url: `${theConfig.api_url}/auth/twitter`
	});
	$authProvider.oauth2({
		name: 'soundcloud',
		url: `${theConfig.api_url}/auth/soundcloud`,
		clientId: 'c22a3cc3713e8fe35c63fe6437c52f77',
		responseType: 'code',
		redirectUri: window.location.origin,
		scope: '*',
		authorizationEndpoint: 'https://soundcloud.com/connect',
		popupOptions: {
			width: 580,
			height: 400
		}
	});
	$authProvider.tokenRoot = 'user';

	$httpProvider.interceptors.push(function ($state) {
		'ngInject';
		return {
			'responseError': function (response) {
				// 401 Unauthorized: credentials BAD
				// 403 Forbidden: credentials OK, but limited access
				if (response.status === 401) {
					$state.go('login')
					return Promise.reject(response);
				}
				return Promise.reject(response);
			}
		};
	});

}
