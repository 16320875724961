import { Component, Inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import Auth0Service from "../auth/auth0.service";
import { User } from "./user";

export interface DialogData {
	user: User;
	channels: { id: string, name: string }[];
}


@Component({
	selector: 'dialog-content-example-dialog',
	template: require('./dialog-edit-user.template.pug'),
})
export class UserEditCreateDialog {
	profiles = []
	userForm = new FormGroup({
		name: new FormControl(),
		email: new FormControl(),
		password: new FormControl(),
		profiles: new FormControl([]),
		disabled: new FormControl(false),
		channels: new FormArray([]),
	})
	isSocial: boolean;
	pendingRequest: boolean;
	constructor(
		@Inject('Auth0Service') Auth0Service: Auth0Service,
		@Inject('User') private User: any,
		public dialogRef: MatDialogRef<UserEditCreateDialog>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private fb: FormBuilder,
		private snack: MatSnackBar,
	) {
		this.profiles = [
			{ code: 'clipper', name: 'Clipeador' },
			{ code: 'communityManager', name: 'Community Manager' },
			{ code: 'rebooter', name: 'Reiniciador' },
			{ code: 'ingestManager', name: 'Gestor de Ingestas' },
			{ code: 'userAdmin', name: 'Administrador de Usuarios' },
			{ code: 'admin', name: 'Super Administrador' }
		]
		this.profiles = this.profiles.filter(profile => Auth0Service.getUserProfilesToManageClaim().includes(profile.code))
		this.userForm.controls.channels = this.fb.array(this.data?.channels.map(channel =>
			this.fb.group({
				name: channel.name,
				id: channel.id,
				selected: !!this.data.user.channels && (this.data.user.channels.indexOf(channel.id) != -1)
			})
		))
		this.userForm.get('name').setValue(this.data.user.name)
		this.userForm.get('email').setValue(this.data.user.email)
		this.userForm.get('profiles').setValue(this.data.user.profiles)
		this.userForm.get('disabled').setValue(this.data.user.disabled)

		this.isSocial = !!this.data.user.identities.find(i => i.isSocial)
	}
	enableAll() {
		this.channels.controls.forEach(c => c.get('selected').setValue(true))
	}
	get channels() {
		return this.userForm.get('channels') as FormArray;
	}
	async save() {
		try {
			if (this.pendingRequest) return;
			this.pendingRequest = true;
			this.channels.controls.forEach(control => {
				if (control.value.selected) {
					const index = this.data.user.channels.indexOf(control.value.id)
					if (index === -1) this.data.user.channels.push(control.value.id)
				} else {
					const index = this.data.user.channels.indexOf(control.value.id)
					if (index > -1) this.data.user.channels.splice(index)
				}
			})
			this.data.user.name = this.userForm.value.name;
			this.data.user.email = this.userForm.value.email
			this.data.user.profiles = this.userForm.value.profiles;
			this.data.user.disabled = this.userForm.value.disabled;
			this.data.user.password = this.userForm.value.password;
			if (this.data.user.user_id)
				await this.User.update({ id: this.data.user.user_id }, this.data.user).$promise
			else
				await this.User.create(this.data.user).$promise
			this.dialogRef.close(true)
		} catch (error) {
			console.error(error)
			if (error.data.length) {
				this.parseResponseError(error)
			} else if (error.data.message) {
				this.snack.open(`No se ha podido guardar el usuario. ${error.data.message}`, 'OK')
			} else if (error.data.error) {
				this.snack.open(`No se ha podido guardar el usuario. ${error.data.error.message}`, 'OK');
			} else {
				this.snack.open(`No se ha podido guardar el usuario.`, 'OK');
			}
		}
		this.pendingRequest = false;
		return;
	}
	parseResponseError(response) {
		response.data.forEach(item => {
			if (item.code === 'validation') {
				const messages = [];
				item.errors.forEach(err => {
					if (err.rule === 'minLength') {
						messages.push(`- length is invalid.`);
					} else {
						messages.push(`- ${err.rule}`);
					}
				});
				this.snack.open(`${item.field} invalid:\n${messages.join('\n')}`, 'OK');
			}
		})
	}

}