// import * as angular from "angular";

import { merge, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
// import { DialogController } from "./dialog-edit-user.controller";
import { User } from "./user";

// import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
// import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserEditCreateDialog } from './dialog-edit-user.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialog } from '../common/dialog-confirm.component';
require('./user-list.styl');

export class ChannelService {
	constructor(private httpClient: HttpClient) { }
	getChannels() {
		const params = {};
		return this.httpClient.get<FireClip.Channel[]>(`${theConfig.api_url}/channel`, {
			params,
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
			}
		})
	}
}

export class UserService {
	constructor(private httpClient: HttpClient) { }
	getUsers(page = 0, page_length = 10, filter: string, adv_filter: boolean) {
		const params = { page, page_length, filter, adv_filter };
		return this.httpClient.get<{ users: User[], total: number }>(`${theConfig.api_url}/user`, {
			params,
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
			}
		})
	}
}
@Component({
	selector: 'user-list-component',
	template: require('./user-list.template.pug'),
	styles: [],
})
export class UserListComponent implements AfterViewInit {
	//  'delete'
	displayedColumns: string[] = [
		'name',
		'email',
		'profile',
		'initialized',
		'disabled',
		'identities',
		'delete',
		'edit',
	];
	dataSource = new MatTableDataSource<User>();
	@ViewChild(MatPaginator) paginator: MatPaginator;
	errors: any[] = [];
	query = new FormControl("")
	isLoading = false;
	useAdvanceFilter = new FormControl(false)
	filterState = {
		currentPage: 0,
		totalPages: 1,
		filterUser: "",
		advancedFilter: false,
	}
	users: User[];
	channels: FireClip.Channel[];
	channelService: ChannelService;
	userService: UserService;
	total = 0;
	refresh = new Subject<void>();
	constructor(httpClient: HttpClient,
		public dialog: MatDialog,
		@Inject('User') private User: any,
		private snack: MatSnackBar) {
		this.channelService = new ChannelService(httpClient)
		this.userService = new UserService(httpClient)
	}
	// $scope, private User, private Auth, ChannelService: ChannelService, private $mdDialog: angular.material.IDialogService, private $mdToast) {
	ngAfterViewInit(): void {
		merge(
			this.paginator.page,
			this.query.valueChanges,
			this.useAdvanceFilter.valueChanges,
			this.refresh
		)
			.pipe(
				debounceTime(600),
				startWith({}),
				switchMap(() => {
					this.isLoading = true;
					return this
						.userService
						.getUsers(this.paginator.pageIndex, this.paginator.pageSize, this.query.value, this.useAdvanceFilter.value)
						.pipe(catchError(error => {
							this.snack.open(`Error al cargar usuarios: ${error.error.message || error.message}`, 'OK')
							return of(null)
						}))
				}),
				map(result => {
					this.isLoading = false;
					if (result === null) {
						return []
					}
					this.total = result?.total || 0;
					return result.users || []
				})
			)
			.subscribe(data => this.dataSource.data = data)
		this.channelService.getChannels().subscribe(channels => this.channels = channels)
	}
	showToast(_type: 'success' | 'error', message: string) {
		this.snack.open(message, 'OK', { duration: 3000 })
	}
	async delete(_ev, user) {
		this.dialog
			.open(ConfirmDialog, {
				data: {
					title: '¿Desea eliminar el usuario?',
					message: `El usuario ${user.email} será eliminado`
				}
			})
			.afterClosed()
			.subscribe(result => {
				if (result) {
					this.User
						.delete({ id: user.user_id })
						.$promise
						.then(() => {
							this.refresh.next();
							this.snack.open(`Usuario ${user.email} eliminado`, 'OK', { duration: 3000 })
						})
				}
			})
	}
	edit(_ev, user: User) {
		if (!user) {
			user = {
				user_id: null,
				name: null,
				email: null,
				channels: [],
				disabled: true,
				profiles: [],
				password: null,
				identities: [],
				initialized: true,
			}
		}
		const dialogRef = this.dialog.open(UserEditCreateDialog, {
			data: {
				user,
				channels: this.channels,
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.refresh.next();
				this.snack.open(`Usuario ${user.user_id ? 'editado' : 'creado'}`, 'OK', { duration: 3000 })
			}
		});
	}
}
