import { LibraryService } from '../library/library.service';
import { Input } from '../channel/channelService';
import { safeApply } from '../../utils';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Library } from '../library/library';
import angular from 'angular';

export class LibraryDialogController implements angular.IComponentController {
	libraries: Library[]
	loading = false;
	error: string;
	input: Input = null
	searchTitle: string = null;
	searchText = new Subject<string>();
	currentPage = 0;
	lastSearchText = ""
	pageLength = 4

	constructor(private $scope, public inputs: Input[], private $mdDialog: angular.material.IDialogService, private LibraryService: LibraryService) {
		'ngInject';
		this.input = inputs[0];
		this.getLibraries()
		this.searchText
			.pipe(debounceTime(800))
			.subscribe((text) => {
				this.getLibraries(text)
				this.lastSearchText = text;
			})
	}


	forwardPage() {
		this.currentPage = this.libraries.length > 0 ? this.currentPage + 1 : this.currentPage;
		this.getLibraries(this.lastSearchText);
	}

	backwardPage() {
		this.currentPage = this.currentPage > 0 ? this.currentPage - 1 : 0;
		this.getLibraries(this.lastSearchText);
	}
	getLibraries(searchText = "") {
		if (this.loading) return
		this.loading = true;
		this.LibraryService
			.getLibraryByRecordingIds([this.input.recordingId], null, searchText, this.currentPage, this.pageLength)
			.then(async libraries => {
				this.libraries = libraries.map(l => new Library(l))
				this.loading = false;
				safeApply(this.$scope)
			})
			.catch(error => {
				this.loading = false;
				safeApply(this.$scope, () => {
					this.error = error.message;
				})
			})
	}
	search(text) {
		this.searchText.next(text);
	}
	onInputChange() {
		this.getLibraries()
	}
	close() {
		this.$mdDialog.cancel();
	}
	select(library: Library) {
		this.$mdDialog.hide(library.dbLibrary)
	}
}
