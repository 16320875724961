import * as  angular from 'angular';
import { copyToClipboard } from '../../utils';

const template = require('./index.pug');
const image = require('./c13.svg');

class Service implements FireClip.ExportService {
  async defaultAction(theExport, _media) {
    return copyToClipboard(theExport.result.url).then(() => "Texto copiado")
  }
}

class Controller implements angular.IComponentController {
  exporter: any;
  job: any;
  media: any;
  result: any;
  errors = {
    providerEmpty: false,
    providerLoadError: false
  };
  constructor() {
    "ngInject";
  }
  async $onInit() {
    if (this.job.status === 0) {
      this.job.data.title = this.media.title;
    } else if (this.job.status == 3) {
      this.result = this.job.result;
    }
  }
}
angular.module('fireclip').component('exporterHls', {
  template: template,
  controller: Controller,
  bindings: {
    job: '<',
    media: '<',
    exporter: '<'
  }
});

export default {
  code: 'hls',
  template,
  controller: Controller,
  image,
  service: new Service(),
}