import Auth0Lock from 'auth0-lock';

import { cleanLocalStorage, getUserLocalSetting } from '../utils';
import { EventEmitter } from "events";
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, repeat, timeout } from 'rxjs/operators';
import uiActions from './uiActions';

class Auth0Service extends EventEmitter {

	auth0Lock: typeof Auth0Lock = null;
	token: string = null;
	profile: any = {};
	params: Auth0LockAuthParamsOptions;

	constructor(private $auth, private $state) {
		'ngInject';
		super()
		this.params = {
			audience: theConfig.auth0.audience,
			scope: 'openid profile email',
			responseType: 'token id_token',
		}
		this.auth0Lock = new Auth0Lock(theConfig.auth0.clientId, theConfig.auth0.domain, {
			language: 'es',
			allowAutocomplete: true,
			allowPasswordAutocomplete: true,
			auth: {
				responseType: 'token id_token',
				audience: theConfig.auth0.audience,
				redirect: false,
				redirectUrl: location.origin,
				params: this.params
			},
			theme: {
				logo: require('../../assets/img/fireclip-logo.webp')
			},
			languageDictionary: {
				title: ''
			},
			allowSignUp: false,
			defaultDatabaseConnection: theConfig.auth0.defaultDatabaseConnection,
		});

		this.auth0Lock.on("authenticated", (credentials) => this.authenticate(credentials));
		this.auth0Lock.on("authorization_error", (error) => {
			alert(error.errorDescription || error.description || error.error_description)
		});
		this.auth0Lock.on("unrecoverable_error", (error) => {
			alert(error.errorDescription || error.description || error.error_description)
		});

		let errorCount = 0;
		of(1).pipe(
			mergeMap(() => from(this.maintainToken())),
			timeout(theConfig.auth0.sessionCheckTimeoutMs),
			catchError(() => {
				if (errorCount > theConfig.auth0.sessionCheckMaxRetries) this.logout();
				return of(`err`);
			}),
			map((val) => {
				return errorCount = val === 'err' ? errorCount + 1 : 0
			}),
			delay(theConfig.auth0.sessionCheckIntervalMs),
			repeat()
		).subscribe({
			next: _ => { },
			error: val => {
				console.error(`Timer terminated due to an unexpected error. Reloading window.`, val);
				window.location.reload();
			},
		})
	}

	checkSession(): Promise<AuthResult> {
		return new Promise((resolve, reject) => {
			// The Auth0Lock documentation says that this method when given no parameters for
			// options, it uses the same options as the ones used to initialize the lock...
			// AND THAT'S A LIE, IT DOESN'T!!!!!!!!!
			// https://auth0.com/docs/libraries/lock/lock-api-reference#checksession-
			try {
				this.auth0Lock.checkSession(this.params, async (err, authResult) => {
					if (err) return reject(err);
					await this.setupCredentials(authResult)
					return resolve(authResult);
				});
			} catch (err) {
				return reject(err);
			}
		})
	}
	async maintainToken() {
		const profile = this.getProfile()
		if (
			Auth0Service.isLegacyAuthEnabled() // is legacy login
			|| !this.getAccessToken() // AND no access token
			|| !profile
			|| (profile.exp * 1000) - Date.now() > theConfig.auth0.refreshTokenLookaheadMs // AND token is not expiring soon
		) {
			return 'ok'; // a return value is needed for RxJS to operate
		}
		await this.checkSession();
		return 'ok';
	}

	async authenticate(credentials: AuthResult) {
		await this.setupCredentials(credentials);
		const activeChannelId = getUserLocalSetting(this.profile.sub, 'lastActiveChannelID')
		const activeRecordingId = getUserLocalSetting(this.profile.sub, 'lastActiveRecordingID')
		const params = {}
		if (activeChannelId) {
			params['channel'] = activeChannelId
			if (activeRecordingId) {
				params['recording'] = activeRecordingId
			}
			this.$state.go('main.channel', params);
		} else {
			this.$state.go('main')
		}
		// this.maintainToken();
	}

	getUserInfo(token) {
		return new Promise((resolve, reject) => {
			this.auth0Lock.getUserInfo(token, (error, profile) => {
				if (error) {
					reject(error);
				} else {
					resolve(profile);
				}
			});
		})
	}

	async setupCredentials(credentials: AuthResult) {
		this.profile = await this.getUserInfo(credentials.accessToken);
		localStorage.setItem('satellizer_token', credentials.accessToken);
		localStorage.setItem('profile', JSON.stringify(this.profile));
		if (this.profile.sub) {
			this.profile.id = this.profile.sub;
		}
		this.$auth.setToken(credentials.accessToken);
		this.emit('newAccessToken', credentials.accessToken);
	}

	static enableLegacyAuth() {
		localStorage.setItem('legacy_auth', 'true');
	}

	static disableLegacyAuth() {
		localStorage.removeItem('legacy_auth');
	}

	static isLegacyAuthEnabled() {
		return localStorage.getItem('legacy_auth') === 'true';
	}

	getAccessToken() {
		// same ass this.$auth.getToken()
		return localStorage.getItem('satellizer_token');
	}

	getAdminMenuClaim(): string[] {
		const claim = this.getProfileClaim('adminMenu');
		if (!claim || claim.constructor !== Array) return [];
		return claim
	}

	getUserProfilesToManageClaim(): string[] {
		const claim = this.getProfileClaim('userProfileToManage');
		if (!claim || claim.constructor !== Array) return [];
		return claim
	}

	profileHasUIAction(action: uiActions): boolean {
		const claim = this.getProfileClaim('uiActions')
		if (!claim || claim.constructor !== Array) return false;
		return claim.includes(action.valueOf());
	}

	private getProfileClaim(claim: string) {
		this.profile = this.getProfile()
		return this.profile?.[`${theConfig.auth0.audience}/${claim}`]
	}

	getProfile() {
		// this payload could be the one created by Auth0 or FireClip
		const profile = this.$auth.getPayload()
		if (!profile) return null;
		const storedProfile = JSON.parse(localStorage.getItem('profile') || '{}');
		return {
			...profile,
			picture: `https://ui-avatars.com/api/?name=${profile.name}`,
			...storedProfile,
			id: profile.id || profile.sub
		}
	}

	isAuthorized() {
		return !!this.getAccessToken();
	}
	// wrapper for $auth
	isAuthenticated() {
		return this.$auth.isAuthenticated()
	}

	isAadmin() {
		if (!this.isAuthorized()) { return false; }
		return this.getAdminMenuClaim().length > 0
	}

	resetProfile() {
		this.token = null;
		this.profile = null;
		Auth0Service.disableLegacyAuth();
	}

	cleanLogout() {
		this.resetProfile();
		cleanLocalStorage({});
		this.$auth.logout()
	}
	// auth0 still redirects even whenn returnTo is not set... 
	logout() {
		this.cleanLogout();
		return this.auth0Lock.logout({ returnTo: window.location.href });
	}

	presentLoginPopup() {
		this.auth0Lock.show();
	}

}

export default Auth0Service;
