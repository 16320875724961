import * as angular from "angular";

//Notification Handler written following official JS Docs
//https://developer.mozilla.org/en-US/docs/Web/API/Notification/Using_Web_Notifications
var WebNotificationHandler = function ($window: angular.IWindowService) {
    'ngInject';

    /*
        Gecko notes
        -----------
        Prior to Firefox 22 (Firefox OS <1.2), the instantiation of 
        a new notification must be done with the navigator.mozNotification 
        object through its createNotification method.
        Prior to Firefox 22 (Firefox OS <1.2), the Notification was displayed 
        when calling the show method and was supporting the click and close 
        events only.
        One particular Firefox OS issue is that you can pass a path to an icon 
        to use in the notification, but if the app is packaged you cannot use a 
        relative path like /my_icon.png. 
        You also can't use window.location.origin + "/my_icon.png" 
        because window.location.origin is null in packaged apps. 
        The manifest origin field fixes this, but it is only available in 
        Firefox OS 1.1+. A potential solution for supporting Firefox OS <1.1 
        is to pass an absolute URL to an externally hosted version of the icon. 
        This is less than ideal as the notification is displayed immediately with 
        the icon missing, then the icon is fetched, but it works on all versions of 
        Firefox OS.
        -> old Firefox implementation: https://developer.mozilla.org/en-US/docs/Web/Guide/User_experience/Displaying_notifications_deprecated
        
        Chrome notes
        ------------
        Prior to Chrome 22, the support for notification was following an old prefixed 
        version of the specification and was using the navigator.webkitNotifications 
        object to instantiate a new notification.

        Prior to Chrome 32, Notification.permission was not supported.
        -> Old Chrome implementation: http://www.chromium.org/developers/design-documents/desktop-notifications/api-specification
        
        Safari notes
        ------------
        Safari started supporting notification with Safari 6 but only on Mac OSX 10.8+ (Mountain Lion).

        Internet Explorer notes
        -----------------
        No support even on IE 11
    */

    var NotificationObj = undefined;
    var implementationDetected = null;

    var WEBKIT_PERMISSIONS = ['granted', 'default', 'denied'];
    Object.freeze(WEBKIT_PERMISSIONS);

    var config = {
        defaultIcon: 'http://lorempixel.com/64/64/'
    }

    var implementation = {
        'Notif': 'Standard HTML5 Web Notification API (W3C Recommendation)',
        'mozNotif': 'Gecko old implementation, non-standard',
        'webkitNotif': 'Chromium old implementation, non-standard',
        'no_support': 'This Browser does not support Web Notifications'
    }

    var __crossBrowserSetup = function () {
        if ('Notification' in $window) {
            /* Standard Implementation/Specification, as of May 2016, W3C Recommendation
                https://developer.mozilla.org/en-US/docs/Web/API/notification
            */
            NotificationObj = $window.Notification
            implementationDetected = 'Notif';
        } else if ('mozNotification' in navigator) {
            /* Old Firefox implementation/specification. Deprecated on Firefox 22
            https://developer.mozilla.org/en-US/docs/Web/Guide/User_experience/Displaying_notifications_deprecated
            */
            // NotificationObj = navigator.mozNotification;
            implementationDetected = 'mozNotif';
        } else if ('webkitNotifications' in $window) {
            /*old Chrome implementation/specification. Deprecated aprox may 2014
            http://www.chromium.org/developers/design-documents/desktop-notifications/api-specification
            */
            NotificationObj = $window.webkitNotifications;
            implementationDetected = 'webkitNotif';
        } else {
            implementationDetected = 'no_support';
        }

        console && console.info && console.info('Web Notification API implementation detected: ' + implementation[implementationDetected]);
    }

    var _isNotificationSupported = function () {
        return implementationDetected != 'no_support' && implementation != null ? true : false
    }

    var _getPermissionStatus = function () {
        switch (implementationDetected) {
            case 'Notif':
                return NotificationObj.permission
                break;
            case 'mozNotif':
                //previous firefox implementation do not handle permissions
                //return granted by default
                return 'granted';
                break;
            case 'webkitNotif':
                /* old Chrome implementation returns an integer
                    PERMISSION_ALLOWED (0) indicates that the user has granted permission to scripts with this origin to show notifications.
                    PERMISSION_NOT_ALLOWED (1) indicates that the user has not taken an action regarding notifications for scripts from this origin.
                    PERMISSION_DENIED (2) indicates that the user has explicitly blocked scripts with this origin from showing notifications.
                */
                return WEBKIT_PERMISSIONS[NotificationObj.checkPermission()]
                break;
        }
    }
    var _requestPermission = function (callback) {
        switch (implementationDetected) {
            case 'Notif':
                NotificationObj.requestPermission(function (status) {
                    callback(status)
                });
                break;
            case 'mozNotif':
                //previous firefox implementation do not handle permissions
                //return granted by default
                callback('granted');
                break;
            case 'webkitNotif':
                NotificationObj.requestPermission(function (status) {
                    callback(WEBKIT_PERMISSIONS[status]);
                });
                break;
        }
    }


    var _createNotification = function (title, options2) {
        //instantiated notification 
        var newNotification = undefined;

        //Standard options
        var options = {
            body: options2.body || '',
            icon: options2.icon || config.defaultIcon,
            tag: options2.tag || null,
            lang: options2.lang || null,
            dir: options2.dir || 'auto'
        }

        switch (implementationDetected) {
            case 'Notif':
                newNotification = new NotificationObj(title, options);
                break;
            case 'mozNotif':
                newNotification = NotificationObj.createNotification(title, options.body, options.icon);
                newNotification.show();
                break;
            case 'webkitNotif':
                newNotification = NotificationObj.createNotification(title, options.body, options.icon);
                break;
        }

        return newNotification;
    }

    var _config = function (setting, value) {
        if (config[setting]) {
            config[setting] = value;
        }
    }

    __crossBrowserSetup();

    return {
        permission: _getPermissionStatus,
        requestPermission: _requestPermission,
        isSupported: _isNotificationSupported,
        create: _createNotification,
        config: _config
    }
}

//expose angular module
const wns = angular.module('jg.webNotification', []).factory('WebNotification', ['$window', WebNotificationHandler]);

export default wns;
