export default function ($resource, API_URL) {
	'ngInject';

	return $resource(`${API_URL}channel/:id`, {}, {
		getProvidersByExporterAndType: {
			url: `${API_URL}channel/:channelId/exporter/:exporterId/provider/:providerType`,
			method: 'GET',
			isArray: true
		},
		addAccountToProvider: {
			url: `${API_URL}channel/:channelId/exporter/:exporterId/provider/:providerType`,
			method: 'POST'
		},
		removeAccountFromProvider: {
			url: `${API_URL}channel/:channelId/exporter/:exporterId/provider/:providerType/:providerId`,
			method: 'DELETE'
		}
	});
}