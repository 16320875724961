import MediaService from "./media.service";
import ChannelService, { Channel } from "../channel/channelService";
import * as angular from "angular";

class mediaListController implements angular.IComponentController {
	moreMedias: boolean = true;
	loadingMedias: boolean = false;
	channel: Channel;
	archived: boolean | null = false; //true: show archived only, false: show not archived, null do not filter
	showFilter: boolean = false;
	justClips = false;
	filterParams = {
		title: null,
		from: null,
		to: null,
		archive: false,
		types: ['clip'],
		owner: null,
	}
	$onInit() {
		this.MediaService.clear();
		this.loadMoreMedias();
		this.SocialProviderService.configureProviders(this.channel);
		this.MediaService.onUpdate();
		if (this.filterParams.types.length === 1 && this.filterParams.types[0] === 'clip') {
			this.justClips = true;
		}
	}
	$onDestroy() {
		this.MediaService.clear();
		this.MediaService.offUpdate();
	}
	$onChanges(changesObj) {
		if (changesObj.filterParams && changesObj.filterParams.currentValue && !changesObj.filterParams.isFirstChange()) {
			this.filter();
		}
	}
	constructor(private $scope, $state, ChannelService: ChannelService, private SocialProviderService, private MediaService: MediaService) {
		'ngInject';

		$scope.query = '';
		$scope.search = false;
		$scope.state = {
			selectedMedia: undefined,
		};
		if ($state.params && $state.params.media) {
			MediaService
				.getById($state.params.media)
				.then((media) => {
					const mediaChannel = ChannelService.channels.find(channel => channel.id === media.channel);
					if (mediaChannel) {
						if (ChannelService.activeChannel.id === mediaChannel.id) {
							$state.go('main.manager', { channelId: mediaChannel.id, mediaId: media.id });
						} else {
							$state.go('main.manager', { channelId: mediaChannel.id, mediaId: media.id });
						}
					} else {
						$state.go('main.live');
					}
				});
		}
	}

	filter() {
		this.MediaService.clear();
		this.loadMoreMedias()
	}
	editMedia(_media) {
		this.$scope.selectTab(0);
	}

	copyMedia(_media) {
		this.$scope.selectTab(0);
	}

	deleteMedia(media) {
		this.MediaService.delete(media);
	}

	filterSetup() {
		if (this.justClips) {
			this.filterParams.types = ['clip']
		} else {
			this.filterParams.types = [];
		}
	}
	loadMoreMedias(toDate = null) {
		this.loadingMedias = true;
		if (!toDate && this.filterParams.to) {
			// in the form, the to date uses only date values (ie to=2020-01-22T00:00:00), no hours, and the gramar is understood to include that day so add 24 horus to match the expected result (ie: to=2020-01-23T00:00:00)
			toDate = new Date(this.filterParams.to.getTime() + 86400000)
		}
		this.MediaService
			.load(this.channel, false, this.filterParams.from, toDate, this.filterParams.archive, this.filterParams.title, this.filterParams.types, this.filterParams.owner)
			.then((moreMedias) => {
				this.loadingMedias = false;
				this.moreMedias = moreMedias;
			});
	}
}

angular.module('fireclip').component('mediaList', {
	controller: mediaListController,
	template: require('./media.list.pug'),
	bindings: {
		showFilter: '<',
		channel: '<',
		filterParams: '<',
	}
})
export default mediaListController
