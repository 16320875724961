import PlayerService from "./service";

/**
 * @type {import('angular').IComponentOptions}
 */
class Component {
	onInit: (param: { service: PlayerService }) => any
	element: HTMLElement;
	id: any;
	player: ('videojs' | 'jw');
	playerTech: string;
	debug: boolean;
	playerService: PlayerService;
	constructor($element: angular.IRootElementService) {
		'ngInject';
		this.element = $element[0];
	}

	$onInit() {
		if (this.id === null || this.id === undefined) {
			this.id = `angular-video-player-${Math.floor((Math.random() * 999999999) + 1)}`;
		}
		if (!this.player) {
			this.player = 'jw';
		}
		if (!this.playerTech) {
			if (this.player === 'jw') this.playerTech = 'flash';
			else this.playerTech = 'html5';
		}
		this.playerService = new PlayerService(this.player, this.element, this.playerTech, this.debug);
		this.onInit({ service: this.playerService })
		// use a then...this.PlayerService.onInit()
	}

	$onChanges(changesObj) {
		console.warn('missing change object...', changesObj)
	}

	$onDestroy() {
		this.playerService.dispose();
	}
}

export default Component;
