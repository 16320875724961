// import JWPlayerWrapper from './jwplayer';
import VideoJSWrapper from './videojs';
// import BitdashWrapper from './bitdash';
import Player from './player';
import { EventEmitter } from 'events';

class PlayerService extends EventEmitter {
	playerWrapper: Player = null;
	playerInterface: Player = null;
	setupParams: [any, any, any];
	file: string;

	dispose() {
		this.playerInterface.dispose();
		this.removeAllListeners('play');
		this.removeAllListeners('timeupdate');
		this.removeAllListeners('pause');
		this.removeAllListeners('mute');
		this.removeAllListeners('player-ready');
	}

	constructor(player: 'jw' | 'videojs', container: HTMLElement, playerTech: string = 'html5', playerDebug: boolean) {
		super();
		this.setupParams = null;
		console.log('initPlayer', player);
		switch (player) {
			case 'videojs':
				this.playerInterface = new VideoJSWrapper(container, playerTech, playerDebug);
				break;
			// case 'jw':
			// 	this.playerInterface = new JWPlayerWrapper(container, playerTech, playerDebug);
			// 	break;
			default: throw new Error("no player instance")
		}
		this.playerInterface.on('play', (...args) => this.emit('play', ...args))
		this.playerInterface.on('timeupdate', (...args) => {
			this.emit('timeupdate', ...args)
		})
		this.playerInterface.on('pause', (...args) => this.emit('pause', ...args))
		this.playerInterface.on('mute', (...args) => this.emit('mute', ...args))
		this.playerInterface.onReady((...args) => this.emit('player-ready', ...args))
		// window._player = this.playerInterface;
		// window._playerService = this;
	}

	onPlayerReady(callback: { (): void; (...args: any[]): void; }) {
		this.once('player-ready', callback);
	}
	offPlayerReady() {
		this.removeAllListeners('player-ready');
	}

	/**
	 * @param start in seconds
	 */
	setup(file = '', start = 0, format = 'hls') {
		this.file = file;
		this.setupParams = [file, start, format];
		if (!this.playerInterface) return console.warn('no player interface');
		// this.playerInterface.dispose();
		if (file === '') this.playerInterface.pause()
		this.playerInterface.setup(file, start, format);
		if (this.volume !== null) {
			this.playerInterface.volume(this.volume);
		}
	}

	get volume() {
		if (localStorage.getItem('playerVolume') === null) return null;
		return parseInt(localStorage.getItem('playerVolume'), 10);
	}
	set volume(volume: number) {
		if (volume === undefined) volume = 0;
		localStorage.setItem('playerVolume', volume.toString());
		this.playerInterface.volume(volume);
	}
	seekOrSetup(url: string, position: number) {
		if (this.file === url) {
			this.seek(position);
		} else {
			this.setup(url, position);
		}
	}
	seek(totalDiff) {
		return this.playerInterface.seek(totalDiff);
	}

	position() {
		return this.playerInterface.position();
	}

	duration() {
		return this.playerInterface.duration();
	}

	reset() {
		this.playerInterface.reset();
	}

	play() {
		this.playerInterface.play();
	}

	pause() {
		this.playerInterface.pause();
	}

	mute(val: boolean = false) {
		return this.playerInterface.mute(val);
	}

	on(event: 'play' | 'timeupdate' | 'pause' | 'mute', listener: (...args: any[]) => void) {
		return super.on(event, listener);
	}

	isPlaying() {
		return this.playerInterface.isPlaying();
	}

	log(message) {
		console.log(`PlayerService: ${message}`);
	}

	togglePlay() {
		if (this.isPlaying()) {
			this.pause();
		} else {
			this.play();
		}
	}
}
export default PlayerService;
