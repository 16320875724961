import { BehaviorSubject, Subject } from "rxjs";
import { v4 } from 'uuid'

interface getImageCanvas { (time: number): Promise<HTMLCanvasElement> }
interface imageCanvasGenerator<T> { (value?: T): getImageCanvas }


class EditorLib {
	readonly duration$: BehaviorSubject<number>;
	readonly start$: BehaviorSubject<number>;
	readonly end$: BehaviorSubject<number>;
	readonly destroy$ = new Subject();
	readonly id: string = v4()
	public title = 'sin título'
	constructor(readonly refId: string, start: number, duration: number, public readonly url: string, public readonly getImageCanvas: imageCanvasGenerator<any>) {
		this.duration$ = new BehaviorSubject(duration);
		this.start$ = new BehaviorSubject(start);
		this.end$ = new BehaviorSubject(start + duration);
	}
	//TODO: on all setters, only set if value is different
	get duration() {
		return this.duration$.getValue();
	}
	set duration(value) {
		this.duration$.next(value);
		this.end$.next(this.start + value);
	}
	get start() {
		return this.start$.getValue()
	}
	set start(value) {
		this.start$.next(value)
		this.end$.next(value + this.duration);
	}
	get end() {
		return this.end$.getValue();
	}
	destroy() {
		console.log('removing library ', this.id)
		this.destroy$.next();
		this.destroy$.complete();
	}
}

export { EditorLib, imageCanvasGenerator, getImageCanvas }