import * as angular from 'angular';
import { copyToClipboard } from '../../utils';

const template = require('./index.pug')
class Service implements FireClip.ExportService {
	async defaultAction(theExport, _media) {
		return copyToClipboard(theExport.result.url).then(() => "Texto copiado")
	}
}
class Controller {
	result: any;
	exporter: any;
	job: any;
	media: any;
	constructor() {
		'ngInject';
	}

	$onInit() {
		if (this.job.status == 3) {
			if (this.job.result && this.job.result.url) {
				if (!this.job.result.url.startsWith('http')) {
					this.result = this.job.childs[0].data.WorkflowInput.input.parameters.s3.cloudFrontURL + this.job.result.url
				} else {
					this.result = this.job.result.url;
				}
			}
		}
	}
}
angular.module('fireclip').component('exporterS3', {
	template,
	controller: Controller,
	bindings: {
		job: '<',
		media: '<',
		exporter: '<'
	}
});

export default {
	code: 's3',
	template,
	controller: Controller,
	image: require('./default-icon.svg'),
	service: new Service(),
}