import socketService from '../socketService';
import { EventEmitter } from 'events';
import { v4 } from 'uuid'
import RecordingService from '../recording/recording.service';

export class Input {
	id: string
	name: string
	private _recording: FireClip.Recording = null
	recordingId: string = null
	isBumperInput: boolean = false
	automaticExporting: string = null
	order?: number = 0

	constructor(dbInput: FireClip.Input, private RecordingService? : RecordingService) {
		if (dbInput) {
			this.id = dbInput.id;
			this.name = dbInput.name;
			if (typeof dbInput.order === 'number')
				this.order = dbInput.order;
			if (typeof dbInput.recording === 'string') {
				this.recordingId = dbInput.recording;
			} else if (typeof dbInput.recording === 'object') {
				this.recording = dbInput.recording;
			}
			this.isBumperInput = dbInput.isBumperInput
			this.automaticExporting = dbInput.automaticExporting
		} else {
			this.id = v4();
		}
	}
	set recording(value) {
		this._recording = value;
		this.recordingId = value?.id || null;
	}
	get recording() {
		return this._recording;
	}
	get recordingEnabled() {
		return this._recording?.enabled;
	}
	async getRecording(): Promise<FireClip.Recording> {
		if (!this._recording) {
			if (!this.RecordingService) throw "no recording service provided to input"
			this._recording = await this.RecordingService.get(this.recordingId)
		}
		return this._recording;
	}
	clone(): Input {
		const dbInput = this.getDBInput();
		const cloned = new Input(dbInput, this.RecordingService);
		// assing recording just in case recordingservice is null
		cloned.recording = this.recording;
		return cloned;
	}
	getDBInput(): FireClip.Input {
		return {
			id: this.id,
			name: this.name,
			recording: this.recordingId,
			isBumperInput: this.isBumperInput,
			automaticExporting: this.automaticExporting,
			order: this.order
		}
	}
}

export class Channel {
	id: string
	active: boolean
	name: string
	code: string
	createdAt: Date
	updatedAt: Date
	exporters: FireClip.Exporter[] = []
	inputs: Input[] = [];
	constructor(dbChannel: FireClip.Channel = null, private RecordingService: RecordingService = null) {
		if (dbChannel) {
			this.id = dbChannel.id;
			this.active = dbChannel.active
			this.name = dbChannel.name
			this.code = dbChannel.code
			this.createdAt = dbChannel.createdAt
			this.updatedAt = dbChannel.updatedAt
			this.exporters = dbChannel.newExporters || []
			this.inputs = dbChannel.inputs.map(i => new Input(i, RecordingService)) || []
		}
	}
	clone(): Channel {
		const clone = this.getDBChannel()
		return new Channel(clone, this.RecordingService)
	}
	getDBChannel(): FireClip.Channel {
		this.inputs = this.inputs.map((item, index) => {
			item.order = index;
			return item;
		});
		return {
			id: this.id,
			active: this.active,
			name: this.name,
			code: this.code,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			newExporters: this.exporters,
			inputs: this.inputs.map(i => i.getDBInput()),
		}
	}
}

class ChannelService extends EventEmitter {
	player: any;
	channels: any;
	active: any;
	activeChannel: Channel;
	LIBRARY_STORAGE_HOST: string;
	MEDIA_STORAGE_HOST: string;
	constructor(private Channel, private RecordingService: RecordingService) {
		'ngInject';

		super();
		this.LIBRARY_STORAGE_HOST = `${theConfig.library_storage_url}/`
		this.MEDIA_STORAGE_HOST = `${theConfig.media_storage_url}/`
		this.player = null;
		this.channels = null;
		this.active = null;
	}

	setActiveChannel(channel: Channel) {
		if (this.activeChannel) {
			socketService.unsubscribeToMediasByChannelId(this.activeChannel.id);
		}
		this.activeChannel = channel;
		socketService.subscribeToMediasByChannelId(this.activeChannel.id);
	}

	async get(id: string): Promise<Channel> {
		this.channels = this.Channel.get({ id });
		return this.channels.$promise.then(c => new Channel(c, this.RecordingService))
	}
	async query(arg0: any = null): Promise<Channel[]> {
		const channels = await this.Channel.query(arg0).$promise;
		return channels.map(c => new Channel(c, this.RecordingService));
	}
	async save(channel: Channel) {
		return this.Channel
			.save(channel.getDBChannel())
			.$promise
	}
	async delete(channel: any) {
		return this.Channel.delete({id: channel.id}).$promise
	}

	async update(channel: Channel) {
		return this.Channel.update({ id: channel.id }, channel.getDBChannel())
			.$promise
	}

	getVodURL(library: FireClip.Library, _recording: FireClip.Recording) {
		return `${this.LIBRARY_STORAGE_HOST + library.path}stream/${library.filePrefix}.m3u8`;
	}

	getCurrentLibraryUrl(library) {
		if (library) {
			let extension = '.mpeg';
			if (library.ext) { extension = library.ext; }
			return this.LIBRARY_STORAGE_HOST + library.path + library.filePrefix + extension;
		}
		return '';
	}

	async getChannelById(id: string): Promise<Channel> {
		return this.get(id)
	}

	async getProvidersByExporterAndType(channelId: string, exporterId: string, providerType: string) {
		return this.Channel.getProvidersByExporterAndType({channelId, exporterId, providerType}).$promise
	}

	async addAccountToProvider(channelId: string, exporterId: string, providerType: string, providerId: string) {
		return this.Channel.addAccountToProvider({channelId, exporterId, providerType}, {id: providerId}).$promise
	}

	async removeAccountFromProvider(channelId: string, exporterId: string, providerType: string, providerId: string) {
		return this.Channel.removeAccountFromProvider({channelId, exporterId, providerType, providerId}).$promise
	}

}

export default ChannelService;
