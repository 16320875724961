import * as angular from 'angular';
import MediaService from '../media/media.service';

class WorkflowController {
  constructor(private ExportService, private $mdToast, private $mdDialog, private MediaService: MediaService) { 
    'ngInject';

  }
  export(media, job) {
    const messages = validateJobData(job)
    if (messages) {
      this.$mdDialog.show(this.$mdDialog.alert({
        title: 'No se puede exportar aún',
        htmlContent: 'La verificación de datos ha detectado errores.<br>' +
          'Vuelve a intentarlo luego de corregir los siguientes errores: <br><br>' +
          messages.join('<br>'),
        ok: 'Aceptar',
        multiple: true
      }))
      return Promise.reject(messages.join(';'))
    }
    return this.ExportService
      .export(job)
      .then((job) => {
        this.MediaService.addNewRootJobToMedia(media, job);
        return job;
      })
      .catch((error) => {
        const toast = this.$mdToast.simple()
          .content(error)
          .action('OK')
          .capsule(true)
          .highlightAction(true)
          .hideDelay(false)
          .position('top right');
        this.$mdToast.show(toast);
        return Promise.reject(error);
      })
  }
}

angular.module('fireclip').service('WorkflowController', WorkflowController);

function validateJobData(job) {
  const jobValidations = dataValidationsByJob[job.type];
  if (jobValidations) {
    var messages = [];
    if (job.data.provider) {
      for (let key in job.data.provider) {
        if (!job.data.provider[key]) messages.push("debes seleccionar " + key);
      }
    }
    jobValidations.forEach(function (validationObj) {
      var value = job.data[validationObj.field] || null;
      if (!validationObj.filterFn) {
        if (!value) messages.push(validationObj.message);
      } else if (!validationObj.filterFn(value)) {
        messages.push(validationObj.message);
      }
    });
    if (messages.length) return messages;
  }
}

const dataValidationsByJob = {
  facebook: [{
    field: 'pageId',
    message: 'Debes seleccionar la pagina'
  }],
  youtube: [{
    field: 'title',
    message: 'El título para youtube no puede superar los 100 caracteres',
    filterFn: function (value) {
      return value.toString().length <= 100 ? true : false
    }
  },
  {
    field: 'title',
    message: 'El título para youtube debe tener más de 3 caracteres',
    filterFn: function (value) {
      return value.toString().length >= 4 ? true : false
    }
  }
  ]
};