import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: 'confirm-dialog',
	template: `
<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    <p>{{data.message}}</p>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="dialog.close(false)">No</button>
    <button mat-button (click)="dialog.close(true)" cdkFocusInitial>Sí</button>
</div>
`,
})
export class ConfirmDialog {
	constructor(
		public dialog: MatDialogRef<ConfirmDialog>,
		@Inject(MAT_DIALOG_DATA) public data: { title: string, message: string }) { }
}
