/**
 * Google analytics
 */
if (theConfig.googleTrackingId) {
	let googleAnalyticsScript = document.createElement("script");
	googleAnalyticsScript.type = "text/javascript";
	googleAnalyticsScript.setAttribute("async", "true");
	googleAnalyticsScript.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${theConfig.googleTrackingId}`)
	document.documentElement.firstChild.appendChild(googleAnalyticsScript);
	window.dataLayer = window.dataLayer || [];
	function gtag(...args: any[]) { window.dataLayer.push(args); }
	gtag('js', new Date());
	gtag('config', theConfig.googleTrackingId, {
		send_page_view: false
	});
}
/**
 * Google analytics end
 */

require('./main.styl')
import * as angular from 'angular';
import { downgrade } from './app/fireclip.module';
import fireclipApp from './app'

angular.element(function () {
	//angular.bootstrap(document.body, ['fireclip'], { strictDi: true });
	downgrade()
});

import config from './app/app.config';
import routeConfig from './app/app.config.routes';
import run from './app/app.run';
fireclipApp.config(routeConfig);
fireclipApp.config(config)
fireclipApp.run(run)


// config setup
fireclipApp.constant('API_URL', `${theConfig.api_url}/`);
fireclipApp.constant('MEDIA_STORAGE_HOST', `${theConfig.media_storage_url}/`);

import './webNotificationService';

import { appComponent } from './app/home/home.component';
fireclipApp.component(appComponent);
import { navComponent } from './app/home/nav.component';
fireclipApp.component(navComponent);

import Auth from './app/auth/auth';
fireclipApp.factory('Auth', Auth);

import Auth0Service from './app/auth/auth0.service';
fireclipApp.service('Auth0Service', Auth0Service);

import Channel from './app/channel/channel';
fireclipApp.factory('Channel', Channel);
import Job from './app/job/job';
fireclipApp.factory('Job', Job);
import './app/library/library';
import Provider from './app/provider/provider';
fireclipApp.factory('Provider', Provider);

import RecordingService from './app/recording/recording.service';
fireclipApp.service('RecordingService', RecordingService);

import { UserResource } from './app/user/user';
fireclipApp.factory('User', UserResource);
import Workflow from './app/workflow/workflow';
fireclipApp.factory('Workflow', Workflow);

import ChannelService from './app/channel/channelService';
fireclipApp.service('ChannelService', ChannelService);
import ClipService from './app/clip/clipService';
fireclipApp.service('ClipService', ClipService);
import MediaService from './app/media/media.service';
fireclipApp.service('MediaService', MediaService);

import './app/library/library.service';

import SocialProviderService from './app/provider/socialProviderService';
fireclipApp.service('SocialProviderService', SocialProviderService);

import { adminComponent } from './app/admin/adminController';
fireclipApp.component(adminComponent)

import clipController from './app/clip/clipController';
fireclipApp.controller('clipController', clipController);

require('./app/exporter/exportService');

import './app/media/media.list.component';
import './app/media/media.item.component';

import providerController from './app/provider/providerController';
fireclipApp.controller('providerController', providerController);

require('./app/workflow/workflow.controller');
require('./app/live/')

require('./app/manager/manager.component')
require('./app/channel/channelController')
require('./app/channel/channel.styl')

require('./app/filters');

import './timeline/timeline.component';
import './app/channel/channel.home.component'

import './app/clip/index'

/** images, the return value is the url of the asset, 
 *  in the exporters this is done with a spec to load the assets, instead of 
 *  md-icon(md-svg-src="/assets/img/exporter/{{exporter}}.svg")
 *  it sould load in the exporter with something like
 * export.image = require('./assets/img/exporter/ned.svg');
 * then use 
 *    md-icon(md-svg-src="{{exporter.image}}")
 * TODO: every asset should be loaded in the module that uses it..
 */
require('./assets/img/noise.png'); // foto ruido, usada para cuando un video esta en modo "no procesado" (solo guardado) es como preview
require('./copyComponent/copy');