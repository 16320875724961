import SocialProviderService from "./socialProviderService";

require('./provider/simpleForm')

class providerController {
  ui = { typeFilter: '' }
  providers = {}
  currentProviderType = null
  providerTypes: any[];
  constructor(private Provider, private $mdDialog, private SocialProviderService: SocialProviderService) {
		'ngInject';

    this.Provider = Provider;
    this.$mdDialog = $mdDialog;
    this.SocialProviderService = SocialProviderService;
    this.providerTypes = SocialProviderService.providerTypes;
    this.providerTypes.$promise.then(items => {
      this.currentProviderType = items && items[0];
    });
    this.load();
  }
  selectType(providerType) {
    this.currentProviderType = providerType;
  }
  load() {
    this.providers = {};
    return this.Provider.query().$promise.then(providers => {
      providers.forEach(provider => {
        this.providers[provider.code] = this.providers[provider.code] || [];
        this.providers[provider.code].push(provider);
      });
    });
  }
  delete(provider) {
    this.Provider.delete(provider).$promise.then(() => { this.load(); });
  }
  editProvider(ev, provider, providerType) {
    return this
      .SocialProviderService
      .openCraeteEditProvider(this.$mdDialog, ev, provider, providerType)
      .then(data => {
        this.load().then( () => {
          if (data && providerType.code === 'google') {
            /**
             * find the loaded provider, remember if a send the provider in the response of the link http request, 
             * sattelizer will remove the current login and replace-it with this one, this will logout the current user
             * I do open the provider in case there is no name associated to it, this way the user can put a name
             */
            const prov = this.providers[providerType.code].find(provider => provider.id == data.id);
            this.SocialProviderService.openCraeteEditProvider(this.$mdDialog, ev, prov, providerType);
          }
        })
      })
  };
};
export default providerController