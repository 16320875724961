import { v4 } from 'uuid'
import * as angular from 'angular';
import { cloneDeep } from 'lodash'
import fireclipApp from '../../app';
import ChannelService, { Channel, Input } from './channelService';
import { safeApply } from '../../utils';
import RecordingService from '../recording/recording.service';
import { Recording } from '../recording/recording.model';

class exporterController {
	createMode = true
	exporter: { name: string, id: any; workflowCode: any; parameters: { provider: {}; }; };
	constructor(exporter: FireClip.Exporter, private workflow, private $mdDialog) {
		'ngInject';

		if (exporter) {
			this.createMode = false;
			this.exporter = cloneDeep(exporter);
		} else {
			this.createMode = true;
			this.exporter = {
				name: workflow.name,
				id: v4(),
				workflowCode: workflow.code,
				parameters: {
					provider: {}
				}
			}
			// initialize providers
			this.workflow.providers.forEach(provider => this.exporter.parameters.provider[provider] = []);
		}
	}
	save() {
		this.$mdDialog.hide(this.exporter);
	}
	close() {
		this.$mdDialog.cancel()
	}
}

class inputController {
	constructor(public createMode = false, public input: Input, public channel: Channel, private $mdDialog) {
		'ngInject';
	}
	save(form: angular.IFormController) {
		if (form.$valid) {
			this.$mdDialog.hide(this.input);
		}
	}
	close() {
		this.$mdDialog.cancel()
	}
}

class channelEditController implements angular.IComponentController {
	workflows: any;
	recordings: Recording[];
	private channel: Channel;
	pendingRequest = false;
	$onInit() {
		if (this.channel) {
			this.channel = this.channel.clone()// cloneDeep<FireClip.Channel>(this.channel);
		} else {
			this.channel = new Channel();
		}
	}
	constructor(private $scope, private $mdDialog: angular.material.IDialogService, private ChannelService: ChannelService, Workflow, RecordingService: RecordingService, private $state, $element) {
		'ngInject';
		this.workflows = Workflow.query();
		RecordingService.query({
			populate: false,
			sort: 'name ASC'
		}).then(recordings => this.recordings = recordings)

		$scope.cancel = function () {
			$state.go('^')
		};
		$scope.searchTerm;
		$scope.clearSearchTerm = function () {
			$scope.searchTerm = '';
		};
		// The md-select directive eats keydown events for some quick select
		// logic. Since we have a search input here, we don't need that logic.
		$element.find('input').on('keydown', function (ev) {
			ev.stopPropagation();
		});
	}
	save(channel: Channel) {
		if (this.pendingRequest) return;
		this.pendingRequest = true;
		let p = null;
		if (channel.id) {
			p = this.ChannelService.update(channel)
		} else {
			p = this.ChannelService.save(channel)
		}
		p
			.then(() => this.$state.go('^'))
			.catch(error => {
				// TODO: improve error handling
				console.error(error)
				alert(error);
			});
	}

	openWorkflowMenu($mdMenu, ev) {
		$mdMenu.open(ev);
	};

	async openInputDialog(createMode = false, input: Input, $event): Promise<Input> {
		return this
			.$mdDialog
			.show({
				controller: inputController,
				controllerAs: '$ctrl',
				locals: {
					createMode,
					input,
					channel: this.channel,
				},
				template: require('./input.dialog.pug'),
				parent: angular.element(document.body),
				targetEvent: $event,
				clickOutsideToClose: true
			})
	}
	async openInputEditDialog(input: Input, $event) {
		const recording = this.recordings.find(r => r.id === input.recordingId);
		input.recording = recording;
		if (!recording) alert('no se ha encontrado ingesta para esta entrada')
		return this
			.openInputDialog(false, input.clone(), $event)
			.then(newInput => {
				const index = this.channel.inputs.findIndex(i => i.id === newInput.id);
				if (index >= 0) {
					this.channel.inputs[index] = newInput;
					safeApply(this.$scope)
				}
			})
	}
	async openInputCreateDialog(recording: FireClip.Recording, $event) {
		let p = true;
		if (this.channel.inputs.find(i => i.recordingId === recording.id)) {
			p = await this.$mdDialog.show(
				this.$mdDialog
					.confirm()
					.title('Advertencia')
					.textContent('Vas a crear una entrada con una ingesta que ya existe.')
					.targetEvent($event)
					.parent(angular.element(document.body))
					.cancel('No crear')
					.ok('Crear'))
				.then(() => true)
				.catch(() => false)
		}
		if (p) {
			const input = new Input(null)
			input.recording = recording;
			input.name = recording.name;
			return this
				.openInputDialog(true, input, $event)
				.then(input => {
					this.channel.inputs.push(input);
					safeApply(this.$scope)
				})
		}
	}
	removeInput(input: Input) {
		const index = this.channel.inputs.findIndex(i => i === input)
		if (index >= 0) {
			this.channel.inputs.splice(index, 1);
		}
	}
	inputIndexSwap(left: number, right: number) {
		if (left === right || Math.min(left, right) < 0 || Math.max(left, right) >= this.channel.inputs.length) return;
		const bubble = this.channel.inputs[left]
		this.channel.inputs[left] = this.channel.inputs[right]
		this.channel.inputs[right] = bubble
		safeApply(this.$scope)
	}
	removeExporter(exporter: FireClip.Exporter) {
		const index = this.channel.exporters.findIndex((e) => e.id === exporter.id);
		if (index >= 0) {
			if (exporter.workflowCode === 'process_library') {
				const input = this.channel.inputs.find(i => i.automaticExporting === exporter.id);
				if (input) {
					this.$mdDialog.show(
						this.$mdDialog.alert().title('Este exportador está usado')
							.textContent(`Este exportador ${exporter.name} se usa en la entrada ${input.name}, eliminar el exportador de esa entrada para continuar.`)
							.ok('Cancelar')
					);
					return;
				}
			}
			this.channel.exporters.splice(index, 1);
		}
	}
	openExtporerDialog(exporter: FireClip.Exporter, workflow, $event) {
		return this
			.$mdDialog
			.show({
				controller: exporterController,
				controllerAs: '$ctrl',
				locals: { exporter, workflow },
				template: require('./exporter.dialog.pug'),
				parent: angular.element(document.body),
				targetEvent: $event,
				clickOutsideToClose: true
			})
	}
	openCreateExporter(workflow, $event) {
		this.openExtporerDialog(null, workflow, $event)
			.then((exporter) => {
				this.channel.exporters.push(exporter);
			})
			.catch((error) => {
				console.error(error)
			});
	}
	openEditExporter(exporter: FireClip.Exporter, $event) {
		const workflow = this.workflows.find(w => w.code == exporter.workflowCode);
		this.openExtporerDialog(exporter, workflow, $event)
			.then((exporter) => {
				let index = this.channel.exporters.findIndex(e => e.id === exporter.id);
				this.channel.exporters[index] = exporter;
			})
			.catch((error) => {
				console.error(error || 'Unexpteced error while closing openExtporerDialog')
			});
	}
}

const name = 'fcChannelEdit';
fireclipApp.component(name, {
	controller: channelEditController,
	template: require('./channel.edit.pug'),
	bindings: {
		channel: '<'
	}
})

export { name as fcChannelEditComponent }