import { safeApply } from "../../utils";
import angular from "angular";
import { EditorService, EditorClip } from "../editor/editor.service";
import { Subscription } from "rxjs";

class clipController implements angular.IComponentController {
	editorService: EditorService
	clip: EditorClip;
	sub: Subscription;
	constructor(private $scope) {
		'ngInject';
	}
	$onDestroy() {
		this.sub.unsubscribe()
	}
	$onInit() {
		this.sub = this.editorService.selected$.subscribe((clip) => {
			// console.log('clip selected: ', clip?.id, this.$scope.$id)
			this.clip = clip;
			safeApply(this.$scope)
		})
	}

	fixClip() {
		this.editorService.fixClip(this.clip);
	}

	playCurrentStart() {
		this.editorService.playCurrentStart();
	}

	playCurrentEnd() {
		this.editorService.playCurrentEnd();
	}

	moveCurrentStart(delta: number) {
		this.editorService.moveCurrentStart(delta)
	}
	moveCurrentEnd(delta: number) {
		this.editorService.moveCurrentEnd(delta)
	}

	createClipFromStart() {
		this.editorService.addClip(this.editorService.getPlayTime(), 30000, true);
	}

	createClipFromEnd() {
		this.editorService.addClip(this.editorService.getPlayTime() - 30000, 30000, true)
	}
	setCurrentStartToPlaytime() {
		const c = this.editorService.selected$.getValue();
		if (c) {
			c.start = this.editorService.getPlayTime()
		}
	}
	setCurrentEndToPlaytime() {
		const c = this.editorService.selected$.getValue();
		if (c) {
			c.end = this.editorService.getPlayTime();
		}
	}

}

angular.module('fireclip').component('clipControls', {
	template: '<ng-transclude></ng-transclude>',
	bindings: {
		editorService: '<',
	},
	transclude: true,
	controller: clipController
})

export default clipController
