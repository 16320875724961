import { StateProvider, StateParams } from "@uirouter/angularjs";
import ChannelService, { Input, Channel } from './channel/channelService';
import { fcChannelEditComponent } from './channel/channel.edit.component';
import { fcLiveHeaderComponent } from './live/live.header.component';
import { fcManagerHeaderComponent } from './manager/manager.header.component'
import { fcHeaderComponent } from "./home/home.header.component";
import { fcManagerLibraryHeaderComponent } from "./library/manager.library.header.component";
import { fcLibraryListComponent } from "./library/library.list.component";
import { loginComponent } from "./login/login.component";
import { EditorVodComponentName } from "./editor/vod/editor-vod.component";
import { fcEditorVodHeaderComponent } from "./editor/vod/editor-vod-header.component";
import Auth0Service from "./auth/auth0.service";
import { AdminNavController } from "./admin/adminNavController";

export default function configRoutes($stateProvider: StateProvider, $urlRouterProvider, $analyticsProvider) {
	'ngInject';
	// esto causa que quede en un loop cuando no hay usuario logeado (se entra por primera vez)
	// $uiRouterProvider.stateService.defaultErrorHandler(() => {
	// 	$uiRouterProvider.stateService.go('error', {  });
	// })
	$urlRouterProvider.otherwise('/');
	$stateProvider.state('error', {
		url: '/error',
		template: `<div><h1> Ha ocurrido un error </h1></div>`
	})

	const setupUser = (Auth0Service: Auth0Service) => {
		"ngInject";
		if (theConfig.googleTrackingId) {
			if (Auth0Service.isAuthenticated()) {
				$analyticsProvider.settings.ga.userId = Auth0Service.getProfile().id;
			} else {
				$analyticsProvider.settings.ga.userId = null;
			}
		}
	}

	$stateProvider
		.state('login', {
			url: '/login',
			component: loginComponent,
			resolve: {
				analytics: setupUser,
				layout: () => 'column',
				flex: () => 'flex'
			}
		})
		.state('legacyLogin', {
			url: '/legacyLogin',
			component: loginComponent,
			resolve: {
				analytics: setupUser,
				layout: () => 'column',
				flex: () => 'flex'
			}
		})
	$stateProvider
		.state('main', {
			url: '/',
			// RTFM: https://ui-router.github.io/ng1/docs/1.0.19/interfaces/resolve.resolvepolicy.html#when
			resolvePolicy: {
				when: 'EAGER'
			},
			resolve: {
				analytics: setupUser,
				auth: function (Auth0Service: Auth0Service) {
					"ngInject";
					return Auth0Service.isAuthorized()
				},
				channels: function (ChannelService: ChannelService) {
					'ngInject';
					return ChannelService.query()
				},
				layout: () => 'column',
				flex: () => 'flex'
			},
			views: {
				'header@main': fcHeaderComponent,
				'nav@main': 'fcNav',
				'@': 'fcHome'
			}
		})
		.state('main.channel', {
			url: ':channel/',
			views: {
				'main@main': 'fcChannel'
			},
			redirectTo: (trans) => {
				const resolveChannel = trans.injector().getAsync('channel')
				return resolveChannel.then((channel: Channel) => {
					if (channel) {
						const lastActiveRecording = trans.params()?.recording
						let input: Input = null
						if (lastActiveRecording) {
							input = channel.inputs.find(i => i.recording.id === lastActiveRecording);
							if (input) {
								console.log(`last active recording ${lastActiveRecording} found`);
							} else {
								console.log(`last active recording ${lastActiveRecording} not found`)
							}
						}
						if (!input) {
							input = channel.inputs.find(i => i.recording.input.type !== 'upload')
							if (input) {
								console.log(`live input found`);
							} else {
								console.log(`live input not found`)
								input = channel.inputs.find(i => i.recording.input.type === 'upload')
								if (input) {
									console.log(`upload input found`);
								} else {
									console.log(`upload input not found`)
								}
							}
						}
						if (input) {
							console.log('redirec', { state: 'main.channel.live', params: { channel: channel.id, input: input.id, recording: '' } })
							return { state: 'main.channel.live', params: { channel: channel.id, input: input.id } }

						}
					} else {
						console.error('failed to resolve channel')
					}
					return null;
				})
			},
			params: {
				channel: { value: 'no-channel' }
			},
			resolve: {
				channel: function (channels: Channel[], $stateParams, ChannelService: ChannelService) {
					"ngInject";
					const c = channels.find(c => c.id === $stateParams.channel);
					if (c)
						ChannelService.setActiveChannel(c);
					return c;
				}
			},
		})
		.state('main.channel.live', {
			url: 'live?recording&input', //"/contacts?myParam1&myParam2"
			params: {
				recording: { dynamic: true },
				input: { dynamic: true },
			},
			resolve: {
				input: (channel: Channel, $stateParams): Input => {
					'ngInject';
					let input;
					if ($stateParams.input) {
						input = channel.inputs.find(i => i.id === $stateParams.input);
					} else {
						input = channel.inputs.filter(r => r.recording.input.type !== 'upload').find(r => r.recording.id === $stateParams.recording);
					}
					if (!input) {
						console.error('no input!')
						return null
					}
					return input;
				},
				recording: (input: Input): FireClip.Recording => {
					'ngInject';
					return input?.recording;
				}
			},
			redirectTo: (trans) => {
				const params = trans.params();
				if (!params.input) {
					let resolveChannel = trans.injector().getAsync('channel')
					return resolveChannel.then((channel: Channel) => {
						let input = null;
						if (params.recording)
							input = channel?.inputs.find(i => i.recording.input.type !== 'upload' && i.recordingId === params.recording)
						if (!input)
							input = channel?.inputs.find(i => i.recording.input.type !== 'upload')
						if (input) {
							return { state: 'main.channel.live', params: { channel: channel.id, test: 'hi', input: input.id }, options: { inherit: false } }
						} else {
							return { state: 'main.channel' }
						}
					})
				}
			},
			views: {
				"header.content@main": fcLiveHeaderComponent,
				"main@main": 'fcLive'
			}
		})
		.state('main.channel.vod', {
			url: 'vod',
			resolve: {
				library: ($stateParams: StateParams) => {
					'ngInject'
					return $stateParams.library;
				}
			},
			params: {
				library: { dynamic: true }
			},
			views: {
				"header.content@main": fcEditorVodHeaderComponent,
				"main@main": EditorVodComponentName
			}
		})
		.state('main.channel.manager', {
			url: 'manager/',
			params: {
				recording: { dynamic: true }
			},
			views: {
				"header.content@main": fcManagerHeaderComponent,
				"main@main": 'fcManager'
			}
		})
		.state('main.channel.manager.library', {
			url: 'library?input&search',
			params: {
				search: { dynamic: true},
				input: { dynamic: true }
			},
			resolve: {
				layout: () => 'column',
				flex: () => 'flex',
				input: (channel: Channel, $stateParams): Input => {
					'ngInject';
					if ($stateParams.input)
						return channel.inputs.find(i => i.id === $stateParams.input);
					if ($stateParams.recording)
						return channel.inputs.find(i => i.recording.id === $stateParams.recording);
					return null
				},
			},
			redirectTo: (trans) => {
				const params = trans.params()
				if (!params.input) {
					const resolveChannel = trans.injector().getAsync('channel')
					return resolveChannel.then((channel: Channel) => {
						const input = channel?.inputs.find(i => i.recording.input.type === 'upload')
						if (input) {
							return { state: 'main.channel.manager.library', params: { channel: channel.id, input: input.id, library: null } }
						} else {
							alert('este canal no tiene asignadas ingestas. Contacte a soporte.')
							return { state: 'main' }
						}
					})
				}
			},
			views: {
				"header.content@main": fcManagerLibraryHeaderComponent,
				"main@main": fcLibraryListComponent
			}
		})

	$stateProvider
		.state('admin', {
			url: '/admin',
			resolve: {
				analytics: setupUser,
				auth: function (Auth0Service: Auth0Service) {
					"ngInject";
					if (!Auth0Service.isAadmin()) {
						return Promise.reject(new Error("Not authorized"))
					}
				},
				channels: function (ChannelService: ChannelService) {
					'ngInject';
					return ChannelService.query();
				},
				layout: () => 'column',
				flex: () => 'flex'
			},
			views: {
				'nav@admin': {
					template: require('./admin/admin_nav.pug'),
					controller: AdminNavController,
					controllerAs: '$ctrl'
				},
				'@': 'fcAdmin'
			}
		})
		.state('admin.channel', {
			url: '/channel',
			template: require('./channel/channel.pug'),
			controller: 'channelController',
			controllerAs: '$ctrl'
		})
		.state('admin.channel.create', {
			url: '/create',
			views: {
				'nav@admin': {
					template: require('./channel/channel.edit.nav.pug'),
					controller: ['$scope', function () { }]
				},
				'@admin': fcChannelEditComponent
			}
		})
		.state('admin.channel.edit', {
			url: '/:id',
			resolve: {
				channel: function (ChannelService: ChannelService, $stateParams) {
					'ngInject';
					return ChannelService.get($stateParams.id)
				}
			},
			views: {
				'nav@admin': {
					template: require('./channel/channel.edit.nav.pug'),
					controller: function ($scope, channel) {
						'ngInject';
						$scope.channel = channel;
					}
				},
				'@admin': fcChannelEditComponent
			}
		})
		.state('admin.recording', {
			url: '/recording',
			component: 'recordingListComponent',
		})
		.state('admin.provider', {
			url: '/provider',
			template: require('./provider/provider.home.pug'),
			controller: 'providerController',
			controllerAs: '$ctrl'
		})
		.state('admin.user', {
			url: '/user',
			// template: require('./user/user.pug'),
			component: 'userListComponent',
			// controller: 'userController',
			// controllerAs: 'ctrl'
		})
		.state('admin.job', {
			url: '/job',
			component: 'jobListComponent',
		})
}