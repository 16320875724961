import * as angular from 'angular';
import { copyToClipboard } from '../../utils';
const template = require('./index.pug')
class Service implements FireClip.ExportService {
	async defaultAction(theExport, _media) {
		return copyToClipboard(theExport.result.url).then(() => "Texto copiado")
	}
}
class Controller {
	result: any;
	providers: any = {};
	exporter: any;
	job: any;
	constructor() {
		'ngInject';
	}
	async $onInit() {
		if (this.job.status == 0) {
		} else if (this.job.status == 3) {
			this.result = this.job.result;
		}
	}
}
angular.module('fireclip').component('exporterProcessLibrary', {
	template,
	controller: Controller,
	bindings: {
		job: '<',
		media: '<',
		exporter: '<'
	}
});

export default {
	code: 'process-library',
	template,
	controller: Controller,
	image: require('./icon.svg'),
	service: new Service(),
}