import * as angular from 'angular';
import ExportService from '../exporter/exportService';
require('./jobProgress.styl');
const template = require('./jobProgress.pug');
class ExporterProgressController implements angular.IComponentController {
  type: string;
  image: string;
  media: FireClip.Media;
  progress: number = 0;
  status: 0|1|2|3|4|5 = 2;
  constructor(private ExportService: ExportService) {
    'ngInject';
  }
  $onInit() {
    this.image = this.ExportService.getDefaultWorkflowImage(this.type);
  }
  
}
angular.module('fireclip').component('jobProgress', {
  template,
  controller: ExporterProgressController,
  bindings: {
    status: '<',
    progress: '<',
    type: '<'
  }
})