import * as angular from "angular";
import JSONEditor from 'jsoneditor'
require('./style.styl')
require('jsoneditor/dist/jsoneditor.min.css')



const name = 'json-editor';
angular.module(name, []);
class Controller implements angular.IComponentController {
	json: string | object;
	editor: JSONEditor;
	constructor($element) {
		'ngInject';
		const options = {
			mode: 'view'
		};
		this.editor = new JSONEditor($element[0], options);
	}
	$onInit() {
		this.editor.set(this.json);
	}
}
angular.module(name).component('jsonEditor', {
	bindings: {
		json: '<',
	},
	controller: Controller,
});

export default name;
