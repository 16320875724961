import app from '../../../app'
import { IComponentController } from 'angular';
import { homeController } from '../../home/home.component';


const name = 'fcEditorVodHeader';
class ComponentController implements IComponentController {
	home: homeController
}
app.component(name, {
	controller: ComponentController,
	template: require('./editor-vod-header.pug'),
	require: {
		home: '^^fcHome'
	}
})
export { name as fcEditorVodHeaderComponent }