require('./login.styl');
import fireclipApp from '../../app'
import { getUserLocalSetting, cleanLocalStorage } from '../utils'
import Auth0Service from '../auth/auth0.service';
import { StateService } from '@uirouter/core';
import angular, { IRootScopeService } from 'angular';


class loginController implements angular.IComponentController {
	loading = false
	user = ''
	password = ''
	message = ''
	alternativeEnvironment: { name: string; url: string; };
	warningBannerMessage: string = null;
	constructor(private $auth, private $state: StateService, private Auth0Service: Auth0Service, $scope: IRootScopeService) {
		'ngInject';
		this.alternativeEnvironment = theConfig.environments[Object.keys(theConfig.environments).filter(k => k != environment)[0]]
		this.Auth0Service.cleanLogout()
		fetch('https://worldtimeapi.org/api/timezone/Etc/GMT')
			.then(response => response.json())
			.then(data => {
				let localTime = Date.now();
				let remoteTime = new Date(data.datetime)
				const diffInMinutes = (localTime - remoteTime.getTime()) / 60000; // 60*1000
				const maxDiffInMinutes = 5;
				console.log(Math.abs(diffInMinutes) > maxDiffInMinutes)
				$scope.$apply(() => {
					if (Math.abs(diffInMinutes) > maxDiffInMinutes) {
						this.warningBannerMessage = `El reloj local tiene una diferencia mayor a ${maxDiffInMinutes} min con el remoto (${Math.round(diffInMinutes*10)/10}), por favor corrige la hora local.`
					} else {
						this.warningBannerMessage = null
					}
				})
			});
	}
	legacy() {
		return this.$state.is("legacyLogin")
	}
	// legacy login
	login() {
		this.loading = true;
		this.message = '';

		this.$auth.login({
			identifier: this.user,
			password: this.password
		}).then((response) => {
			const userId = response.data?.user?.user_id
			const activeChannelId = getUserLocalSetting(userId, 'lastActiveChannelID')
			const activeRecordingId = getUserLocalSetting(userId, 'lastActiveRecordingID')
			const params = {}
			cleanLocalStorage({ keep: ['legacy_auth', 'satellizer_token', `_U_${userId}`] })
			this.loading = false;
			if (activeChannelId) {
				params['channel'] = activeChannelId
				if (activeRecordingId) {
					params['recording'] = activeRecordingId
				}
				this.$state.go('main.channel', params);
			} else {
				this.$state.go('main')
			}
		}, (response) => {
			this.loading = false
			if (response.status == 403) {
				this.message = "Usuario y/o contraseña incorrectos"
			} else {
				this.message = "Error desconocido."
			}
		})
	}


	loginAuth0() {
		this.Auth0Service.presentLoginPopup();
	}
}

const componentName = 'fcLogin';
fireclipApp.component(componentName, {
	template: require('./login.pug'),
	controller: loginController,
	bindings: {
		layout: '@',
		flex: '@'
	},
})
export { componentName as loginComponent }