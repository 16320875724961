export default function Provider($resource, API_URL) {
	'ngInject';

	return $resource(`${API_URL}provider/:id`, {}, {
		get: {},
		query: {
			params: { limit: 300 },
			method: 'GET',
			isArray: true,
		},
		delete: {
			method: 'DELETE',
		},
		getTypes: {
			url: `${API_URL}provider/types`,
			isArray: true,
		},
	});
}