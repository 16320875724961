import { AsyncSubject } from 'rxjs';

class Thumbnail {
	lastUrl: string = '';

	thumbnailImage: HTMLImageElement = new Image();

	lastObservable: AsyncSubject<HTMLImageElement>;

	abortController: AbortController;

	async getImage(url: string) {
		if (url) {
			if (this.lastUrl != url) {
				if (this.abortController) this.abortController.abort();
				this.lastUrl = url;
				this.abortController = new AbortController();
				const as = new AsyncSubject<HTMLImageElement>();
				fetch(url, { signal: this.abortController.signal })
					.then(response => response.blob())
					.then((blob) => {
						this.thumbnailImage.onload = () => {
							as.next(this.thumbnailImage);
							as.complete();
						};
						this.thumbnailImage.onerror = (_event, _source, _line, _col, error) => {
							as.error(error);
						};
						this.thumbnailImage.src = URL.createObjectURL(blob);
					})
					.catch((error) => {
						as.error(error);
					});
				this.lastObservable = as;
			}
			return this.lastObservable.toPromise();
		}
		this.thumbnailImage.src = '';
	}
}

export default Thumbnail;
