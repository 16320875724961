import app from '../../app';
import ExportService from '../exporter/exportService';
import { LibraryService } from './library.service';
import './library.item.component'
import { EditorService } from '../editor/editor.service';
import { Channel, Input } from '../channel/channelService';
import { StateParams, StateService } from '@uirouter/angularjs';
import { parse as queryParser } from 'search-query-parser'
const name = 'fcLibraryList';


class controller implements IComponentControllerUIRouter {
	channel: Channel;
	input: Input
	library: any;
	searchParams: {
		libraryId: string[]
		title: string
	} = { libraryId: null, title: "" }
	searchText = ""
	jobChange: any;
	filter: { owner?: string, types?: string[] } = {};
	showOwnedByMe: any;
	editorService: EditorService;
	currentPage = 0;
	pageLength = 14
	loading = false

	constructor(private ExportService: ExportService, private LibraryService: LibraryService, private $stateParams: StateParams, private $state: StateService) {
		'ngInject';
		this.setupMediaFilter()
		this.editorService = new EditorService('relative');
	}
	$onInit() {
		this.searchText = this.$stateParams.search
		this.load();
	}
	$onDestroy() {
		this.editorService.destroy();
		if (this.input) {
			this.LibraryService.clearSubscritionAndLibraries(this.input.recordingId)
		}
	}

	clearSearchText() {
		this.searchText = "";
		this.onSearchTextChange()
	}
	onSearchTextChange() {
		this.$state.go('.', { search: this.searchText })
		this.load()
	}
	forwardPage() {
		this.currentPage = this.LibraryService.libraries.length > 0 ? this.currentPage + 1 : this.currentPage;
		this.load();
	}

	backwardPage() {
		this.currentPage = this.currentPage > 0 ? this.currentPage - 1 : 0;
		this.load();
	}

	uiOnParamsChanged(newParams) {
		this.$onDestroy();
		if (newParams.input) {
			this.input = this.channel.inputs.find(i => i.id === newParams.input)
		} else if (newParams.recording) { // backward compatibility 
			this.input = this.channel.inputs.find(i => i.recordingId === newParams.recording)
		}
		if (this.input) {
			this.load()
		}
	}
	load() {
		if (this.input) {
			this.loading = true;
			const parsed = queryParser(this.searchText, { keywords: ['id'] })
			this.searchParams = { title: "", libraryId: null }
			if (typeof parsed === 'string') {
				this.searchParams.title = parsed;
			} else {
				if (parsed.id) {
					if (typeof parsed.id === 'string')
						this.searchParams.libraryId = [parsed.id]
					else
						this.searchParams.libraryId = parsed.id
				} else {
					if (typeof parsed.text === 'string')
						this.searchParams.title = parsed.text
					else
						this.searchParams.title = parsed.text.join(" ")
				}
			}
			if (this.searchParams.libraryId || this.searchParams.title) {
				this.LibraryService.startSubscription(this.input.recordingId, false);
			} else {
				this.LibraryService.startSubscription(this.input.recordingId);
			}
			this.LibraryService.getLibraryByRecordingIds([this.input.recordingId], this.searchParams.libraryId, this.searchParams.title, this.currentPage, this.pageLength)
				.then(libs => {
					this.LibraryService.libraries = libs;
					this.loading = false
				})
				.catch(error => {
					console.error(error)
					this.LibraryService.libraries = [];
					this.loading = false
				})
		}
	}
	setupMediaFilter() {
		const newFilter = {
			types: [],
			owner: null,
		}
		newFilter.types.push('clip')
		if (!this.showOwnedByMe) newFilter.owner = '*';
		// assign new object, so below component gets notified in $onChanges event handler, read: https://docs.angularjs.org/guide/component#component-based-application-architecture
		this.filter = newFilter;
	}
	openJob(theExport, library, event) {
		if (!theExport.exporterId) alert('error, no exporterId')
		const exporter = this.channel.exporters.find(e => e.id === theExport.exporterId)
		this.ExportService.openExportDialog(exporter, null, library, theExport, event);
	}
	showMediaDialog(library) {
		this.library = library;
	}
}
app.component(name, {
	bindings: {
		channel: '<',
		input: '<',
		layout: '@',
		flex: '@'
	},
	controller: controller,
	template: require('./library.list.pug')
})

export { name as fcLibraryListComponent, controller as LibraryListController };