import socketService from "../socketService";
import * as angular from "angular";
import ChannelService, { Channel } from "../channel/channelService";
import './style.styl'
import { StateService } from "@uirouter/core";
import { cleanLocalStorage, setUserLocalSetting } from '../utils'
import Auth0Service from "../auth/auth0.service";

class PanelMenuCtrl implements angular.IController {
	profile: any;
	constructor(private Auth0Service: Auth0Service) {
		'ngInject';
		this.profile = this.Auth0Service.getProfile();
	}

	isAdmin() {
		return this.Auth0Service.isAadmin()
	}
	logOut() {
		const userId = this.Auth0Service.getProfile();
		cleanLocalStorage({ keep: [`_U_${userId}`] })
		this.Auth0Service.logout();
	}
}


class homeController implements angular.IComponentController {
	loading = false;
	openSidenav: boolean;
	channels: Channel[]
	constructor(private $mdPanel, private $state: StateService, private ChannelService: ChannelService, private Auth0Service: Auth0Service) {
		'ngInject';
	}

	$onInit() {
		socketService.setAuth(this.Auth0Service.getAccessToken());
		socketService.connect().then(() => {
			this.Auth0Service.on('newAccessToken', (token) => {
				socketService.refreshAuth(token);
			})
		})
	}
	openAccountMenu($event) {
		var position = this.$mdPanel.newPanelPosition()
			.relativeTo('.profile-menu')
			.addPanelPosition(this.$mdPanel.xPosition.ALIGN_START, this.$mdPanel.yPosition.BELOW);
		var config = {
			attachTo: angular.element(document.body),
			controller: PanelMenuCtrl,
			controllerAs: '$ctrl',
			template: require('./user.panel.pug'),
			panelClass: 'demo-menu-example',
			position: position,
			locals: {
			},
			openFrom: $event,
			clickOutsideToClose: true,
			escapeToClose: true,
			focusOnOpen: false,
			zIndex: 2
		};

		this.$mdPanel.open(config);

	}

	get channel() {
		return this.ChannelService.activeChannel
	}

	toggleSidenav() {
		this.openSidenav = !this.openSidenav;
	}

	goToRecording(recording: FireClip.Recording, saveSelection: boolean = true) {
		if (saveSelection) {
			setUserLocalSetting(this.Auth0Service.getProfile().id, 'lastActiveRecordingID', recording.id)
		}
		this.$state.go('.', { channel: this.channel.id, recording: recording.id })
	}

	goToManager(channel) {
		this.openSidenav = false;
		this.$state.go('main.channel.manager', { channel: channel.id });
	}
}
const appComponent: IComponent = {
	'fcHome': {
		bindings: {
			layout: '@',
			flex: '@',
			channels: '<',
		},
		controller: homeController,
		template: require('./home.pug')
	}
}

export { appComponent, homeController }