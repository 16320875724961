interface clipJobResult {
	path: string // "test/medias/clips/5dc19096fa34252dbf5be207/asf.mp4"
	filesPath: string // "test/medias/clips/5dc19096fa34252dbf5be207",
	files: {
		normal: string // "asf.mp4"
	},
	image: string // "test/medias/clips/5dc19096fa34252dbf5be207/thumbnails/1.jpg",
	images: {
		path: string // "test/medias/clips/5dc19096fa34252dbf5be207/thumbnails/",
		files: string[] // [ "1.jpg","2.jpg"... ]
	},
	encoder: string // "libx264",
	imagesTime: number[] // [0.018,0.06,0.102,0.143,0.185]
}

import { module } from 'angular';
import { mediaURL, copyToClipboard } from '../../utils';

const template = require('./index.pug');
const image = require('./clip.svg');

require('./clip.styl')

class Service implements FireClip.ExportService {
	async defaultAction(theExport, _media) {
		const result = this.processResult(theExport);
		return copyToClipboard(result.videoURL).then(() => "Enlace copiado")
	}

	processResult(job) {
		if (job.status == 3) {
			if (job.childs.length === 1) {
				const result: clipJobResult = job.result;
				const response = {
					videoURL: mediaURL(result.path),
					imageURL: null,
					imagesURL: null,
				}
				if (result.image) {
					response.imageURL = mediaURL(result.image);
				}
				if (result.images) {
					response.imagesURL = result.images.files.map(f => mediaURL(`${result.images.path}${f}`));
				}
				return response;
			}
		}
		return null;
	}
}

const clipExportService = new Service();

class Controller {
	job: any;
	videoURL: string;
	imageURL: string;
	imagesURL: string[];
	constructor() {
		'ngInject';
		
	}
	$onInit() {
		const result = clipExportService.processResult(this.job);
		if (result) {
			this.videoURL = result.videoURL;
			this.imageURL = result.imageURL;
			this.imagesURL = result.imagesURL;
		}
	}
}
module('fireclip').component('exporterClip', {
	template,
	controller: Controller,
	bindings: {
		job: '<',
		media: '<'
	}
});

export default {
	code: 'clip',
	template,
	controller: Controller,
	image,
	service: clipExportService,
}