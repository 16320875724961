import * as angular from 'angular';
const template = require('./index.pug')
const image = require('./image.svg');

class ExporterImageService implements FireClip.ExportService {
	static getSize(image: string) {
		const blob = this.getBlob(image)
		return blob.size;
	}
	static getBlob(image:string) {
		const byteCharacters = atob(image);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], {
			type: 'image/jpeg',
		});
		return blob
	}
	getDefaultData(theExport) {
		const image = theExport.childs[0].result.images[0];
		return {
			size: ExporterImageService.getSize(image),
			image: ExporterImageService.getSize(image)
		}
	}
	async defaultAction(theExport, media) {
		let image = theExport.childs[0].result.images[0];
		ExporterImageService.downloadImage(image, media.title)
		return "Descargando imagen";
	}
	static downloadImage(image: string, name) {
		const fileName = `${name}.jpg`;
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.style.display = 'none';
		const blob = this.getBlob(image)
		const url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = fileName;
		a.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	}
}

class Controller {
	job: any;
	images: string[]
	constructor() {
		'ngInject';

	}
	$onInit() {
		if (this.job.status == 3) {
			// TODO: plain copy... this is buggy
			this.images = this.job.childs[0].result.images;
			ExporterImageService.getSize(this.images[0]);
		}
	}
	getSize(image){
		ExporterImageService.getSize(image);
	}
	download(image, name) {
		ExporterImageService.downloadImage(image, name);
	}
}
angular.module('fireclip').component('exporterImage', {
	template,
	controller: Controller,
	bindings: {
		job: '<',
		media: '<',
		exporter: '<'
	}
});

export default {
	code: 'image',
	template,
	controller: Controller,
	image,
	service: new ExporterImageService(),
}