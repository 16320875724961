import ChannelService, { Channel } from "./channelService";
import angular from "angular";
import { safeApply } from "../../utils";

class channelController {
  channels: Channel[];

  constructor(private $scope, private $mdDialog, private $mdToast, private ChannelService: ChannelService, private $state) {
    'ngInject';
    this.refresh()
  }
  async refresh() {
    this.channels = await this.ChannelService.query({
      populate: false,
      sort: 'name ASC'
    });
    safeApply(this.$scope);
  }

  delete(ev, channel) {
    var confirm = this.$mdDialog.confirm()
      .title('Borrar canal?')
      .textContent('El canal se borrará.')
      .ariaLabel('Borrar canal')
      .targetEvent(ev)
      .ok('si')
      .cancel('no');
    this.$mdDialog.show(confirm).then(() => {
      this.ChannelService.delete(channel).then(() => {
        this.refresh();
        this.$mdToast.show(this.$mdToast.simple().textContent('Borrado!'));
      });
    }, function () {
    });
  }

  edit(channel) {
    this.$state.go('admin.channel.edit', { id: channel.id });
  }
  create() {
    this.$state.go('admin.channel.create');
  }
}

angular.module('fireclip').controller('channelController', channelController);