require('moment')

// Angular Modules
import * as angular from 'angular';
import ngAnimate from 'angular-animate';
import ngResource from 'angular-resource';
import ngMessages from 'angular-messages';
import angularMaterial from 'angular-material';
import ngStorage from 'ngstorage';
import satellizer from 'satellizer'
import ngFileUpload from 'ng-file-upload'
import ngSanitize from 'angular-sanitize';
import uiRouter from '@uirouter/angularjs';
import angularMoment from 'angular-moment';
import 'angular-aria';
import angularVideoPlayer from './angular-video-player/angular-video-player';
import jsonEditor from './json-editor';
require('angular-material/angular-material.scss')
require('./app.styl')
require('angular1-async-filter'); 

const moduleDependencies = [
	jsonEditor,
	angularVideoPlayer,
	ngResource,
	ngAnimate,
	ngStorage.name,
	angularMaterial,
	ngMessages,
	satellizer,
	uiRouter,
	ngFileUpload,
	ngSanitize,
	'jg.webNotification',
	angularMoment,
	'asyncFilter',
	require('angulartics')
];
const fireclipApp = angular.module('fireclip', moduleDependencies);
export default fireclipApp;
